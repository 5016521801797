import {ReaderIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ToolUsedItemProps, ToolUsedItemWrapper} from 'scout-chat/components/tools-used/ToolUsedItem.tsx';
import {castJson} from 'scout-chat/utils/json-utils.ts';

interface SearchContentToolArguments {
  prompt: string;
}
const SearchContentToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    return castJson<SearchContentToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  return (
    <ToolUsedItemWrapper>
      <div className='flex items-center gap-2'>
        <ReaderIcon className='inline-block size-4 shrink-0 text-accent' />
        <span>{t('tool-used.tools.search-content', {prompt: toolArguments?.prompt})}</span>
      </div>
    </ToolUsedItemWrapper>
  );
};

export default SearchContentToolUsedItem;
