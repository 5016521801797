import {ReactComponent as ScoutIcon} from 'scout-chat/resources/logo-round.svg';
import 'scout-chat/styles/ScoutSpinner.css';

const ScoutSpinner = () => {
  return (
    <div>
      <ScoutIcon className='ai-loading text-accent' />
    </div>
  );
};

export default ScoutSpinner;
