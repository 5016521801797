import Page from '#/components/Page';
import {useAdminConfigQuery, useUpdateAdminConfigMutation, useValidateAdminConfigMutation} from '#/hooks/query/config';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import {Button} from 'scout-chat/components/Button.tsx';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';

const AdminPage = () => {
  const {t} = useTranslation();
  const {addToast} = useToasts();

  const [config, setConfig] = useState<string>('');
  const [initialConfig, setInitialConfig] = useState<string>('');
  const [validated, setValidated] = useState(true);
  const adminConfigQuery = useAdminConfigQuery();

  const validateAdminConfigMutation = useValidateAdminConfigMutation();
  const updateAdminConfigMutation = useUpdateAdminConfigMutation();

  useEffect(() => {
    if (adminConfigQuery.data) {
      const configString = JSON.stringify(adminConfigQuery.data, null, 2);
      setInitialConfig(configString);
      setConfig(configString);
    }
  }, [adminConfigQuery.data]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newConfig = event.target.value;
    setConfig(newConfig);
    setValidated(newConfig === initialConfig);
  };

  const validateAdminConfig = useCallback(async () => {
    try {
      JSON.parse(config);
      setValidated(true);
    } catch (error) {
      addToast(t('admin.toasts.errors.invalid-config'), 'error');
      return;
    }
    return await validateAdminConfigMutation.mutateAsync(JSON.parse(config));
  }, [addToast, config, t, validateAdminConfigMutation]);

  const updateAdminConfig = useCallback(async () => {
    try {
      JSON.parse(config);
      setValidated(true);
    } catch (error) {
      addToast(t('admin.toasts.errors.save'), 'error');
      return;
    }
    return await updateAdminConfigMutation.mutateAsync(JSON.parse(config));
  }, [addToast, config, t, updateAdminConfigMutation]);

  return (
    <Page title={t('admin.page-title')}>
      <PageContentHeader title={t('admin.page-title')}>
        <div className='flex gap-2'>
          <Button
            type='button'
            variant='primary'
            size='sm'
            textSize='base'
            disabled={validated}
            onClick={validateAdminConfig}
          >
            {t('admin.actions.validate')}
          </Button>

          <Button
            type='button'
            variant='primary'
            size='sm'
            textSize='base'
            disabled={!validated || config === initialConfig}
            onClick={updateAdminConfig}
          >
            {t('admin.actions.save')}
          </Button>
        </div>
      </PageContentHeader>

      <TextareaAutosize
        className='grow text-primary focus:outline-none p-4 rounded-2xl border border-stroke-main bg-surface-01 font-mono whitespace-pre'
        spellCheck={false}
        value={config}
        onChange={handleChange}
        autoFocus
      />
    </Page>
  );
};

export default AdminPage;
