import {MagnifyingGlassIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ToolUsedItemProps, ToolUsedItemWrapper} from 'scout-chat/components/tools-used/ToolUsedItem.tsx';
import {castJson} from 'scout-chat/utils/json-utils.ts';

interface GetWebpageContentToolArguments {
  url: string;
}

const GetWebpageContentToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const url = useMemo(() => {
    const toolArguments = castJson<GetWebpageContentToolArguments>(toolCall.arguments);
    return toolArguments?.url;
  }, [toolCall.arguments]);

  return (
    <ToolUsedItemWrapper>
      <a className='flex items-center gap-2 hover:opacity-70' target='_blank' rel='noreferrer' href={url}>
        <MagnifyingGlassIcon className='inline-block size-4 text-accent shrink-0' />
        {url && <span className='truncate'>{t('tool-used.tools.get-webpage-content', {url})}</span>}
      </a>
    </ToolUsedItemWrapper>
  );
};

export default GetWebpageContentToolUsedItem;
