import Page from '#/components/Page.tsx';
import TaskPreviewPage from '#/components/tasks/create-edit-task/task-debugger/TaskPreviewPage.tsx';
import {useTaskQuery} from '#/hooks/query/tasks.tsx';
import {TaskResponse} from '#/repositories/assistants-api/requests/fetch-tasks.ts';
import {
  StreamTaskDebuggerChatCompletionRequest,
  streamTaskDebuggerChatCompletion,
} from '#/repositories/assistants-api/requests/stream-task-debugger-chat-completion.ts';
import {ReactComponent as GearIcon} from '#/resources/gear-icon.svg';
import {FunctionComponent, memo, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useParams} from 'react-router-dom';
import ModelDropdown from 'scout-chat/components/model-dropdown/ModelDropdown.tsx';
import ConversationComponent from 'scout-chat/components/scout-chat/ConversationComponent.tsx';
import {useDefaultModelId} from 'scout-chat/hooks/logic/use-default-model-id.tsx';
import {useSetMessagesOnStreamChunks} from 'scout-chat/hooks/logic/use-set-messages-on-stream-chunks.tsx';
import {useChatModelsQuery} from 'scout-chat/hooks/requests/use-chat-models-query.tsx';
import {ConversationMessage, MessageRole, OnEditSubmitType} from 'scout-chat/types.ts';
import {twMerge} from 'tailwind-merge';

type TaskDebuggerPageParams = {
  taskId: string;
};

export const TASK_DEBUGGER_CONVERSATION_ID = 'task-debugger-conversation';

const TaskDebuggerPage: FunctionComponent = () => {
  const {t} = useTranslation();

  const {taskId} = useParams<TaskDebuggerPageParams>();

  const {defaultModelId, setDefaultModelId} = useDefaultModelId();
  const chatModels = useChatModelsQuery();

  const [isStreaming, setIsStreaming] = useState(false);
  const [error, setError] = useState('');

  const taskQuery = useTaskQuery({taskId, shouldPoll: false});

  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useState<ConversationMessage[]>([
    {
      role: 'assistant',
      content: t('create-edit-task.task-debugger.welcome-message'),
    },
  ]);

  const abortControllerRef = useRef<AbortController>();

  const handleStopStreaming = useCallback(() => {
    setIsStreaming(false);
  }, []);

  const setMessagesOnStreamChunks = useSetMessagesOnStreamChunks(setMessages);

  const handleStreamChatCompletion = useCallback(
    (newConversation: ConversationMessage[]) => {
      setIsStreaming(true);

      const request: StreamTaskDebuggerChatCompletionRequest = {
        messages: newConversation,
        model: defaultModelId || chatModels[0]?.id,
      };

      const abortController = new AbortController();

      streamTaskDebuggerChatCompletion(
        taskId || '',
        request,
        chunks => setMessagesOnStreamChunks(chunks, newConversation, () => null),
        error => {
          if (!abortControllerRef.current?.signal.aborted) {
            setError(error.toString());
            setIsStreaming(false);
          }
        },
        () => {
          taskQuery.refetch();
          handleStopStreaming();
        },
        abortController.signal,
      );

      abortControllerRef.current = abortController;
    },
    [chatModels, defaultModelId, handleStopStreaming, setMessagesOnStreamChunks, taskId, taskQuery],
  );

  const sendTaskDebugMessages = useCallback(
    async (newMessages: ConversationMessage[]) => {
      const filteredMessages = messages.filter(
        message => message.content === undefined || typeof message.content === 'string',
      );
      const newConversation = [...filteredMessages, ...newMessages];

      setError('');
      setPrompt('');

      handleStreamChatCompletion(newConversation);
      setMessages(newConversation);
    },
    [handleStreamChatCompletion, messages],
  );

  const handleEditSubmit: OnEditSubmitType = useCallback(
    async (messageIndex, editedMessage) => {
      let count = -1;
      let absoluteIndex = -1;
      for (let index = 0; index < messages.length; index++) {
        const message = messages[index];
        const role = message.role;
        if (role !== 'system' && role !== 'tool' && !!message.content) {
          count += 1;
          if (count === messageIndex) {
            absoluteIndex = index;
            break;
          }
        }
      }

      const updatedMessages = messages.slice(0, absoluteIndex + 1);
      updatedMessages[absoluteIndex] = {
        role: 'user',
        content: editedMessage,
      };

      setMessages(updatedMessages);
      handleStreamChatCompletion(updatedMessages);
    },
    [handleStreamChatCompletion, messages],
  );

  const onSubmit = useCallback(async () => {
    if (isStreaming) {
      abortControllerRef.current?.abort();
      handleStopStreaming();
      return;
    }

    setIsStreaming(true);

    if (!defaultModelId) {
      return;
    }

    const newMessage: ConversationMessage = {
      role: 'user' as MessageRole,
      content: prompt,
    };

    await sendTaskDebugMessages([newMessage]);
  }, [defaultModelId, handleStopStreaming, isStreaming, prompt, sendTaskDebugMessages]);

  return (
    <Page title={t('create-edit-task.task-debugger.page-title')}>
      <div className='flex h-full overflow-hidden'>
        <div className='w-1/2'>
          <ConversationComponent
            conversationId={TASK_DEBUGGER_CONVERSATION_ID}
            messages={messages}
            isLoading={isStreaming}
            message={prompt}
            onMessageChange={setPrompt}
            onSendMessageSubmit={onSubmit}
            error={error}
            onEditSubmit={handleEditSubmit}
          >
            {{
              innerHeader: (
                <>
                  <ModelDropdown
                    chatModels={chatModels}
                    selectedModelId={defaultModelId}
                    setSelectedModelId={setDefaultModelId}
                    className='absolute z-20 top-4 left-4 md:flex hidden'
                  />
                  <TaskButton taskQueryData={taskQuery.data} className='absolute z-20 top-4 right-4' />
                </>
              ),
              outerHeader: (
                <ModelDropdown
                  chatModels={chatModels}
                  selectedModelId={defaultModelId}
                  setSelectedModelId={setDefaultModelId}
                  className='md:hidden'
                />
              ),
            }}
          </ConversationComponent>
        </div>
        <div className='w-1/2'>{taskId && <TaskPreviewPage taskQuery={taskQuery} />}</div>
      </div>
    </Page>
  );
};

const TaskButton: FunctionComponent<{
  taskQueryData: TaskResponse | undefined;
  className?: string;
}> = memo(({taskQueryData, className}) => {
  const {t} = useTranslation();

  return (
    taskQueryData && (
      <NavLink
        to={`/tasks/${taskQueryData?.id}/edit`}
        className={twMerge(
          'flex gap-2 h-12 rounded-lg bg-surface-02 p-4 items-center z-10 hover:opacity-70 transition-opacity group',
          className,
        )}
      >
        <GearIcon className='group-hover:rotate-[30deg] transition stroke-primary' />
        <span className='font-bold'>{t('create-edit-task.task-debugger.edit-button')}</span>
      </NavLink>
    )
  );
});

export default TaskDebuggerPage;
