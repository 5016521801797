import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export type DeleteTaskScheduleRequest = {
  task_id: string;
  schedule_id: string;
};

export const deleteTaskSchedule = async (request: DeleteTaskScheduleRequest): Promise<AxiosResponse<null>> => {
  return ScoutAPI.delete(`/tasks/${request.task_id}/schedules/${request.schedule_id}`);
};
