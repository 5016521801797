import {DownloadIcon} from '@radix-ui/react-icons';
import * as Popover from '@radix-ui/react-popover';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ExtraProps} from 'react-markdown';
import ProtectedImg from 'scout-chat/components/ProtectedImg.tsx';
import {isProtectedRegex} from 'scout-chat/hooks/logic/use-is-protected-url.tsx';
import useDownloadBlob from 'scout-chat/hooks/requests/use-download-blob.tsx';
import {fetchImage} from 'scout-chat/requests/fetch-image.ts';
import {twMerge} from 'tailwind-merge';

const useDownloadOptions = () => {
  const {t} = useTranslation();

  const downloadOptions = [
    {
      format: 'jpeg',
      label: t('protected-img.download-button.formats.jpeg'),
      downloadName: 'scout-generated-image.jpeg',
    },
    {
      format: 'png',
      label: t('protected-img.download-button.formats.png'),
      downloadName: 'scout-generated-image.png',
    },
    {
      format: '',
      label: t('protected-img.download-button.formats.webp'),
      downloadName: 'scout-generated-image.webp',
    },
  ];

  return downloadOptions;
};

export const ImageDownloadButton = ({src}: {src?: string}) => {
  const {t} = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const downloadOptions = useDownloadOptions();
  const downloadBlob = useDownloadBlob();

  const handleDownloadClick = async (format: string, downloadName: string) => {
    if (!src) return;

    const removeUpToProtected = new RegExp(`^.*?${isProtectedRegex.source}`);
    const filePath = src.replace(removeUpToProtected, '');

    const imageBlob = await fetchImage(filePath, format).then(res => res.data);

    downloadBlob(imageBlob, downloadName);
  };

  const renderDownloadLink = (format: string, label: string, downloadName: string) => (
    <button
      key={format}
      className='p-2 w-full rounded-lg text-left hover:bg-surface-03 focus:outline-none'
      onClick={() => handleDownloadClick(format, downloadName)}
    >
      {label}
    </button>
  );

  return (
    <>
      <Popover.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
        <Popover.Trigger asChild>
          <button
            className='absolute top-2 right-2 p-2 bg-black/70 rounded-lg opacity-0 group-hover:opacity-100 hover:opacity-70 transition-opacity duration-200 data-[ismenuopen=true]:opacity-100'
            aria-label={t('protected-img.download-button.label')}
            data-ismenuopen={isMenuOpen}
          >
            <DownloadIcon className='stroke-white' />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className='rounded-xl p-1.5 shadow-lg bg-surface-02 outline-none text-primary'
            sideOffset={5}
            align='center'
            side='bottom'
            onClick={() => setIsMenuOpen(false)}
          >
            <div className='flex flex-col items-start'>
              {downloadOptions.map(({format, label, downloadName}) => renderDownloadLink(format, label, downloadName))}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
};

const GeneratedImageComponent = ({className, node, ...props}: JSX.IntrinsicElements['img'] & ExtraProps) => (
  <ProtectedImg
    animate={true}
    containerClassName='max-h-generated-image h-generated-image'
    className={twMerge(className, 'w-full rounded-2xl max-h-generated-image')}
    downloadButton={true}
    {...props}
  />
);

export default GeneratedImageComponent;
