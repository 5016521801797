import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import AssistantAvatar from 'scout-chat/components/AssistantAvatar.tsx';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';
import {twMerge} from 'tailwind-merge';

interface AssistantPresentationData {
  name: string;
  owner: string;
  description: string;
  avatarUrl: string | undefined;
}

const AssistantPresentation: FunctionComponent<{
  assistant: AssistantPublicResponse | undefined;
  assistantIsLoading: boolean;
  onStarterPromptSelect: (starter: string) => void;
  maxWidth: string | undefined;
}> = ({assistant, assistantIsLoading, onStarterPromptSelect, maxWidth}) => {
  const {t} = useTranslation();

  const assistantPresentationData: AssistantPresentationData | null = useMemo(() => {
    if (assistantIsLoading) {
      return {
        name: '________',
        owner: '________________________',
        description: '___________________________',
        avatarUrl: '_',
      };
    }

    if (assistant) {
      return {
        name: assistant.name,
        owner: `${t('conversation.assistant.by')} ${assistant.owner_name}`,
        description: assistant.description,
        avatarUrl: assistant.avatar_url,
      };
    }

    return null;
  }, [assistant, assistantIsLoading, t]);

  const isLoadingClasses = assistantIsLoading ? ' bg-surface-03 animate-pulse rounded-lg text-transparent ' : '';

  if (!assistantPresentationData) return null;

  return (
    <div
      className='relative flex flex-col size-full justify-start items-center mx-auto text-center @container p-5 @lg:p-0 tall:justify-center'
      style={{maxWidth}}
    >
      {assistantPresentationData.avatarUrl !== undefined && (
        <AssistantAvatar
          src={assistantPresentationData.avatarUrl}
          containerClassName='size-20 aspect-square mb-5'
          className='rounded-xl text-accent-inverse text-2xl font-medium'
          alt={t('conversation.assistant.avatar.alt')}
          assistantName={assistantPresentationData.name}
        />
      )}

      <div className={twMerge('mb-1 text-3xl font-semibold', isLoadingClasses)}>{assistantPresentationData.name}</div>
      <div className={twMerge('text-xs text-gray-400 mb-3', isLoadingClasses)}>{assistantPresentationData.owner}</div>
      <div className={twMerge('max-w-md text-center text-sm hidden tall:block', isLoadingClasses)}>
        {assistantPresentationData.description}
      </div>

      {assistant && assistant?.prompt_starters && (
        <div className='absolute bottom-0 w-full ' style={{maxWidth}}>
          <ul className='flex gap-2 overflow-x-scroll px-4'>
            {assistant.prompt_starters.map((starter, index) => (
              <li
                key={index}
                className='flex flex-col gap-4 text-sm p-5 border border-stroke-main rounded-2xl bg-surface-02 hover:bg-surface-03 transition-colors cursor-pointer w-56 shrink-0'
                onClick={() => onStarterPromptSelect(starter)}
              >
                <span className='font-semibold text-left'>{starter}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AssistantPresentation;
