import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ExecuteExternalServicesCodeToolArguments} from 'scout-chat/components/function-permissions/ExecuteExternalServicesCodeFunctionPermissionDetails.tsx';
import {useExternalService} from 'scout-chat/hooks/logic/use-external-service.tsx';
import {MetadataToolCall} from 'scout-chat/types.ts';
import {castJson} from 'scout-chat/utils/json-utils.ts';

const ExecuteExternalServicesCodeFunctionPermissionMessage: FunctionComponent<{
  toolCall: MetadataToolCall;
}> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    return castJson<ExecuteExternalServicesCodeToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  const {findExternalServiceByName} = useExternalService();
  const externalService = useMemo(() => {
    if (!toolArguments?.service_name) {
      return undefined;
    }

    return findExternalServiceByName(toolArguments?.service_name);
  }, [findExternalServiceByName, toolArguments?.service_name]);

  return (
    <div className='text-center mx-auto flex items-center gap-1'>
      <span>
        {t('function-permissions.message', {
          functionMessage: t('function-permissions.functions.execute_external_services_code.message', {
            serviceName: externalService?.display_name,
          }),
        })}
        {}
      </span>
      <img src={externalService?.icon_url} className='rounded-md size-4 shrink-0' alt='' />
    </div>
  );
};

export default ExecuteExternalServicesCodeFunctionPermissionMessage;
