import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export type AssistantPublicResponse = {
  id: string;
  name: string;
  description: string;
  instructions: string;
  visibility: string;
  is_owner: boolean;
  is_collaborator: boolean;
  owner_name: string;
  prompt_starters: string[];
  avatar_url?: string;
};

export const fetchPublicAssistant = (uuid: string): Promise<AxiosResponse<AssistantPublicResponse>> => {
  return ScoutAPI.get<null, AxiosResponse<AssistantPublicResponse>>(`/assistants/${uuid}/public`);
};
