import {
  addFileToAssistant,
  deleteAssistantFile,
  EditFileInformationRequest,
  fetchAssistantFiles,
  updateFileInformation,
} from '#/repositories/assistants-api/requests/assistant-files.ts';
import {createAssistant} from '#/repositories/assistants-api/requests/create-assistant';
import {deleteAssistant} from '#/repositories/assistants-api/requests/delete-assistant';
import {AssistantResponse, fetchAssistant} from '#/repositories/assistants-api/requests/fetch-assistant';
import {fetchAssistantAvatarAllowedContentTypes} from '#/repositories/assistants-api/requests/fetch-assistant-avatar-allowed-content-types.ts';
import {fetchAssistantLinks} from '#/repositories/assistants-api/requests/fetch-assistant-links.ts';
import {generateAssistantAvatar} from '#/repositories/assistants-api/requests/generate-assistant-avatar.ts';
import {
  improveAssistantPrompt,
  ImprovePromptResponse,
} from '#/repositories/assistants-api/requests/improve-assistant-prompt';
import {updateAssistant, UpdateAssistantRequest} from '#/repositories/assistants-api/requests/update-assistant';
import {
  uploadAssistantAvatar,
  UploadAssistantAvatarResponse,
} from '#/repositories/assistants-api/requests/upload-assistant-avatar.ts';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useAuth} from 'scout-chat/hooks/contexts/use-auth.tsx';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {useErrorMessage} from 'scout-chat/hooks/logic/use-error-message.tsx';

type UseAssistantQueryParams = {
  assistantId?: string;
};
export const useAssistantQuery = ({assistantId}: UseAssistantQueryParams) =>
  useQuery({
    queryKey: ['assistant', assistantId],
    queryFn: async () => {
      const response = await fetchAssistant(assistantId || '');
      return response.data;
    },
    enabled: !!assistantId,
  });

export const useCreateAssistantMutation = (onSuccess: (response: AssistantResponse) => void) => {
  const queryClient = useQueryClient();
  const {user} = useAuth();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['create-assistant'],
    mutationFn: createAssistant,
    onSuccess: ({data}) => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'assistants']});
      onSuccess(data);
    },
    onError: error => {
      addToast(errorMessageForError(error), 'error');
    },
  });
};

export const useUpdateAssistantMutation = (onSuccess: (response: AssistantResponse) => void) => {
  const queryClient = useQueryClient();
  const {user} = useAuth();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['update-assistant'],
    mutationFn: async (data: {assistantId: string; editedAssistant: UpdateAssistantRequest}) =>
      updateAssistant(data.assistantId, data.editedAssistant),
    onSuccess: ({data}) => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'assistants']});
      onSuccess(data);
    },
    onError: (error: AxiosError<{error: string}>) => {
      if (error.response?.status === 400) {
        addToast(error.response.data?.error, 'error');
      } else {
        addToast(errorMessageForError(error), 'error');
      }
    },
  });
};

export const useDeleteAssistantMutation = () => {
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {addToast} = useToasts();
  const {user} = useAuth();

  return useMutation({
    mutationKey: ['delete-assistant'],
    mutationFn: deleteAssistant,
    onSuccess: () => {
      addToast(t('create-edit-assistant.delete-confirmation.toasts.success'), 'success');
      navigate('/chat');
      queryClient.invalidateQueries({queryKey: [user?.id, 'assistants']});
    },
  });
};

export const useGenerateAssistantAvatarMutation = (
  onSuccess: (response: AxiosResponse<UploadAssistantAvatarResponse>) => void,
) => {
  const {addToast} = useToasts();
  const {t} = useTranslation();

  return useMutation({
    mutationKey: ['generate-assistant-avatar'],
    mutationFn: generateAssistantAvatar,
    onError: () => {
      addToast(t('errors.unexpected'), 'error');
    },
    onSuccess,
  });
};

export const useUploadAssistantAvatarMutation = (
  onSuccess: (response: AxiosResponse<UploadAssistantAvatarResponse>) => void,
) => {
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['upload-assistant-avatar'],
    mutationFn: uploadAssistantAvatar,
    onError: (error: Error) => {
      addToast(errorMessageForError(error), 'error');
    },
    onSuccess,
  });
};

export const useAssistantAvatarAllowedContentTypesQuery = () =>
  useQuery({
    queryKey: ['assistant-avatar-allowed-content-types'],
    queryFn: fetchAssistantAvatarAllowedContentTypes,
  });

type UseAssistantFilesQueryParams = {
  assistantId?: string;
  shouldPoll?: boolean;
};
export const useAssistantFilesQuery = ({assistantId, shouldPoll}: UseAssistantFilesQueryParams) =>
  useQuery({
    queryKey: ['assistant-files', assistantId],
    queryFn: async () => {
      const response = await fetchAssistantFiles(assistantId || '');
      return response.data;
    },
    enabled: !!assistantId,
    refetchInterval: shouldPoll ? 1000 : false,
  });

export const useAddFileToAssistantMutation = () => {
  const queryClient = useQueryClient();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['add-file-to-assistant'],
    mutationFn: addFileToAssistant,
    onError: (error: Error) => {
      addToast(errorMessageForError(error), 'error');
    },
    onSuccess: response => {
      queryClient.invalidateQueries({queryKey: ['assistant-files', response.data.assistant_id]});
    },
  });
};

export const useDeleteAssistantFileMutation = () => {
  const queryClient = useQueryClient();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['delete-assistant-file'],
    mutationFn: deleteAssistantFile,
    onError: (error: Error) => {
      addToast(errorMessageForError(error), 'error');
    },
    onSuccess: response => {
      queryClient.invalidateQueries({queryKey: ['assistant-files', response.data.assistant_id]});
    },
  });
};

export const useUpdateFileInformationMutation = () => {
  const queryClient = useQueryClient();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();
  const {t} = useTranslation();

  return useMutation({
    mutationKey: ['update-file-information'],
    mutationFn: async (data: {
      assistantId: string;
      fileId: string;
      editedFileInformation: EditFileInformationRequest;
    }) => updateFileInformation(data.assistantId, data.fileId, data.editedFileInformation),
    onError: (error: Error) => {
      addToast(errorMessageForError(error), 'error');
    },
    onSuccess: response => {
      addToast(t('edit-file.success'), 'success');
      queryClient.invalidateQueries({queryKey: ['assistant-files', response.data.assistant_id]});
    },
  });
};

type UseAssistantLinksQueryParams = {
  assistantId?: string;
  shouldPoll?: boolean;
};
export const useAssistantLinksQuery = ({assistantId, shouldPoll}: UseAssistantLinksQueryParams) =>
  useQuery({
    queryKey: ['assistant-links', assistantId],
    queryFn: async () => {
      const response = await fetchAssistantLinks(assistantId || '');
      return response.data;
    },
    refetchInterval: shouldPoll ? 1000 : false,
    enabled: !!assistantId,
  });

export const useImproveAssistantPromptMutation = (
  onSuccess: (response: AxiosResponse<ImprovePromptResponse>) => void,
) => {
  const {addToast} = useToasts();
  const {t} = useTranslation();

  return useMutation({
    mutationKey: ['improve-assistant-prompt'],
    mutationFn: improveAssistantPrompt,
    onError: () => {
      addToast(t('errors.unexpected'), 'error');
    },
    onSuccess,
  });
};
