import {MobileHeader} from '#/components/MobileHeader.tsx';
import Page from '#/components/Page.tsx';
import {AssistantChatButtons} from '#/components/chat-page/AssistantChatButtons.tsx';
import NewConversationPresentation from '#/components/chat-page/new-conversation/NewConversationPresentation.tsx';
import useFocusPromptInputOnLocationChange from '#/hooks/chat-page/use-focus-prompt-input-on-location-change.tsx';
import {usePrependUpdatedConversationIfNeeded} from '#/hooks/chat-page/use-prepend-updated-conversation-if-needed.tsx';
import {useRedirectOnInvalidConversation} from '#/hooks/chat-page/use-redirect-on-invalid-conversation.tsx';
import {useSendMessageFromBrowserExtension} from '#/hooks/chat-page/use-send-message-from-browser-extension.tsx';
import useSendMessageOnQuerySearchParam from '#/hooks/chat-page/use-send-message-on-query-search-param.tsx';
import {useSendMessagesOnNavigateWithNewMessages} from '#/hooks/chat-page/use-send-messages-on-navigate-with-new-messages.tsx';
import {useStartStreamingOnNewConversation} from '#/hooks/chat-page/use-start-streaming-on-new-conversation.tsx';
import {useConversationsSetQueryData, useUpdateConversationMutation} from '#/hooks/query/conversations.tsx';
import {useAppSettings} from '#/hooks/use-app-settings.tsx';
import {castToConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations.ts';
import {PlusIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Location, NavLink, useLocation, useNavigate, useParams} from 'react-router-dom';
import ModelDropdown from 'scout-chat/components/model-dropdown/ModelDropdown.tsx';
import ScoutChat from 'scout-chat/components/scout-chat/ScoutChat.tsx';
import {useScoutChatContext} from 'scout-chat/components/scout-chat/scout-chat-providers/ScoutChatContext.tsx';
import ConversationScoutChatProvider from 'scout-chat/components/scout-chat/scout-chat-providers/conversation-scout-chat/ConversationScoutChatProvider.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {ConversationResponse} from 'scout-chat/requests/fetch-conversation.ts';
import {ConversationMessage} from 'scout-chat/types.ts';

type ChatPageParams = {
  conversationId: string;
  assistantId: string;
};

export type ChatPageState = {
  shouldStartStream: boolean;
  newMessages?: ConversationMessage[];
} | null;

const ChatPage: FunctionComponent = () => {
  const {
    conversationId,
    messages,
    sendMessage,
    conversationQuery,
    handleStreamChatCompletion,
    sendMessageIsPending,
    chatModels,
    selectedModelId,
    setSelectedModelId,
    promptInputRef,
    assistant,
    assistantQueryIsLoading,
    setPrompt,
  } = useScoutChatContext();

  const {t} = useTranslation();

  const location: Location<ChatPageState> = useLocation();

  useFocusPromptInputOnLocationChange(promptInputRef, location);

  useRedirectOnInvalidConversation(conversationQuery);

  useStartStreamingOnNewConversation(messages, handleStreamChatCompletion, location.state, conversationQuery);

  useSendMessageFromBrowserExtension(sendMessage);

  useSendMessagesOnNavigateWithNewMessages(location.state, sendMessage, conversationQuery);

  useSendMessageOnQuerySearchParam(sendMessage, conversationId);

  usePrependUpdatedConversationIfNeeded(conversationQuery?.data, sendMessageIsPending);

  const onStarterPromptSelect = useCallback((prompt: string) => setPrompt(prompt), [setPrompt]);

  const {conversationMaxWidth} = useAppSettings();

  return (
    <Page title={t('conversation.page-title')}>
      <ScoutChat maxWidth={conversationMaxWidth} showNewConversationPresentation={!conversationId}>
        {{
          innerHeader: (
            <>
              <ModelDropdown
                chatModels={chatModels}
                selectedModelId={selectedModelId}
                setSelectedModelId={setSelectedModelId}
                className='absolute z-20 top-4 left-4 md:flex hidden'
              />
              <AssistantChatButtons assistant={assistant} className='absolute z-20 top-4 right-4' />
            </>
          ),
          outerHeader: (
            <MobileHeader>
              <ModelDropdown
                chatModels={chatModels}
                selectedModelId={selectedModelId}
                setSelectedModelId={setSelectedModelId}
                className='md:hidden'
              />
              <NavLink
                to='/chat'
                className='aspect-square h-12 rounded-md bg-accent flex justify-center items-center hover:opacity-70 transition-opacity'
              >
                <PlusIcon className='size-7 stroke-accent-inverse' />
              </NavLink>
            </MobileHeader>
          ),
          newConversationPresentation: (
            <NewConversationPresentation
              assistant={assistant}
              onStarterPromptSelect={onStarterPromptSelect}
              maxWidth={conversationMaxWidth}
              assistantQueryIsLoading={assistantQueryIsLoading}
            />
          ),
        }}
      </ScoutChat>
    </Page>
  );
};

const withConversationScoutChatProvider = (Component: FunctionComponent) => {
  return () => {
    const {i18n} = useTranslation();
    const {conversationId, assistantId} = useParams<ChatPageParams>();
    const {
      config: {features},
    } = useConfig();

    const navigate = useNavigate();
    const setConversationsQueryData = useConversationsSetQueryData();
    const onCreateConversationSuccess = useCallback(
      (createdConversation: ConversationResponse) => {
        setConversationsQueryData((oldData = []) => {
          return [castToConversationSummaryResponse(createdConversation), ...oldData];
        });
        navigate(`/chat/${createdConversation.id}`, {state: {shouldStartStream: true}, replace: true});
      },
      [navigate, setConversationsQueryData],
    );

    const {mutateAsync: updateConversationMutateAsync} = useUpdateConversationMutation();
    const onSelectedModelIdChange = useCallback(
      async (selectedModelId: string | undefined) => {
        if (conversationId) {
          await updateConversationMutateAsync({
            conversationId: conversationId,
            editedConversation: {model: selectedModelId},
          });
        }
      },
      [conversationId, updateConversationMutateAsync],
    );

    const location = useLocation();
    const onStopStreaming = useCallback(() => {
      if (location.state?.shouldStartStream) {
        navigate('/chat/' + conversationId, {replace: true});
      }
    }, [navigate, location.state?.shouldStartStream, conversationId]);

    return (
      <ConversationScoutChatProvider
        conversationId={conversationId}
        assistantId={assistantId}
        mentionAssistantEnabled={features.assistants}
        onCreateConversationSuccess={onCreateConversationSuccess}
        onSelectedModelIdChange={onSelectedModelIdChange}
        onStopStreaming={onStopStreaming}
        language={i18n.language}
      >
        <Component />
      </ConversationScoutChatProvider>
    );
  };
};

export default withConversationScoutChatProvider(ChatPage);
