import {EditedStep} from '#/repositories/assistants-api/requests/update-task-capability-step.ts';
import {useMemo} from 'react';

const useFieldValues = (
  editedSteps: (EditedStep | null)[],
  originalSteps: {prompt: string; code: string}[],
  selectedStepIndex: number,
) => {
  return useMemo(() => {
    return {
      prompt: editedSteps.at(selectedStepIndex)?.prompt ?? originalSteps.at(selectedStepIndex)?.prompt ?? '',
      code: editedSteps.at(selectedStepIndex)?.code,
    };
  }, [editedSteps, originalSteps, selectedStepIndex]);
};

export default useFieldValues;
