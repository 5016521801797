import {useDeleteConversationMutation} from '#/hooks/query/conversations.tsx';
import useExportRawConversation from '#/hooks/sidebar/use-export-raw-conversation.tsx';
import {useConversations} from '#/hooks/use-conversations';
import {
  SidebarDropdownMenuContent,
  SidebarDropdownMenuItem,
  SidebarDropdownMenuTrigger,
} from '#/library/sidebar/SidebarDropdownMenu';
import * as Dialog from '@radix-ui/react-dialog';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {Cross1Icon, DownloadIcon, Pencil1Icon} from '@radix-ui/react-icons';
import {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Button} from 'scout-chat/components/Button.tsx';
import {ReactComponent as TrashIcon} from 'src/resources/trash-icon.svg';

export interface ConversationMoreMenuProps {
  uuid: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  setIsEditing: (uuid: string | null) => void;
  setEditedTitle: (title: string) => void;
  currentTitle: string;
}

const ConversationMoreMenu: FunctionComponent<ConversationMoreMenuProps> = ({
  uuid,
  isOpen,
  onOpenChange,
  setIsEditing,
  setEditedTitle,
  currentTitle,
}) => {
  const {t} = useTranslation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const {activeConversationId} = useConversations();
  const navigate = useNavigate();
  const deleteConversationMutation = useDeleteConversationMutation();
  const exportRawConversation = useExportRawConversation(uuid);

  const handleDelete = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      try {
        await deleteConversationMutation.mutateAsync(uuid);
        if (activeConversationId === uuid) {
          navigate('/chat', {replace: true});
        }
      } finally {
        setIsDeleteDialogOpen(false);
        onOpenChange(false);
      }
    },
    [deleteConversationMutation, uuid, activeConversationId, navigate, setIsDeleteDialogOpen, onOpenChange],
  );

  const handleEditButtonClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setIsEditing(uuid);
      setEditedTitle(currentTitle);
    },
    [currentTitle, setEditedTitle, setIsEditing, uuid],
  );

  const handleExportButtonClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      exportRawConversation();
      onOpenChange(false);
    },
    [exportRawConversation, onOpenChange],
  );

  const handleDeleteButtonClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setIsDeleteDialogOpen(true);
      onOpenChange(false);
    },
    [setIsDeleteDialogOpen, onOpenChange],
  );

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
      <SidebarDropdownMenuTrigger />

      <DropdownMenu.Portal>
        <SidebarDropdownMenuContent>
          <SidebarDropdownMenuItem onClick={handleEditButtonClick}>
            <Pencil1Icon width={18} height={18} />
            <span>{t('sidebar.conversations.actions.rename')}</span>
          </SidebarDropdownMenuItem>

          <SidebarDropdownMenuItem onClick={handleExportButtonClick}>
            <DownloadIcon width={18} height={18} />
            <span>{t('sidebar.conversations.actions.export')}</span>
          </SidebarDropdownMenuItem>

          <SidebarDropdownMenuItem className='text-danger' onClick={handleDeleteButtonClick}>
            <TrashIcon className='stroke-danger' width={18} height={18} />
            <span>{t('sidebar.conversations.actions.delete.single')}</span>
          </SidebarDropdownMenuItem>

          <DropdownMenu.Arrow className='fill-surface-02' />
        </SidebarDropdownMenuContent>
      </DropdownMenu.Portal>

      <Dialog.Root open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className='fixed inset-0 bg-black/50 z-50' />
          <Dialog.Content className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-surface-02 p-6 rounded-xl shadow-lg z-50 w-full sm:w-auto max-w-dialog-mobile sm:max-w-dialog-desktop text-primary'>
            <Dialog.Title className='text-lg font-bold mb-4'>
              {t('sidebar.conversations.actions.delete.confirmation.message-single')}
            </Dialog.Title>
            <Dialog.Description className='mb-4'>{t('common.delete-confirmation.warning')}</Dialog.Description>
            <div className='flex justify-end space-x-4'>
              <Dialog.Close asChild>
                <Button type='button' variant='secondary' size='sm' textSize='base' onClick={e => e.stopPropagation()}>
                  <Cross1Icon className='stroke-primary' width={18} height={18} />
                  {t('common.actions.cancel')}
                </Button>
              </Dialog.Close>
              <Button
                type='button'
                variant='cancel'
                size='sm'
                textSize='base'
                onClick={handleDelete}
                isLoading={deleteConversationMutation.isPending}
              >
                <TrashIcon className='stroke-accent-inverse' />
                {t('common.actions.delete')}
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </DropdownMenu.Root>
  );
};

export default ConversationMoreMenu;
