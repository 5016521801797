import {useErrorMessage} from 'scout-chat/hooks/logic/use-error-message.tsx';

import {useMutation} from '@tanstack/react-query';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {
  playStreamingSpeechSynthesis,
  StreamSpeechSynthesisRequest,
} from 'scout-chat/requests/play-streaming-speech-synthesis.ts';

export const usePlayStreamingSpeechSynthesisMutation = (
  onPlayerCreated: (howler: Howl) => void,
  onPlayerPlay: () => void,
  onPlayerEnd: () => void,
) => {
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['stream-speech-synthesis'],
    mutationFn: async (data: StreamSpeechSynthesisRequest) =>
      playStreamingSpeechSynthesis(data, onPlayerCreated, onPlayerPlay, onPlayerEnd),
    onError: error => {
      addToast(errorMessageForError(error, 'errors.unexpected'), 'error');
    },
  });
};
