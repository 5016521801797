import {ImageIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ToolUsedItemProps, ToolUsedItemWrapper} from 'scout-chat/components/tools-used/ToolUsedItem.tsx';
import {castJson} from 'scout-chat/utils/json-utils.ts';

interface GenerateAudioTranscriptionToolArguments {
  filename: string;
  model?: string;
}

const GenerateAudioTranscriptionToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    return castJson<GenerateAudioTranscriptionToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  return (
    <ToolUsedItemWrapper>
      <div className='flex items-center gap-2'>
        <ImageIcon className='inline-block size-4 shrink-0 text-accent' />
        <span>
          {t('tool-used.tools.generate-audio-transcription', {
            filename: toolArguments?.filename,
          })}
        </span>
      </div>
    </ToolUsedItemWrapper>
  );
};
export default GenerateAudioTranscriptionToolUsedItem;
