import {MobileHeader} from '#/components/MobileHeader.tsx';
import Page from '#/components/Page.tsx';
import SharedUsersSelect from '#/components/SharedUsersSelect.tsx';
import CreateEditSchedulesSection from '#/components/tasks/CreateEditSchedulesSection.tsx';
import EditTaskVariablesSection from '#/components/tasks/create-edit-task/EditTaskVariablesSection.tsx';
import TaskCapabilitySection from '#/components/tasks/create-edit-task/TaskCapabilitySection.tsx';
import {
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useDuplicateTaskMutation,
  useTaskQuery,
  useUpdateTaskMutation,
} from '#/hooks/query/tasks.tsx';
import Label from '#/library/label/Label.tsx';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import Select, {Option} from '#/library/select/Select.tsx';
import {Textarea} from '#/library/textarea/Textarea.tsx';
import {startTask} from '#/repositories/assistants-api/requests/start-task.ts';
import {ModelVisibilityType, SharedUser} from '#/repositories/assistants-api/requests/visibility.ts';
import {ReactComponent as CheckmarkIcon} from '#/resources/checkmark-icon.svg';
import {ReactComponent as CopyIcon} from '#/resources/copy-icon.svg';
import {ReactComponent as PlayIcon} from '#/resources/play-icon.svg';
import {ReactComponent as TrashIcon} from '#/resources/trash-icon.svg';
import {parseSharedUsersToEmails} from '#/utils/model-visibility-utils.ts';
import {GearIcon} from '@radix-ui/react-icons';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Alert from 'scout-chat/components/Alert.tsx';
import {Button} from 'scout-chat/components/Button.tsx';
import ScoutSpinner from 'scout-chat/components/ScoutSpinner.tsx';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';

type CreateEditTaskPageParams = {
  taskId: string;
};

const CreateEditTaskPage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {taskId} = useParams<CreateEditTaskPageParams>();

  const [description, setDescription] = useState('');
  const [instructions, setInstructions] = useState('');
  const [visibilityType, setVisibilityType] = useState<ModelVisibilityType>('private');
  const [sharedUsers, setSharedUsers] = useState<readonly SharedUser[]>([]);
  const [defaultInputVariables, setDefaultInputVariables] = useState<Record<string, string>>({});
  const [shouldPoll, setShouldPoll] = useState(true);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const {addToast} = useToasts();

  const taskQuery = useTaskQuery({taskId, shouldPoll});

  useEffect(() => {
    if (taskQuery.data) {
      setDescription(taskQuery.data.description);
      setInstructions(taskQuery.data.instructions);
      setShouldPoll(taskQuery.data.status === 'IN_QUEUE' || taskQuery.data.status === 'PROCESSING');
      setVisibilityType(taskQuery.data.visibility.type);
      setSharedUsers(taskQuery.data.visibility.shared_users || []);
      setDefaultInputVariables(taskQuery.data.default_input_variables || {});
    } else {
      setDescription('');
      setInstructions('');
      setDefaultInputVariables({});
    }
  }, [taskQuery.data]);
  const status = taskQuery.data?.status;
  const isOwner = taskQuery.data?.is_owner;
  const capabilities = taskQuery.data?.capabilities;
  const isViewer = !!taskId && !isOwner;

  const shouldDisplayFailedProcessing = status === 'FAILED';
  const shouldDisplayProcessing = status === 'IN_QUEUE' || status === 'PROCESSING';

  const createTaskMutation = useCreateTaskMutation(() => {
    addToast(t('create-edit-task.toasts.create-success'), 'success');
  });

  const navigateToEdit = useCallback(
    (taskId: string) => {
      navigate(`/tasks/${taskId}/edit`);
    },
    [navigate],
  );

  const navigateToRuns = useCallback(
    (taskId: string) => {
      navigate(`/tasks/${taskId}/runs`);
    },
    [navigate],
  );

  const handleAddTask = useCallback(async () => {
    const response = await createTaskMutation.mutateAsync({
      description,
      instructions,
      visibility: {
        type: visibilityType,
        user_emails: parseSharedUsersToEmails(visibilityType, sharedUsers),
      },
    });
    navigateToEdit(response.data.id);
  }, [createTaskMutation, description, instructions, navigateToEdit, sharedUsers, visibilityType]);

  const updateTaskMutation = useUpdateTaskMutation(() => {
    addToast(t('create-edit-task.toasts.edit-success'), 'success');
  });

  const handleVisibilityChange = useCallback((newVisibility: string) => {
    setVisibilityType(newVisibility as ModelVisibilityType);
  }, []);
  const visibilityOptions: Option[] = useMemo(() => {
    return [
      {value: 'private', label: t('model-visibility.private')},
      {value: 'shared', label: t('model-visibility.shared')},
      {value: 'public', label: t('model-visibility.public')},
    ];
  }, [t]);

  const duplicateTaskMutation = useDuplicateTaskMutation();

  const handleDuplicateTask = useCallback(async () => {
    const response = await duplicateTaskMutation.mutateAsync(taskId || '');
    navigate(`/tasks/${response.data.id}/edit`);
    addToast(t('create-edit-task.toasts.duplicate-success'), 'success');
  }, [addToast, duplicateTaskMutation, navigate, t, taskId]);

  const handleDebugButtonClick = useCallback(() => {
    navigate(`/tasks/${taskId}/debug`);
  }, [navigate, taskId]);

  const updateTask = useCallback(async () => {
    return await updateTaskMutation.mutateAsync({
      taskId: taskId || '',
      editedTask: {
        description,
        instructions,
        variables: defaultInputVariables,
        visibility: {
          type: visibilityType,
          user_emails: parseSharedUsersToEmails(visibilityType, sharedUsers),
        },
      },
    });
  }, [updateTaskMutation, taskId, description, instructions, defaultInputVariables, visibilityType, sharedUsers]);

  const handleUpdateTask = useCallback(async () => {
    const response = await updateTask();
    navigateToEdit(response.data.id);
  }, [navigateToEdit, updateTask]);

  const handleNewRun = useCallback(async () => {
    await startTask(taskId || '');
    navigateToRuns(taskId ?? '');
  }, [navigateToRuns, taskId]);

  const deleteTaskMutation = useDeleteTaskMutation();
  const handleDeleteTaskConfirmation = useCallback(() => {
    deleteTaskMutation.mutate(taskId || '');
  }, [taskId, deleteTaskMutation]);

  const error = taskQuery.error || deleteTaskMutation.error;

  const handleDeleteTaskRequest = useCallback(() => {
    if (!showDeleteConfirmation) {
      setShowDeleteConfirmation(true);
      return;
    }
  }, [showDeleteConfirmation]);

  return (
    <Page title={taskId ? t('create-edit-task.page-title.edit') : t('create-edit-task.page-title.create')}>
      <MobileHeader>
        <h2 className='grow font-bold text-center md:text-left'>
          {taskId ? t('create-edit-task.page-title.edit') : t('create-edit-task.page-title.create')}
        </h2>
      </MobileHeader>

      <div className='size-full flex flex-col items-stretch overflow-y-auto rounded-2xl'>
        <PageContentHeader
          title={taskId ? t('create-edit-task.page-title.edit') : t('create-edit-task.page-title.create')}
        >
          <Link draggable={false} to='/tasks' className='text-xs text-secondary mr-4'>
            {t('create-edit-task.actions.navigate-to-tasks')}
          </Link>
        </PageContentHeader>

        <div className='max-w-page-content grow w-full flex flex-col mx-auto gap-y-6 bg-surface-01 px-4 pt-4'>
          <div>
            <Label htmlFor='task-description'>{t('create-edit-task.fields.description.label')}</Label>
            <Textarea
              id='task-description'
              placeholder={t('create-edit-task.fields.description.placeholder')}
              minRows={4}
              disabled={isViewer}
              value={description}
              onValueChange={e => setDescription(e.target.value)}
            />
          </div>

          <div>
            <Label htmlFor='task-instructions'>{t('create-edit-task.fields.instructions.label')}</Label>
            <Textarea
              id='task-instructions'
              placeholder={t('create-edit-task.fields.instructions.placeholder')}
              value={instructions}
              disabled={isViewer}
              onValueChange={e => setInstructions(e.target.value)}
              minRows={10}
            />
          </div>

          {error && <Alert variant='warning'>{error.message}</Alert>}

          <CreateEditSchedulesSection taskId={taskId} />

          {shouldDisplayFailedProcessing ? (
            <div className='bg-warning p-4 rounded-xl text-primary border border-stroke-main'>
              {t('create-edit-task.fields.ai-detail.failed')}
            </div>
          ) : shouldDisplayProcessing ? (
            <div className='flex items-center gap-2 p-4 bg-surface-02 rounded-xl text-primary'>
              <ScoutSpinner /> {t('create-edit-task.fields.ai-detail.processing')}
            </div>
          ) : (
            <div>
              <span className='block text-primary font-bold text-xl mb-4'>
                {t('create-edit-task.fields.ai-detail.label')}
              </span>

              {taskQuery.data && (
                <EditTaskVariablesSection
                  isOwner={isOwner}
                  variables={defaultInputVariables}
                  onVariableValueChange={(variableName: string, newValue: string) => {
                    setDefaultInputVariables({
                      ...defaultInputVariables,
                      [variableName]: newValue,
                    });
                  }}
                />
              )}

              <div className='flex flex-col gap-4'>
                {capabilities?.map((capability, index) => (
                  <TaskCapabilitySection
                    key={`${capability.id}${index}`}
                    taskId={taskId}
                    capability={capability}
                    isOwner={isOwner}
                  />
                ))}
              </div>
            </div>
          )}

          {!isViewer && (
            <div>
              <Label htmlFor='task-visibility'>{t('model-visibility.label')}</Label>
              <Select
                value={visibilityType}
                onChange={handleVisibilityChange}
                options={visibilityOptions}
                fullWidth={true}
                variant='bordered'
              />

              {visibilityType === 'shared' && (
                <SharedUsersSelect className='mt-2' sharedUsers={sharedUsers} setSharedUsers={setSharedUsers} />
              )}
            </div>
          )}

          <div className='sticky flex flex-col gap-0 bottom-0 bg-transparent'>
            <div className='w-full h-8 bg-gradient-to-t from-surface-01' />

            <div className='w-full mx-auto bg-surface-01 flex justify-end items-center md:pb-6 pb-3 pt-2'>
              <div className='w-full flex items-center justify-end gap-4 flex-wrap max-w-fit'>
                {!taskId ? (
                  <>
                    <Button
                      type='button'
                      variant='primary'
                      size='sm'
                      textSize='base'
                      isLoading={createTaskMutation.isPending}
                      disabled={createTaskMutation.isPending || !description || !instructions}
                      onClick={handleAddTask}
                    >
                      {t('create-edit-task.actions.create')}
                    </Button>
                  </>
                ) : showDeleteConfirmation ? (
                  <>
                    <div className='flex items-center justify-end text-right'>
                      <span className='text-red-600 text-sm'>
                        {t('create-edit-task.delete-confirmation.message')}
                        <br /> <strong>{t('create-edit-task.delete-confirmation.warning')}</strong>
                      </span>
                    </div>
                    <Button
                      type='button'
                      variant='cancel'
                      size='sm'
                      textSize='base'
                      isLoading={deleteTaskMutation.isPending}
                      disabled={deleteTaskMutation.isPending || isViewer}
                      onClick={handleDeleteTaskConfirmation}
                    >
                      {t('create-edit-task.delete-confirmation.confirmation-button')}
                    </Button>
                    <Button
                      type='button'
                      variant='secondary'
                      size='sm'
                      textSize='base'
                      onClick={() => {
                        setShowDeleteConfirmation(false);
                      }}
                    >
                      {t('conversation.actions.cancel.content')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type='button'
                      variant='cancel'
                      size='sm'
                      textSize='base'
                      disabled={updateTaskMutation.isPending || isViewer}
                      onClick={handleDeleteTaskRequest}
                    >
                      <TrashIcon className='stroke-accent-inverse size-6' />
                      {t('create-edit-task.actions.delete')}
                    </Button>
                    <Button
                      type='button'
                      variant='primary'
                      size='sm'
                      textSize='base'
                      isLoading={duplicateTaskMutation.isPending}
                      disabled={duplicateTaskMutation.isPending}
                      onClick={handleDuplicateTask}
                    >
                      <CopyIcon className='stroke-accent-inverse size-6' />
                      {t('create-edit-task.actions.duplicate')}
                    </Button>
                    <Button
                      type='button'
                      variant='primary'
                      size='sm'
                      textSize='base'
                      className='hidden'
                      disabled={updateTaskMutation.isPending || isViewer}
                      onClick={handleDebugButtonClick}
                    >
                      <GearIcon className='text-accent-inverse size-6' />
                      {t('create-edit-task.actions.debug')}
                    </Button>
                    <Button
                      type='button'
                      variant='primary'
                      size='sm'
                      textSize='base'
                      isLoading={updateTaskMutation.isPending}
                      disabled={updateTaskMutation.isPending || isViewer}
                      onClick={handleUpdateTask}
                    >
                      <CheckmarkIcon className='stroke-accent-inverse size-6' />
                      {t('create-edit-task.actions.save')}
                    </Button>
                    <Button
                      type='button'
                      variant='primary'
                      size='sm'
                      textSize='base'
                      disabled={updateTaskMutation.isPending || !status || status !== 'PROCESSED'}
                      onClick={handleNewRun}
                    >
                      <PlayIcon className='stroke-accent-inverse size-6' />
                      {t('create-edit-task.actions.new-run')}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default CreateEditTaskPage;
