import {useMemo} from 'react';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';

export const useUserVisibleAssistants = (assistants: AssistantPublicResponse[]) => {
  return useMemo(() => {
    return assistants.reduce(
      (acc, assistant) => {
        if (assistant.is_owner) {
          acc.privateAssistants.push(assistant);
        } else if (assistant.is_collaborator) {
          acc.collaborativeAssistants.push(assistant);
        } else {
          acc.sharedAssistants.push(assistant);
        }
        return acc;
      },
      {
        privateAssistants: [] as AssistantPublicResponse[],
        collaborativeAssistants: [] as AssistantPublicResponse[],
        sharedAssistants: [] as AssistantPublicResponse[],
      },
    );
  }, [assistants]);
};
