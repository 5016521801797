import {useTranslation} from 'react-i18next';
import {DefaultLoader} from 'scout-chat/components/tool-loaders/DefaultToolLoader.tsx';

const SearchCapabilitiesToolLoader = () => {
  const {t} = useTranslation();

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />

      <span>{t('tools.search-capabilities.in-progress-text')}</span>
    </div>
  );
};
export default SearchCapabilitiesToolLoader;
