import {Cross1Icon} from '@radix-ui/react-icons';
import {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import AssistantAvatar from 'scout-chat/components/AssistantAvatar.tsx';
import {usePublicAssistantQuery} from 'scout-chat/hooks/requests/use-public-assistant-query.tsx';

type AssistantInfoParams = {
  assistantId: string;
};

const AssistantInfo: FunctionComponent = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const {assistantId} = useParams<AssistantInfoParams>();
  const assistantQuery = usePublicAssistantQuery({assistantId});
  const assistant = assistantQuery.data;

  return (
    <div className='flex flex-1 bg-black/[0.8] absolute inset-0 justify-center items-center z-20' onClick={handleClose}>
      <div
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
        }}
        className='relative flex flex-col items-center gap-6 md:w-3/5 w-[90%] max-h-[80%] overflow-y-auto p-8 bg-surface-01 border border-stroke-main rounded-2xl text-primary'
      >
        <button onClick={handleClose} className='absolute right-6 top-6 p-2 hover:opacity-70 transition-opacity '>
          <Cross1Icon className=' size-4 stroke-primary' />
        </button>
        {assistant && (
          <>
            <AssistantAvatar
              src={assistant.avatar_url}
              containerClassName='size-20 aspect-square'
              className='rounded-xl mx-auto text-accent-inverse text-2xl font-medium'
              alt={t('conversation.assistant.avatar.alt')}
              assistantName={assistant.name || ''}
            />
            <div className='text-xl'>{assistant.name}</div>
            <div className='text-lg'>{assistant.description}</div>
            <div className='whitespace-pre-wrap'>{assistant.instructions}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default AssistantInfo;
