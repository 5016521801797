import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export type CreateTaskScheduleRequest = {
  task_id: string;
  prompt: string;
};

export type ScheduleResponse = {
  crontab_expression: string;
  crontab_description: string;
};

export type TaskScheduleResponse = {
  id: string;
  task_id: string;
  prompt: string;
  schedule?: ScheduleResponse;
};

export const createTaskSchedule = (
  request: CreateTaskScheduleRequest,
): Promise<AxiosResponse<TaskScheduleResponse>> => {
  return ScoutAPI.post<null, AxiosResponse<TaskScheduleResponse>>(`/tasks/${request.task_id}/schedules`, request);
};
