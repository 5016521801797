import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';

const BrandIconBox: FunctionComponent<{className?: string; variant: 'expanded' | 'collapsed'}> = ({
  className,
  variant,
}) => {
  const {
    config: {
      application: {companyName},
    },
  } = useConfig();
  const {t} = useTranslation();

  return (
    <NavLink
      to='/chat'
      className={`bg-surface-01 text-primary rounded-2xl flex justify-center items-stretch gap-3 ${className || ''}`}
    >
      <div className='h-9 aspect-square bg-accent flex justify-center items-center rounded-xl'>
        <img className='inline h-5' src='/app-logo.svg' alt={t('sidebar.app-logo-alt')} />
      </div>
      {variant === 'expanded' && (
        <span className='grow text-left self-center text-base font-bold select-none'>{companyName}</span>
      )}
    </NavLink>
  );
};

export default BrandIconBox;
