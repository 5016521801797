import {FunctionComponent, memo} from 'react';
import ProtectedImg, {ProtectedImgProps} from 'scout-chat/components/ProtectedImg.tsx';

const AssistantAvatar: FunctionComponent<
  ProtectedImgProps & {
    assistantName: string;
  }
> = ({assistantName, className, containerClassName, ...props}) => {
  if (!props.src) {
    return (
      <div {...props} className={containerClassName}>
        <div
          className={`size-full flex items-center justify-center rounded-full ${!props.src ? ' bg-accent text-accent-inverse' : ''} ${className}`}
        >
          <span>{assistantName[0]}</span>
        </div>
      </div>
    );
  }

  return (
    <ProtectedImg
      draggable={false}
      containerClassName={containerClassName}
      className={`${className} size-full object-cover`}
      {...props}
    />
  );
};

export default memo(AssistantAvatar);
