import {useDeleteConversationsMutation} from '#/hooks/query/conversations.tsx';
import {useConversations} from '#/hooks/use-conversations';
import {SidebarDropdownMenuContent, SidebarDropdownMenuItem} from '#/library/sidebar/SidebarDropdownMenu';
import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations';
import * as Dialog from '@radix-ui/react-dialog';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {Cross1Icon, DotsHorizontalIcon} from '@radix-ui/react-icons';
import {ComponentProps, FunctionComponent, forwardRef, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Button} from 'scout-chat/components/Button.tsx';
import {ReactComponent as TrashIcon} from 'src/resources/trash-icon.svg';

export interface ConversationListMoreMenuProps {
  isOpen: boolean;
  conversations: ConversationSummaryResponse[];
  onOpenChange: (isOpen: boolean) => void;
}

const ConversationListMoreMenu: FunctionComponent<ConversationListMoreMenuProps> = ({
  isOpen,
  conversations,
  onOpenChange,
}) => {
  const {t} = useTranslation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const {activeConversationId} = useConversations();
  const navigate = useNavigate();
  const deleteConversationsMutation = useDeleteConversationsMutation();

  const handleDelete = useCallback(async () => {
    const conversationIds = conversations.map(conversation => conversation.id);
    const shouldRedirect = activeConversationId != null && conversationIds.includes(activeConversationId);

    try {
      await deleteConversationsMutation.mutateAsync(conversationIds);
      if (shouldRedirect) {
        navigate('/chat', {replace: true});
      }
    } finally {
      setIsDeleteDialogOpen(false);
      onOpenChange(false);
    }
  }, [conversations, activeConversationId, deleteConversationsMutation, navigate, setIsDeleteDialogOpen, onOpenChange]);

  const handleDeleteButtonClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setIsDeleteDialogOpen(true);
      onOpenChange(false);
    },
    [setIsDeleteDialogOpen, onOpenChange],
  );

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
      <ConversationListMoreMenuDropdownTrigger />

      <DropdownMenu.Portal>
        <SidebarDropdownMenuContent>
          <SidebarDropdownMenuItem className='text-danger' onClick={handleDeleteButtonClick}>
            <TrashIcon className='stroke-danger' width={18} height={18} />
            <span>{t('sidebar.conversations.actions.delete.all')}</span>
          </SidebarDropdownMenuItem>

          <DropdownMenu.Arrow className='fill-surface-02' />
        </SidebarDropdownMenuContent>
      </DropdownMenu.Portal>

      <Dialog.Root open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className='fixed inset-0 bg-black/50 z-50' />
          <Dialog.Content className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-surface-02 p-6 rounded-xl shadow-lg z-50 w-full sm:w-auto max-w-dialog-mobile sm:max-w-dialog-desktop text-primary'>
            <Dialog.Title className='text-lg font-bold mb-4 break-words'>
              {t('sidebar.conversations.actions.delete.confirmation.message-multiple')}
            </Dialog.Title>
            <Dialog.Description className='mb-4 break-words'>
              {t('common.delete-confirmation.warning')}
            </Dialog.Description>
            <div className='flex flex-wrap justify-end gap-4'>
              <Dialog.Close asChild>
                <Button type='button' variant='secondary' size='sm' textSize='base' onClick={e => e.stopPropagation()}>
                  <Cross1Icon className='stroke-primary' width={18} height={18} />
                  {t('common.actions.cancel')}
                </Button>
              </Dialog.Close>
              <Button
                type='button'
                variant='cancel'
                size='sm'
                textSize='base'
                onClick={handleDelete}
                isLoading={deleteConversationsMutation.isPending}
              >
                <TrashIcon className='stroke-accent-inverse' />
                {t('common.actions.delete')}
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </DropdownMenu.Root>
  );
};

const ConversationListMoreMenuDropdownTrigger: FunctionComponent<ComponentProps<typeof DropdownMenu.Trigger>> =
  forwardRef((props, ref) => (
    <DropdownMenu.Trigger {...props} ref={ref}>
      <DotsHorizontalIcon className='size-4' />
    </DropdownMenu.Trigger>
  ));

export default ConversationListMoreMenu;
