export const shuffleArray = <T>(array: T[]): T[] => {
  const shuffledArray = [...array];
  shuffledArray.sort(() => 0.5 - Math.random());
  return shuffledArray;
};

export const getRandomItems = <T>(array: T[], count: number): T[] => {
  const cappedCount = Math.min(count, array.length);

  const selectedIndices = new Set<number>();

  while (selectedIndices.size < cappedCount) {
    const randomIndex = Math.floor(Math.random() * array.length);
    selectedIndices.add(randomIndex);
  }

  return Array.from(selectedIndices).map(index => array[index]);
};
