import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';
import {ConversationResponse} from 'scout-chat/requests/fetch-conversation.ts';
import {ConversationMessage} from 'scout-chat/types.ts';

export type CreateConversationRequest = {
  payload: ConversationMessage[];
  time_zone_offset: string;
  assistant_id?: string;
  title?: string;
  model: string;
};

export const createConversation = (
  conversation: CreateConversationRequest,
): Promise<AxiosResponse<ConversationResponse>> => {
  return ScoutAPI.post<CreateConversationRequest, AxiosResponse<ConversationResponse>>('/conversations/', conversation);
};
