import {useLogin} from '#/hooks/use-login.tsx';
import {CredentialResponse, GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import {FunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useErrorMessage} from 'scout-chat/hooks/logic/use-error-message.tsx';

const GoogleLoginButton: FunctionComponent<{clientId: string; setErrorMessage: (arg0: string) => void}> = ({
  clientId,
  setErrorMessage,
}) => {
  const {i18n, t} = useTranslation();
  const {errorMessageForError} = useErrorMessage();

  const [isLoading, setIsLoading] = useState(false);

  const {login} = useLogin();
  const navigate = useNavigate();

  const handleSuccess = async (credentialResponse: CredentialResponse) => {
    setIsLoading(true);
    setErrorMessage('');
    if (isLoading) return;
    if (credentialResponse.credential === undefined) {
      setErrorMessage(t('errors.unexpected'));
      setIsLoading(false);
      return;
    }

    try {
      await login({
        oauth_token: credentialResponse.credential,
        nonce: null,
        provider: 'google',
      });

      navigate('/chat');
    } catch (error) {
      setErrorMessage(errorMessageForError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = () => {
    setErrorMessage(t('errors.google-login'));
  };

  return (
    <div className='flex justify-center'>
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin locale={i18n.language} onSuccess={handleSuccess} onError={handleError} />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleLoginButton;
