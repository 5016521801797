import AppContentWrapper from '#/AppContentWrapper';
import {Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {ConfigurationProvider} from 'scout-chat/hooks/contexts/use-config.tsx';
import {ThemeProvider} from 'scout-chat/hooks/contexts/use-theme.tsx';
import environment from 'scout-chat/repositories/environment.ts';

export const APP_NAME = 'Scout';

const App = () => {
  return (
    <BrowserRouter>
      <Suspense>
        <ConfigurationProvider baseUrl={`${environment.baseApiUrl}/api`}>
          <ThemeProvider>
            <AppContentWrapper />
          </ThemeProvider>
        </ConfigurationProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
