import {useMutation} from '@tanstack/react-query';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {useErrorMessage} from 'scout-chat/hooks/logic/use-error-message.tsx';
import {
  updateConversationMessage,
  UpdateConversationMessageRequest,
} from 'scout-chat/requests/update-conversation-message.ts';

export const useUpdateConversationMessageMutation = () => {
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['edit-conversation-message'],
    mutationFn: async (data: {conversationId: string; request: UpdateConversationMessageRequest}) =>
      updateConversationMessage(data.conversationId, data.request),
    onError: error => {
      addToast(errorMessageForError(error, 'toasts.edit-message-failure'), 'error');
    },
  });
};
