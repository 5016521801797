import LayoutContentWrapper from '#/components/layout/LayoutContentWrapper.tsx';
import SidebarWrapper from '#/components/layout/SidebarWrapper.tsx';
import useActiveAssistantId from '#/hooks/use-active-assistant-id.tsx';
import {ConversationRefsProvider} from '#/hooks/use-conversation-refs.tsx';
import {useConversations} from '#/hooks/use-conversations.tsx';
import {ConversationsSidebar} from '#/library/sidebar/ConversationsSidebar.tsx';
import {FunctionComponent, useMemo} from 'react';
import {Outlet, useLocation} from 'react-router-dom';

export enum SidebarContextEnum {
  Chat = 'chat',
  Assistants = 'assistants',
}

const ConversationsLayout: FunctionComponent = () => {
  const {conversations} = useConversations();
  const activeAssistantId = useActiveAssistantId();
  const location = useLocation();

  const filteredConversations = useMemo(() => {
    if (location.pathname.startsWith('/assistants')) {
      return conversations.filter(conversation => {
        if (!activeAssistantId) {
          return !!conversation.assistant_id;
        }
        return conversation.assistant_id === activeAssistantId;
      });
    }
    return conversations;
  }, [activeAssistantId, conversations, location.pathname]);

  const context = useMemo(() => {
    if (location.pathname.startsWith('/assistants')) return SidebarContextEnum.Assistants;
    else return SidebarContextEnum.Chat;
  }, [location.pathname]);

  return (
    <>
      <SidebarWrapper>
        <ConversationRefsProvider>
          <ConversationsSidebar conversations={filteredConversations} context={context} />
        </ConversationRefsProvider>
      </SidebarWrapper>
      <LayoutContentWrapper>
        <Outlet />
      </LayoutContentWrapper>
    </>
  );
};

export default ConversationsLayout;
