import * as SliderPrimitive from '@radix-ui/react-slider';

interface SliderProps extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  onValueChange?: (value: number[]) => void;
  value?: number[];
  initialValue: number[];
  min: number;
  max: number;
  step: number;
  label?: string;
}

const Slider = ({
  disabled = false,
  onValueChange,
  min,
  max,
  step,
  value,
  initialValue,
  label,
  ...props
}: SliderProps) => {
  return (
    <div {...props}>
      <SliderPrimitive.Root
        disabled={disabled}
        className='relative flex h-5 touch-none items-center'
        defaultValue={initialValue}
        value={value ? value : undefined}
        min={min}
        max={max}
        step={step}
        onValueChange={onValueChange}
      >
        <SliderPrimitive.Track className='relative h-1 w-full grow rounded-full bg-gray-500 dark:bg-gray-800'>
          <SliderPrimitive.Range className='absolute h-full rounded-full bg-accent' />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
          className='flex justify-center items-center size-6 rounded-full bg-accent focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:bg-accent/75'
          aria-label={label}
        >
          <span className='text-xs text-accent-inverse'>{value ?? initialValue}</span>
        </SliderPrimitive.Thumb>
      </SliderPrimitive.Root>
    </div>
  );
};

export default Slider;
