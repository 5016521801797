import tailwindcssContainerQueries from '@tailwindcss/container-queries';
import {Config} from 'tailwindcss';
// eslint-disable-next-line
// @ts-ignore
import tailwindcssAnimationDelay from 'tailwindcss-animation-delay';
import tailwindcssArialAttributes from 'tailwindcss-aria-attributes';
import tailwindcssTypography from '@tailwindcss/typography'

const config = {
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  darkMode: 'class',
  theme: {
    screens: {
      'min': '0px',
      'xs': '400px',
      'sm': '640px',
      'md': '768px',
      'lg': '924px',
      'xl': '1280px',
      '2xl': '1536px',
      '3xl': '2000px',
    },
    extend: {
      fontFamily: {
        sans: ['Manrope'],
      },
      fontSize: {
        '2xs': '10px',
        'header-title-size': '2rem',
      },
      lineHeight: {
        'xxs': '10px',
        'header-title': '2.8rem',
      },
      containers: {
        '2xs': '16rem',
        'screen2xl': '1536px',
      },
      size: {
        'assistant-icon': '72px',
      },
      width: {
        'mirego-woodmark-icon': '44px',
        'sidebar-actionbar-width': '100px',
      },
      maxWidth: {
        'dialog-desktop': '500px',
        'dialog-mobile': '90vw',
        'page-content': '800px',
        'select': '90vw',
      },
      height: {
        'generated-image': '50vh',
        'mirego-woodmark-icon': '8px',
      },
      maxHeight: {
        'generated-image': '50vh',
      },
      strokeWidth: {
        'w-icon': '0.4',
      },
      keyframes: {
        'horizontal-slide': {
          '0%': {transform: 'translateX(-25%)'},
          '100%': {transform: 'translateX(-75%)'},
        },
        'slideDownAndFade': {
          from: {opacity: '0', transform: 'translateY(-2px)'},
          to: {opacity: '1', transform: 'translateY(0)'},
        },
        'slideLeftAndFade': {
          from: {opacity: '0', transform: 'translateX(2px)'},
          to: {opacity: '1', transform: 'translateX(0)'},
        },
        'slideUpAndFade': {
          from: {opacity: '0', transform: 'translateY(2px)'},
          to: {opacity: '1', transform: 'translateY(0)'},
        },
        'slideRightAndFade': {
          from: {opacity: '0', transform: 'translateX(-2px)'},
          to: {opacity: '1', transform: 'translateX(0)'},
        },
        'overlayShow': {
          from: {opacity: '0'},
          to: {opacity: '1'},
        },
        'contentShow': {
          from: {opacity: '0', transform: 'translate(-50%, -48%) scale(0.96)'},
          to: {opacity: '1', transform: 'translate(-50%, -50%) scale(1)'},
        },
        'expandDown': {
          from: {opacity: '0', height: '0px'},
          to: {opacity: '1', height: 'var(--radix-accordion-content-height)'},
        },
        'collapseUp': {
          from: {height: 'var(--radix-accordion-content-height)'},
          to: {height: '0px'},
        },
        'shake': {
          '0%, 100%': {transform: 'translateX(0)'},
          '25%': {transform: 'translateX(-5px)'},
          '50%': {transform: 'translateX(5px)'},
          '75%': {transform: 'translateX(-5px)'},
        },
      },
      boxShadow: {
        textBox: '0 0 10px 1px rgba(0, 0, 0, 0.2)',
      },
      animation: {
        'horizontal-slide': 'horizontal-slide 120s linear 1.5s infinite',
        'slideDownAndFade': 'slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        'slideLeftAndFade': 'slideLeftAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        'slideUpAndFade': 'slideUpAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        'slideRightAndFade': 'slideRightAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        'overlayShow': 'overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',
        'contentShow': 'contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',
        'expandDown': 'expandDown 150ms cubic-bezier(0.87, 0, 0.13, 1)',
        'collapseUp': 'collapseUp 150ms cubic-bezier(0.87, 0, 0.13, 1)',
        'shake': 'shake 0.5s ease-in-out',
      },
      colors: {
        // General
        'info': 'var(--color-info-background)',
        'info-inverse': 'var(--color-info-inverse)',
        'warning': 'var(--color-warning-background)',
        'warning-inverse': 'var(--color-warning-inverse)',
        'danger': 'var(--color-danger-background)',
        'danger-inverse': 'var(--color-danger-inverse)',

        // Override
        'accent': 'var(--color-accent)',
        'accent-inverse': 'var(--color-accent-inverse)',
        // Mirego Theme
        'background': 'var(--color-background)',
        'primary': 'var(--color-primary)',
        'secondary': 'var(--color-secondary)',
        'inverse': 'var(--color-inverse)',
        'stroke-main': 'var(--color-stroke-main)',
        'surface-01': 'var(--color-surface-01)',
        'surface-02': 'var(--color-surface-02)',
        'surface-03': 'var(--color-surface-03)',
      },
      textColor: {
        // General
        'info': 'var(--color-info-inverse)',
        'warning': 'var(--color-warning-inverse)',
        'danger': 'var(--color-danger-inverse)',
        // Override
        'accent': 'var(--color-accent)',
        'accent-inverse': 'var(--color-accent-inverse)',
        // Mirego Theme
        'primary': 'var(--color-primary)',
        'inverse': 'var(--color-inverse)',
      },
      screens: {
        short: {raw: '(min-height: 600px)'},
        tall: {raw: '(min-height: 800px)'},
      }
    },
  },
  plugins: [tailwindcssArialAttributes, tailwindcssAnimationDelay, tailwindcssContainerQueries, tailwindcssTypography],
} satisfies Config;

export default config;
