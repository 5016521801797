import {useTranslation} from 'react-i18next';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {useAuthenticatedQuery} from 'scout-chat/hooks/requests/use-authenticated-query.tsx';
import {chatCompletion, ChatCompletionRequest} from 'scout-chat/requests/chat-completion.ts';

export const useChatCompletionQuery = (request: ChatCompletionRequest, id: string) => {
  const {addToast} = useToasts();
  const {t} = useTranslation();

  const {data, error} = useAuthenticatedQuery({
    queryKey: ['chat-completion-request', id],
    queryFn: () => chatCompletion(request),
    enabled: request.messages.length > 0 && request.model.length > 0,
  });

  if (error) {
    addToast(t('errors.unexpected'), 'error');
  }

  return data?.data ?? [];
};
