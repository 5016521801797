import AppRoutes from '#/AppRoutes.tsx';
import AppProviders from '#/components/AppProviders';

const AppContentWrapper = () => {
  return (
    <>
      <AppProviders>
        <AppRoutes />
      </AppProviders>
    </>
  );
};

export default AppContentWrapper;
