import {useEffect, useRef} from 'react';
import {useSearchParams} from 'react-router-dom';
import {ConversationMessage} from 'scout-chat/types.ts';

const useSendMessageOnQuerySearchParam = (
  sendMessage: (newMessages: ConversationMessage[], title?: string | undefined) => Promise<void>,
  conversationId: string | undefined,
) => {
  const [searchParams] = useSearchParams();
  const messageSent = useRef(false);

  useEffect(() => {
    if (conversationId || messageSent.current || !searchParams) {
      return;
    }
    const queryParam = searchParams.get('q');
    if (!queryParam) {
      return;
    }

    sendMessage([{content: queryParam, role: 'user'}]);
    messageSent.current = true;
  }, [conversationId, searchParams, sendMessage]);
};

export default useSendMessageOnQuerySearchParam;
