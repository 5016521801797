import {FunctionComponent, useMemo} from 'react';
import Markdown from 'scout-chat/components/Markdown.tsx';

const PythonCodeMarkdown: FunctionComponent<{code?: string}> = ({code}) => {
  const pythonCode = useMemo(() => {
    return `\`\`\`python\n${code ?? ''}`;
  }, [code]);

  return <Markdown>{pythonCode}</Markdown>;
};

export default PythonCodeMarkdown;
