import {useAssistantFilesQuery, useUpdateFileInformationMutation} from '#/hooks/query/assistants';
import Label from '#/library/label/Label.tsx';
import {Textarea} from '#/library/textarea/Textarea';
import {Cross1Icon} from '@radix-ui/react-icons';
import {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from 'scout-chat/components/Button.tsx';
import {ReactComponent as CheckmarkIcon} from 'src/resources/checkmark-icon.svg';

type EditFileParams = {
  assistantId: string;
  fileIndex: string;
};

export const EditFile: FunctionComponent = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {assistantId, fileIndex} = useParams<EditFileParams>();
  const assistantFilesQuery = useAssistantFilesQuery({assistantId: assistantId, shouldPoll: false}).data;
  const file = fileIndex && assistantFilesQuery ? assistantFilesQuery[parseInt(fileIndex)] : undefined;
  const [currentfile, setCurrentFile] = useState(file);
  const [name, setName] = useState(file?.filename ?? '');
  const [description, setDescription] = useState(file?.filedescription ?? '');

  const editFileInformation = useUpdateFileInformationMutation();

  if (currentfile !== file && file) {
    setName(file.filename);
    setDescription(file.filedescription);
    setCurrentFile(file);
  }

  const handleEditFileInformation = useCallback(() => {
    editFileInformation.mutate({
      assistantId: assistantId ?? '',
      fileId: currentfile?.id ?? '',
      editedFileInformation: {
        file_description: description ?? 'description',
        file_name: name ?? 'file',
      },
    });
  }, [assistantId, editFileInformation, currentfile, name, description]);

  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className='flex flex-1 bg-black/[0.8] absolute inset-0 justify-center items-center z-20' onClick={handleClose}>
      <div
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
        }}
        className='flex-col space-y-6 min-w-[40%] p-8 bg-surface-01 md:border border-stroke-main md:rounded-2xl text-primary'
      >
        <div>
          <Label htmlFor='file-name'>{t('edit-file.name-label')}</Label>
          <Textarea
            id='file-name'
            value={name}
            placeholder={t('edit-file.name-placeholder')}
            onValueChange={e => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <Label htmlFor='file-description'>{t('edit-file.description-label')}</Label>
          <Textarea
            id='file-description'
            value={description}
            placeholder={t('edit-file.description-placeholder')}
            onValueChange={e => setDescription(e.target.value)}
            disabled={file?.content_type === 'scout/custom function'}
            minRows={5}
          />
        </div>
        <div className='flex flex-row justify-end space-x-4'>
          <Button
            type='button'
            variant='primary'
            size='sm'
            textSize='base'
            isLoading={editFileInformation.isPending}
            disabled={editFileInformation.isPending}
            onClick={handleEditFileInformation}
          >
            <CheckmarkIcon className='stroke-accent-inverse' />
            {t('edit-file.save-button')}
          </Button>
          <Button type='button' variant='secondary' size='sm' textSize='base' onClick={handleClose}>
            <Cross1Icon />
            {t('edit-file.close-button')}
          </Button>
        </div>
      </div>
    </div>
  );
};
