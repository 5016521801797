import {QueryClient, QueryClientContext, QueryClientProvider} from '@tanstack/react-query';
import {FunctionComponent, useContext} from 'react';

export const AppQueryClientProvider: FunctionComponent<Pick<React.HTMLProps<HTMLElement>, 'children'>> = ({
  children,
}) => {
  const existingContext = useContext(QueryClientContext);

  const client = existingContext ?? new QueryClient();

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};
