import {Textarea} from '#/library/textarea/Textarea.tsx';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';

const EditTaskVariablesSection: FunctionComponent<{
  variables: Record<string, string>;
  onVariableValueChange: (variableName: string, newValue: string) => void;
  isOwner: boolean | undefined;
}> = ({variables, onVariableValueChange, isOwner}) => {
  const {t} = useTranslation();

  return (
    <div>
      <span className='block text-primary font-bold mb-2'>{t('create-edit-task.fields.ai-detail.variables')}</span>
      <div className='flex flex-col gap-2 p-4 rounded-xl text-primary border border-stroke-main mb-4'>
        {Object.keys(variables).map((key, index) => (
          <div key={index} className='flex gap-4 items-center'>
            <label className='text-primary font-bold'>{key}</label>
            <Textarea
              id={key}
              value={variables[key]}
              onValueChange={e => onVariableValueChange(key, e.target.value)}
              minRows={1}
              maxRows={1}
              disabled={!isOwner}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditTaskVariablesSection;
