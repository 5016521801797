import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export type UserCustomPromptResponse = {
  prompt: string;
};

export const fetchUserCustomPrompt = (): Promise<AxiosResponse<UserCustomPromptResponse>> => {
  return ScoutAPI.get<UserCustomPromptResponse>('/users/custom-prompt');
};
