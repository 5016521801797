import {UseQueryResult} from '@tanstack/react-query';
import React, {createContext, useContext} from 'react';
import {ConversationResponse} from 'scout-chat/requests/fetch-conversation.ts';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';
import {ChatModel, ConversationMessage, FunctionPermission, OnEditSubmitType} from 'scout-chat/types.ts';

export const APP_NAME = 'Scout';

export interface ScoutChatContextType {
  prompt: string;
  setPrompt: (newPrompt: string) => void;
  onSendMessageSubmit: () => void;
  sendMessage: (newMessages: ConversationMessage[], title?: string | undefined) => Promise<void>;
  handleStreamChatCompletion: (
    newMessages: ConversationMessage[] | null,
    conversation: ConversationMessage[],
    functionPermissions?: FunctionPermission[],
  ) => Promise<void>;
  sendMessageIsPending: boolean;
  messages: ConversationMessage[];
  conversationId?: string;
  conversationQuery?: UseQueryResult<ConversationResponse, Error>;
  loading: boolean;
  chatModels: ChatModel[];
  selectedModelId: string | undefined;
  setSelectedModelId: (selectedModelId: string | undefined) => Promise<void>;
  filesToShow: File[];
  setFiles: (files: File[]) => void;
  error: string;
  assistant: AssistantPublicResponse | undefined;
  assistantQueryIsLoading: boolean;
  mentionAssistantEnabled: boolean;
  mentionedAssistantToShow: AssistantPublicResponse | null;
  handleMentionedAssistantSelect: (assistant: AssistantPublicResponse | null) => void;
  handleRemoveMentionedAssistant: () => void;
  promptInputRef: React.RefObject<HTMLTextAreaElement>;
  handleEditSubmit: OnEditSubmitType;
}

export const ScoutChatContext = createContext<ScoutChatContextType | undefined>(undefined);

export const useScoutChatContext = () => {
  const context = useContext(ScoutChatContext);

  if (context === undefined) {
    throw new Error('useScoutChatContext must be used inside ScoutChatContextProvider');
  }
  return context;
};
