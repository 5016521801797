import {useOktaAuth} from '@okta/okta-react';
import {FunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ThreeDots} from 'react-loader-spinner';
import LegacyButton from 'scout-chat/components/LegacyButton.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {LocalStorageKeys} from 'scout-chat/repositories/environment.ts';

const OktaLoginButton: FunctionComponent = () => {
  const {t} = useTranslation();
  const {config} = useConfig();

  const {oktaAuth} = useOktaAuth();
  const [isPending, setIsPending] = useState(false);

  const handleOktaLoginButtonClicked = () => {
    setIsPending(true);

    const generateSecureNonce = () => {
      const array = new Uint8Array(12);
      window.crypto.getRandomValues(array);
      return Array.from(array, byte => byte.toString(36)).join('');
    };

    const nonce = generateSecureNonce();
    localStorage.setItem(LocalStorageKeys.OKTA_NONCE, nonce);
    oktaAuth.signInWithRedirect({nonce});
  };

  return (
    <LegacyButton
      className='bg-accent hover:opacity-70 transition-opacity text-accent-inverse font-bold py-2 px-4 rounded-md w-full'
      disabled={isPending}
      onClick={handleOktaLoginButtonClicked}
    >
      {isPending ? (
        <ThreeDots
          visible={true}
          height='24'
          width='38'
          color={config.application.colors.accentInverse}
          radius='9'
          ariaLabel='three-dots-loading'
          wrapperStyle={{}}
          wrapperClass='justify-center'
        />
      ) : (
        t('login.button')
      )}
    </LegacyButton>
  );
};

export default OktaLoginButton;
