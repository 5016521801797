import {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Alert from 'scout-chat/components/Alert.tsx';
import {Button} from 'scout-chat/components/Button.tsx';
import FunctionPermissionDetailsView from 'scout-chat/components/function-permissions/FunctionPermissionDetailsView.tsx';
import FunctionPermissionMessageView from 'scout-chat/components/function-permissions/FunctionPermissionMessageView.tsx';
import {ConversationMessage, FunctionPermission} from 'scout-chat/types.ts';
import {StreamToolStatus} from 'scout-chat/utils/streaming-utils.ts';

export type OnRespondFunctionPermissionFn = (
  functionPermissions: FunctionPermission[],
  newConversation: ConversationMessage[],
) => void;

const FunctionPermissionAlert: FunctionComponent<{
  toolCall: StreamToolStatus;
  onRespond: OnRespondFunctionPermissionFn;
  conversation: ConversationMessage[];
}> = ({toolCall, onRespond, conversation}) => {
  const {t} = useTranslation();
  const [buttonClicked, setButtonClicked] = useState<'allowConversation' | 'allowOnce' | 'dontAllow'>();

  const handleRespond = useCallback(
    (functionPermissions: FunctionPermission[]) => {
      // We remove the last message because it is the confirmation component message
      const trimmedConversation = conversation.slice(0, -1);
      onRespond(functionPermissions, trimmedConversation);
    },
    [conversation, onRespond],
  );

  const onAllowConversationClick = useCallback(() => {
    setButtonClicked('allowConversation');
    handleRespond([{function_name: toolCall.tool_name}]);
  }, [handleRespond, toolCall.tool_name]);

  const onAllowOnceClick = useCallback(() => {
    setButtonClicked('allowOnce');
    handleRespond([{call_id: toolCall.call_id}]);
  }, [handleRespond, toolCall.call_id]);

  const handleDontAllow = useCallback(() => {
    setButtonClicked('dontAllow');
    handleRespond([{call_id: toolCall.call_id, denied: true}]);
  }, [handleRespond, toolCall.call_id]);

  return (
    <Alert variant='surface'>
      <div className='flex flex-col gap-4'>
        <FunctionPermissionMessageView toolCall={toolCall} />
        <div>
          <FunctionPermissionDetailsView toolCall={toolCall} />
        </div>
        <div className='flex justify-center gap-4 flex-wrap'>
          <Button
            variant='primary'
            size='sm'
            textSize='base'
            onClick={onAllowConversationClick}
            isLoading={buttonClicked === 'allowConversation'}
          >
            {t('function-permissions.allow-conversation')}
          </Button>
          <Button
            variant='primary'
            size='sm'
            textSize='base'
            onClick={onAllowOnceClick}
            isLoading={buttonClicked === 'allowOnce'}
          >
            {t('function-permissions.allow-once')}
          </Button>
          <Button
            variant='cancel'
            size='sm'
            textSize='base'
            onClick={handleDontAllow}
            isLoading={buttonClicked === 'dontAllow'}
          >
            {t('function-permissions.dont-allow')}
          </Button>
        </div>
      </div>
    </Alert>
  );
};

export default FunctionPermissionAlert;
