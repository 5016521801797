import {FunctionComponent, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'scout-chat/components/Button.tsx';

const EditMessageButtonsView: FunctionComponent<{
  isPending: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}> = ({isPending, onSubmit, onCancel}) => {
  const {t} = useTranslation();

  return (
    <div className='w-full flex justify-center gap-2'>
      <Button variant='primary' textSize='base' size='sm' onClick={onSubmit} isLoading={isPending}>
        {t('actions.submit-edit-message.content')}
      </Button>
      <Button variant='secondary' textSize='base' size='sm' onClick={onCancel}>
        {t('actions.cancel.content')}
      </Button>
    </div>
  );
};

export default memo(EditMessageButtonsView);
