import Page from '#/components/Page';
import Auth0LoginButton from '#/components/login/auth0/Auth0LoginButton.tsx';
import GoogleLoginButton from '#/components/login/google/GoogleLoginButton';
import MicrosoftLoginButton from '#/components/login/microsoft/MicrosoftLoginButton.tsx';
import OktaLoginButton from '#/components/login/okta/OktaLoginButton';
import {useUserAgreement} from '#/hooks/use-user-agreement.tsx';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate} from 'react-router-dom';
import Alert from 'scout-chat/components/Alert.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {checkAuth} from 'scout-chat/requests/check-auth.ts';

const LoginPage = () => {
  const {t} = useTranslation();
  const {config} = useConfig();
  const {
    integrations: {authentication},
  } = config;

  const [isAlreadyAuthenticated, setIsAlreadyAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {userAgreementsAccepted, setUserAgreementsAccepted, userAgreementText} = useUserAgreement();

  const shouldDisableLoginButtons = config.application.userAgreement && !userAgreementsAccepted;

  useEffect(() => {
    const checkAlreadyAuthenticated = async () => {
      try {
        await checkAuth();
        setIsAlreadyAuthenticated(true);
      } catch (error) {
        setIsAlreadyAuthenticated(false);
      }
    };

    checkAlreadyAuthenticated();
  }, []);

  const [animateUseAgreementCheckbox, setAnimateUseAgreementCheckbox] = useState<boolean>(false);
  const handleButtonDivClick = useCallback(() => {
    setAnimateUseAgreementCheckbox(true);
    setTimeout(() => {
      setAnimateUseAgreementCheckbox(false);
    }, 500);
  }, []);

  if (isAlreadyAuthenticated) {
    return <Navigate to='/chat' replace />;
  }

  return (
    <Page title={t('login.page-title')} className='md:rounded-none'>
      <div className='min-h-full flex items-center justify-center bg-background'>
        <div
          className='bg-surface-01 text-primary rounded-md p-8 shadow-lg w-96'
          onClick={shouldDisableLoginButtons ? handleButtonDivClick : undefined}
        >
          <h1 className='text-2xl font-bold mb-4 text-center'>{t('login.title')}</h1>
          <div className={`flex flex-col gap-3 ${shouldDisableLoginButtons ? 'opacity-50 pointer-events-none' : ''}`}>
            {authentication.okta && <OktaLoginButton />}
            {authentication.auth0 && <Auth0LoginButton setErrorMessage={setErrorMessage} />}
            {authentication.google && (
              <GoogleLoginButton clientId={authentication.google.clientId} setErrorMessage={setErrorMessage} />
            )}
            {authentication.microsoft && (
              <MicrosoftLoginButton
                clientId={authentication.microsoft.clientId}
                baseUrl={authentication.microsoft.baseUrl}
                setErrorMessage={setErrorMessage}
              />
            )}
            {errorMessage && (
              <div>
                <Alert variant='warning'>{errorMessage}</Alert>
              </div>
            )}
          </div>
          {config.application.userAgreement && (
            <label
              className={`mt-3 block items-center user-agreement ${animateUseAgreementCheckbox ? 'animate-shake' : ''}`}
              onClick={e => e.stopPropagation()}
            >
              <input
                type='checkbox'
                checked={userAgreementsAccepted}
                onChange={() => {
                  setUserAgreementsAccepted(!userAgreementsAccepted);
                }}
                className='mr-2'
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: userAgreementText,
                }}
              />
            </label>
          )}
        </div>
      </div>
    </Page>
  );
};

export default LoginPage;
