import ConversationListMoreMenu from '#/components/ConversationListMoreMenu';
import ConversationMoreMenu from '#/components/ConversationMoreMenu';
import {useUpdateConversationMutation} from '#/hooks/query/conversations';
import {useConversationRefs} from '#/hooks/use-conversation-refs.tsx';
import {SidebarBoxNavLink} from '#/library/sidebar/SidebarBoxNavLink';
import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations';
import {CheckIcon, Cross1Icon, SymbolIcon} from '@radix-ui/react-icons';
import React, {ComponentProps, FunctionComponent, memo, useCallback, useMemo, useRef, useState} from 'react';
import {Flipped} from 'react-flip-toolkit';
import {useTranslation} from 'react-i18next';
import {useClickAway} from 'react-use';
import AssistantAvatar from 'scout-chat/components/AssistantAvatar.tsx';
import {useAssistants} from 'scout-chat/hooks/contexts/use-assistants.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';

const MAX_CONVERSATION_TITLE_LENGTH = 100;

export const ConversationsList: FunctionComponent<{
  title: string;
  conversations: ConversationSummaryResponse[];
  editingConversationId: string | null;
  setEditingConversationId: (value: ((prevState: string | null) => string | null) | string | null) => void;
  openMenuUuid: string | null;
  setOpenMenuUuid: (value: ((prevState: string | null) => string | null) | string | null) => void;
  activeConversationId: string | null;
}> = memo(
  ({
    title,
    conversations,
    editingConversationId,
    setEditingConversationId,
    openMenuUuid,
    setOpenMenuUuid,
    activeConversationId,
  }) => {
    const {registerRef} = useConversationRefs();
    const [openMenuKey, setOpenMenuKey] = useState('');

    const onOpenChange = (isOpen: boolean) => {
      setOpenMenuKey(isOpen ? title : '');
    };

    return (
      <div className='pb-2 bg-surface-02 rounded-xl text-primary overflow-hidden group/conversation-list'>
        <div className='relative flex justify-between items-center px-4 pt-4 pb-1'>
          <h2 className='text-sm text-secondary'>{title}</h2>

          <ConversationListMoreMenu
            isOpen={openMenuKey === title}
            conversations={conversations}
            onOpenChange={onOpenChange}
          />
        </div>

        {conversations.map(conversation => (
          <div key={conversation.id} ref={el => registerRef(conversation.id, el)}>
            <ConversationsListItem
              conversation={conversation}
              setEditingConversationId={setEditingConversationId}
              editing={editingConversationId === conversation.id}
              menuIsOpen={openMenuUuid === conversation.id}
              setOpenMenuUuid={setOpenMenuUuid}
              isActiveConversation={activeConversationId === conversation.id}
            />
          </div>
        ))}
      </div>
    );
  },
);

type ConversationsListItemWrapperProps = {
  conversation: ConversationSummaryResponse;
  setEditingConversationId: React.Dispatch<React.SetStateAction<string | null>>;
  editing: boolean;
  setOpenMenuUuid: React.Dispatch<React.SetStateAction<string | null>>;
  menuIsOpen: boolean;
  isActiveConversation: boolean;
};
const ConversationsListItem: FunctionComponent<ConversationsListItemWrapperProps> = memo(
  ({conversation, setEditingConversationId, editing, setOpenMenuUuid, menuIsOpen, isActiveConversation}) => {
    const updateConversationMutation = useUpdateConversationMutation();
    const [editedTitle, setEditedTitle] = useState<string>('');

    if (editing) {
      return (
        <ConversationsListItemEdit
          conversation={conversation}
          updateConversationMutation={updateConversationMutation}
          editedTitle={editedTitle}
          setEditedTitle={setEditedTitle}
          setEditingConversationId={setEditingConversationId}
        />
      );
    }

    return (
      <ConversationBoxListItemDisplay
        conversation={conversation}
        updateConversationMutation={updateConversationMutation}
        editedTitle={editedTitle}
        setEditedTitle={setEditedTitle}
        setEditingConversationId={setEditingConversationId}
        menuIsOpen={menuIsOpen}
        setOpenMenuUuid={setOpenMenuUuid}
        isActiveConversationId={isActiveConversation}
      />
    );
  },
);

type ConversationsListItemProps = {
  conversation: ConversationSummaryResponse;
  editedTitle: string;
  setEditedTitle: (value: ((prevState: string) => string) | string) => void;
  setEditingConversationId: React.Dispatch<React.SetStateAction<string | null>>;
};
const ConversationsListItemEdit: FunctionComponent<
  ConversationsListItemProps & {
    updateConversationMutation: ReturnType<typeof useUpdateConversationMutation>;
  }
> = ({conversation, updateConversationMutation, editedTitle, setEditedTitle, setEditingConversationId}) => {
  const {t} = useTranslation();
  const {addToast} = useToasts();

  const handleRename = useCallback(
    (conversation: ConversationSummaryResponse) => {
      if (!editedTitle?.length || editedTitle.length > MAX_CONVERSATION_TITLE_LENGTH) {
        addToast(
          t('sidebar.conversations.toasts.rename-failure-character-limit', {
            max_characters: MAX_CONVERSATION_TITLE_LENGTH,
          }),
          'error',
        );
      } else if (editedTitle !== conversation.title) {
        updateConversationMutation.mutate({
          conversationId: conversation.id,
          editedConversation: {title: editedTitle},
        });
      }

      setEditingConversationId(null);
    },
    [addToast, editedTitle, setEditingConversationId, t, updateConversationMutation],
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditedTitle(event.target.value);
    },
    [setEditedTitle],
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleRename(conversation);
      } else if (e.key === 'Escape') {
        setEditingConversationId(null);
      }
    },
    [conversation, handleRename, setEditingConversationId],
  );

  const onFocus = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const lengthOfInput = event.currentTarget.value.length;
    return event.currentTarget.setSelectionRange(lengthOfInput, lengthOfInput);
  }, []);

  const onCancelRename = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setEditingConversationId(null);
    },
    [setEditingConversationId],
  );

  const onSaveRename = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      handleRename(conversation);
    },
    [conversation, handleRename],
  );

  const divRef = useRef<HTMLDivElement>(null);
  useClickAway(divRef, () => {
    setEditingConversationId(null);
  });

  return (
    <div ref={divRef} className='gap-2 items-center rounded-lg bg-surface-02 cursor-pointer mx-0 mr-1.5 py-2.5'>
      <input
        autoFocus
        type='text'
        className='w-full bg-transparent caret-accent outline-none px-4'
        value={editedTitle}
        minLength={1}
        maxLength={MAX_CONVERSATION_TITLE_LENGTH}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      />
      <div className='w-full gap-x-2 grid grid-cols-2 grid-rows-1 mt-2 px-3'>
        <ConversationBoxListItemEditConfirmButton
          IconComponent={Cross1Icon}
          title={t('common.actions.cancel')}
          onClick={onCancelRename}
        />

        <ConversationBoxListItemEditConfirmButton
          IconComponent={CheckIcon}
          title={t('common.actions.save')}
          onClick={onSaveRename}
        />
      </div>
    </div>
  );
};

const ConversationBoxListItemEditConfirmButton: FunctionComponent<
  ComponentProps<'button'> & {
    IconComponent: typeof CheckIcon;
    title: string;
    isLoading?: boolean;
  }
> = ({onClick, IconComponent, title, isLoading, ...rest}) => (
  <button
    {...rest}
    className='text-sm w-full bg-surface-03 text-primary flex justify-center items-center gap-1 px-2 py-1 rounded-lg hover:opacity-50'
    onClick={onClick}
  >
    <span>{title}</span>
    {isLoading ? (
      <SymbolIcon className='size-4 text-primary animate-spin' />
    ) : (
      <IconComponent className='size-4 text-primary' />
    )}
  </button>
);

type ConversationBoxListItemDisplayProps = ConversationsListItemProps & {
  setOpenMenuUuid: React.Dispatch<React.SetStateAction<string | null>>;
  updateConversationMutation: ReturnType<typeof useUpdateConversationMutation>;
  menuIsOpen: boolean;
  isActiveConversationId: boolean;
};

const ConversationBoxListItemDisplay: FunctionComponent<ConversationBoxListItemDisplayProps> = ({
  conversation,
  updateConversationMutation,
  editedTitle,
  setEditedTitle,
  setEditingConversationId,
  setOpenMenuUuid,
  menuIsOpen,
  isActiveConversationId,
}) => {
  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpenMenuUuid(isOpen ? conversation.id : null);
    },
    [conversation.id, setOpenMenuUuid],
  );

  const {config} = useConfig();

  return (
    <Flipped flipId={conversation.id}>
      <SidebarBoxNavLink
        to={`/chat/${conversation.id}`}
        isActive={isActiveConversationId}
        dropdownOpen={menuIsOpen}
        title={conversation.title}
      >
        {{
          image: config.features.assistants ? (
            <ConversationBoxListItemAssistantAvatar assistantId={conversation.assistant_id} />
          ) : null,
          title: updateConversationMutation.isPending ? editedTitle : conversation.title,
          button: updateConversationMutation.isPending ? (
            <div className='p-1 md:p-0 flex justify-center items-center md:absolute md:top-0 md:right-0 md:h-full pointer-events-none'>
              <div className='hidden md:block h-full w-4 bg-gradient-to-l from-surface-01' />
              <div className='md:pl-3 md:pr-5 md:inline-flex md:items-center md:h-full md:bg-surface-01 group/button'>
                <SymbolIcon className='size-4 text-primary animate-spin' />
              </div>
            </div>
          ) : (
            <ConversationMoreMenu
              uuid={conversation.id}
              isOpen={menuIsOpen}
              onOpenChange={onOpenChange}
              setIsEditing={setEditingConversationId}
              setEditedTitle={setEditedTitle}
              currentTitle={conversation.title}
            />
          ),
        }}
      </SidebarBoxNavLink>
    </Flipped>
  );
};

const ConversationBoxListItemAssistantAvatar: FunctionComponent<{assistantId: string | null}> = ({assistantId}) => {
  const {assistants} = useAssistants();
  const assistant = useMemo(
    () => assistants.find(assistant => assistant.id === assistantId),
    [assistantId, assistants],
  );

  if (!assistant) {
    return null;
  }

  return (
    <div className='flex items-center justify-center'>
      <AssistantAvatar
        src={assistant?.avatar_url}
        containerClassName='size-6'
        className='rounded-full'
        assistantName={assistant?.name}
      />
    </div>
  );
};
