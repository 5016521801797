import {useAppSettings} from '#/hooks/use-app-settings.tsx';
import {Input} from '#/library/Input.tsx';
import Label from '#/library/label/Label.tsx';
import Select, {Option} from '#/library/select/Select.tsx';
import {resources} from '#/locales/i18n';
import {ReactComponent as AssistantsIcon} from '#/resources/assistants-icon.svg';
import {ReactComponent as LanguageIcon} from '#/resources/language-icon.svg';
import {HomeIcon, Pencil1Icon, WidthIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

export type LanguagesKey = 'fr' | 'en';

const PreferencesList: FunctionComponent = () => {
  const {t, i18n} = useTranslation();
  const {
    numberOfAssistantsToShow,
    setNumberOfAssistantsToShow,
    conversationMaxWidth,
    setConversationMaxWidth,
    useCasesViewEnabled,
    setUseCasesViewEnabled,
  } = useAppSettings();

  const handleLanguageChange = useCallback(
    (value: string) => {
      i18n.changeLanguage(value);
    },
    [i18n],
  );

  const languageOptions: Option[] = useMemo(() => {
    return (Object.keys(resources) as LanguagesKey[]).map(langCode => ({
      value: langCode,
      label: t(`settings.preferences.language.${langCode}`),
    }));
  }, [t]);

  return (
    <div>
      <Label>{t('settings.preferences.section-title')}</Label>

      <div className='flex flex-col gap-4'>
        <div className='bg-surface-02 rounded-xl px-6 py-5 flex items-center justify-between gap-4'>
          <div className='flex items-center justify-between gap-3'>
            <LanguageIcon className='size-6' />

            <div className='flex items-center gap-3'>{t('settings.preferences.language.label')}</div>
          </div>

          <Select value={i18n.language} onChange={handleLanguageChange} options={languageOptions} />
        </div>

        <div className='bg-surface-02 rounded-xl px-6 py-5 flex items-center justify-between gap-4'>
          <div className='flex items-center justify-between gap-3'>
            <AssistantsIcon className='stroke-primary size-6' />

            <div className='flex items-center gap-3'>{t('settings.preferences.number-assistants-actionbar.label')}</div>
          </div>

          <Input
            className='w-20'
            type='number'
            value={numberOfAssistantsToShow}
            onChange={e => setNumberOfAssistantsToShow(Number(e.target.value))}
          />
        </div>

        <div className='bg-surface-02 rounded-xl px-6 py-5 flex items-center justify-between gap-4'>
          <div className='flex items-center justify-between gap-3'>
            <WidthIcon className='text-primary size-6' />

            <div className='flex items-center gap-3'>{t('settings.preferences.conversation-max-width.label')}</div>
          </div>

          <Input
            className='w-32'
            value={conversationMaxWidth}
            onChange={e => setConversationMaxWidth(e.target.value)}
          />
        </div>

        <div className='bg-surface-02 rounded-xl px-6 py-5 flex items-center justify-between gap-4'>
          <div className='flex items-center justify-between gap-3'>
            <HomeIcon className='size-6' />

            <div className='flex items-center gap-3'>{t('settings.preferences.use-cases-view-enabled.label')}</div>
          </div>

          <Select
            value={useCasesViewEnabled ? 'true' : 'false'}
            onChange={(value: string) => {
              setUseCasesViewEnabled(value === 'true');
            }}
            options={[
              {value: 'true', label: t('settings.preferences.use-cases-view-enabled.options.true')},
              {value: 'false', label: t('settings.preferences.use-cases-view-enabled.options.false')},
            ]}
          />
        </div>

        <div className='bg-surface-02 rounded-xl px-6 py-5 flex items-center justify-between gap-4'>
          <div className='flex items-center justify-between gap-3'>
            <Pencil1Icon className='size-6' />

            <div className='flex items-center gap-3'>{t('settings.preferences.user-custom-prompt.label')}</div>
          </div>

          <NavLink
            to='/settings/user-custom-prompt'
            className='py-2 px-4 bg-accent rounded-xl font-bold text-sm text-accent-inverse hover:opacity-70 transition-opacity'
          >
            {t('settings.preferences.user-custom-prompt.actions.manage')}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PreferencesList;
