import {useAuthenticatedQuery} from 'scout-chat/hooks/requests/use-authenticated-query.tsx';
import {fetchAssistants} from 'scout-chat/requests/fetch-assistants.ts';

export const useAllAssistantsQuery = ({enabled}: {enabled?: boolean}) =>
  useAuthenticatedQuery({
    queryKey: ['assistants'],
    queryFn: async () => {
      const response = await fetchAssistants();
      return response.data;
    },
    enabled,
  });
