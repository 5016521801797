import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useExternalService} from 'scout-chat//hooks/logic/use-external-service.tsx';
import {ToolUsedItemProps, ToolUsedItemWrapper} from 'scout-chat/components/tools-used/ToolUsedItem.tsx';
import {castJson} from 'scout-chat/utils/json-utils.ts';

interface ExternalServicesInstructionsToolArguments {
  service_name: string;
}

const GetExternalServicesInstructionsToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    return castJson<ExternalServicesInstructionsToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  const {findExternalServiceByName} = useExternalService();
  const externalService = useMemo(() => {
    if (!toolArguments?.service_name) {
      return undefined;
    }

    return findExternalServiceByName(toolArguments?.service_name);
  }, [findExternalServiceByName, toolArguments?.service_name]);

  return (
    <ToolUsedItemWrapper>
      <div className='flex items-center gap-2'>
        <img src={externalService?.icon_url} className='rounded-md size-4 shrink-0' alt='' />
        {externalService && (
          <span>
            {t('tool-used.tools.get-external-services-instructions', {
              serviceName: externalService.display_name,
            })}
          </span>
        )}
      </div>
    </ToolUsedItemWrapper>
  );
};

export default GetExternalServicesInstructionsToolUsedItem;
