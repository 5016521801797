import {UseQueryResult} from '@tanstack/react-query';
import {MutableRefObject, useEffect} from 'react';
import {ConversationResponse} from 'scout-chat/requests/fetch-conversation.ts';
import {ConversationMessage} from 'scout-chat/types.ts';

export const useSetFormStateOnConversation = (
  conversationQuery: UseQueryResult<ConversationResponse, Error>,
  setMessages: (value: ConversationMessage[]) => void,
  setLoading: (value: boolean) => void,
  setError: (value: string) => void,
  abortControllerRef: MutableRefObject<AbortController | undefined>,
) => {
  useEffect(() => {
    if (conversationQuery.isLoading) {
      setMessages([]);
      return;
    }
    abortControllerRef.current?.abort();
    setLoading(false);
    setError('');

    if (conversationQuery.data) {
      setMessages(conversationQuery.data.payload);
    } else {
      setMessages([]);
    }
  }, [abortControllerRef, conversationQuery.data, conversationQuery.isLoading, setError, setLoading, setMessages]);
};
