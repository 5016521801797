import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';
import {ConversationMessage} from 'scout-chat/types.ts';

export type ChatCompletionResponse = {
  messages: ConversationMessage[];
  error?: {
    code: number;
    message: string;
  };
};

export type ChatCompletionRequest = {
  messages: ConversationMessage[];
  model: string;
};

export const chatCompletion = (request: ChatCompletionRequest): Promise<AxiosResponse<ChatCompletionResponse>> => {
  return ScoutAPI.post<ChatCompletionRequest, AxiosResponse<ChatCompletionResponse>>('/chat/completion/', request);
};
