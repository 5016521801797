import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';

const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';

const isLocalhost = window.location.hostname === 'localhost';

const getCookieOptions = (overrides = {}) => {
  const sameSiteValue: 'Lax' | 'Strict' | 'None' = isLocalhost ? 'Lax' : 'Strict';

  return {
    expires: 31,
    secure: !isLocalhost,
    sameSite: sameSiteValue,
    domain: window.location.hostname,
    ...overrides,
  };
};

// TODO Il va sûrement falloir permettre de fetcher les tokens d'une autre manière, (ex.: token passé dans le state React)

export const getAccessToken = () => {
  return Cookies.get(ACCESS_TOKEN_STORAGE_KEY);
};

export const getRefreshToken = () => {
  return Cookies.get(REFRESH_TOKEN_STORAGE_KEY);
};

export const setAccessToken = (token: string) => {
  Cookies.set(ACCESS_TOKEN_STORAGE_KEY, token, getCookieOptions());
};

export const setRefreshToken = (token: string) => {
  Cookies.set(REFRESH_TOKEN_STORAGE_KEY, token, getCookieOptions());
};

export const removeAllTokens = () => {
  Cookies.remove(ACCESS_TOKEN_STORAGE_KEY, getCookieOptions());
  Cookies.remove(REFRESH_TOKEN_STORAGE_KEY, getCookieOptions());
};

interface TokenPayload {
  sub: string;
  first_name: string;
  last_name: string;
  picture_url?: string;
  is_admin?: boolean;
}

export const getTokenPayload = async (): Promise<TokenPayload | null> => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return null;
  }

  const decodedPayload = jwtDecode<TokenPayload>(accessToken);

  return decodedPayload;
};
