import {useTranslation} from 'react-i18next';
import ProtectedImg from 'scout-chat/components/ProtectedImg.tsx';
import {DefaultLoader} from 'scout-chat/components/tool-loaders/DefaultToolLoader.tsx';
import {ToolStatusLoaderProps} from 'scout-chat/components/tool-loaders/ToolStatusLoader.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {castJson} from 'scout-chat/utils/json-utils.ts';

interface GetExternalServicesIntructionsArguments {
  service_name: string;
}

const GetExternalServicesInstructionsToolLoader = (props: ToolStatusLoaderProps) => {
  const {t} = useTranslation();
  const {config} = useConfig();

  const toolArguments = castJson<GetExternalServicesIntructionsArguments>(props.arguments);
  const externalService = config.features.externalServices.find(
    service => service.name === toolArguments?.service_name,
  );
  const serviceName = externalService?.display_name;
  const icon_url = externalService?.icon_url;

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />
      <div className='flex items-center'>
        {!serviceName && t('tools.get-external-services-instructions.searching')}
        {serviceName && t('tools.get-external-services-instructions.reading', {serviceName})}
        {icon_url && (
          <ProtectedImg containerClassName='size-4 aspect-square ml-1' className='rounded-md' src={icon_url} />
        )}
      </div>
    </div>
  );
};

export default GetExternalServicesInstructionsToolLoader;
