import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export interface ConnectedServiceResponse {
  name: string;
}

export interface ExternalServicesResponse {
  services: ConnectedServiceResponse[];
}

export const fetchUserConnectedServices = (): Promise<AxiosResponse<ExternalServicesResponse>> => {
  return ScoutAPI.get<ExternalServicesResponse>('/users/connected-services');
};
