import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export type UserPublicResponse = {
  first_name: string;
  last_name: string;
  email: string;
  picture_url: string | null;
};

export const fetchUsers = async (query: string): Promise<AxiosResponse<UserPublicResponse[]>> => {
  return ScoutAPI.get<UserPublicResponse[]>('/users', {params: {query}});
};
