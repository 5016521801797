import React, {ChangeEvent, FunctionComponent, memo, useCallback} from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const EditMessageTextarea: FunctionComponent<{
  editedMessage: string;
  onChange: (newEditedMessage: string) => void;
}> = ({editedMessage, onChange}) => {
  const onFocus = useCallback((event: React.FocusEvent<HTMLTextAreaElement>) => {
    const lengthOfInput = event.currentTarget.value.length;
    return event.currentTarget.setSelectionRange(lengthOfInput, lengthOfInput);
  }, []);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <TextareaAutosize
      autoFocus
      className='w-full m-0 resize-none border-0 bg-transparent p-0 focus:outline-none'
      value={editedMessage}
      minLength={1}
      onChange={handleChange}
      onFocus={onFocus}
    />
  );
};

export default memo(EditMessageTextarea);
