import {useCallback} from 'react';

export type OnSelectedModelIdChange = (selectedModelId: string | undefined) => void;

const useSetSelectedModelId = (
  setDefaultModelId: (value: React.SetStateAction<string | undefined>) => void,
  onSelectedModelIdChange: OnSelectedModelIdChange | undefined,
) => {
  return useCallback(
    async (selectedModelId: string | undefined) => {
      setDefaultModelId(selectedModelId);
      onSelectedModelIdChange?.(selectedModelId);
    },
    [onSelectedModelIdChange, setDefaultModelId],
  );
};

export default useSetSelectedModelId;
