import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export interface SignedUrlResponse {
  url: string;
}

export const fetchSignedUrl = (url: string): Promise<AxiosResponse<SignedUrlResponse>> => {
  return ScoutAPI.get<null, AxiosResponse<SignedUrlResponse>>(url);
};
