import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api';

export type ImproveAssistantPromptRequest = {
  prompt: string;
};

export type ImprovePromptResponse = {
  prompt: string;
};

export const improveAssistantPrompt = (
  request: ImproveAssistantPromptRequest,
): Promise<AxiosResponse<ImprovePromptResponse>> => {
  return ScoutAPI.post<ImproveAssistantPromptRequest, AxiosResponse<ImprovePromptResponse>>(
    '/utils/improve-prompt',
    request,
  );
};
