import Label from '#/library/label/Label.tsx';
import {CodeIcon} from '@radix-ui/react-icons';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

const APITokens: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <div>
      <Label>{t('settings.security.section-title')}</Label>

      <div className='bg-surface-02 rounded-xl px-6 py-5 flex items-center justify-between'>
        <div className='flex items-center justify-between gap-3'>
          <CodeIcon width={24} height={24} />

          <div className='flex items-center gap-3'>{t('settings.security.api-tokens.title')}</div>
        </div>

        <NavLink
          to='/settings/api-tokens'
          className='py-2 px-4 bg-accent rounded-xl font-bold text-sm text-accent-inverse hover:opacity-70 transition-opacity'
        >
          {t('settings.security.api-tokens.actions.manage')}
        </NavLink>
      </div>
    </div>
  );
};

export default APITokens;
