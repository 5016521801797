import type {DefaultError, QueryClient} from '@tanstack/query-core';
import {QueryKey, UseQueryOptions, UseQueryResult, useQuery} from '@tanstack/react-query';
import {useAuth} from 'scout-chat/hooks/contexts/use-auth.tsx';

export const useAuthenticatedQuery = <TQueryFnData = unknown, TError = DefaultError>(
  baseOptions: UseQueryOptions<TQueryFnData, TError>,
  queryClient?: QueryClient,
): UseQueryResult<TQueryFnData, TError> => {
  const {user} = useAuth();

  const queryKey: QueryKey = [user?.id, ...(baseOptions.queryKey || [])];

  const options: UseQueryOptions<TQueryFnData, TError> = {
    retry: false,
    refetchOnWindowFocus: true,
    ...baseOptions,
    queryKey,
    enabled: !!user && baseOptions.enabled,
  };

  return useQuery<TQueryFnData, TError>(options, queryClient);
};
