import {useTranslation} from 'react-i18next';
import {DefaultLoader} from 'scout-chat/components/tool-loaders/DefaultToolLoader.tsx';
import {ToolStatusLoaderProps} from 'scout-chat/components/tool-loaders/ToolStatusLoader.tsx';
import {castJson} from 'scout-chat/utils/json-utils.ts';

export interface GetFileTextContentToolLoaderProps {
  filename: string;
}

const GetFileTextContentToolLoader = (props: ToolStatusLoaderProps) => {
  const {t} = useTranslation();

  const toolArguments = castJson<GetFileTextContentToolLoaderProps>(props.arguments);

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />

      {toolArguments ? (
        <span>
          {t('tools.get-file-text-content.in-progress-text-with-filename', {
            filename: toolArguments.filename,
          })}
        </span>
      ) : (
        <span>{t('tools.get-file-text-content.in-progress-text')}</span>
      )}
    </div>
  );
};
export default GetFileTextContentToolLoader;
