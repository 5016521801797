import {fetchConfig} from 'scout-chat/requests/fetch-config.ts';
import {WebappConfig} from 'scout-chat/types.ts';

type ConfigResourceType = {read(): WebappConfig | undefined};

let configResource: ConfigResourceType | null = null;

const createConfigResource: () => ConfigResourceType = () => {
  let status = 'pending';
  let result: WebappConfig;
  const suspender = fetchConfig().then(
    response => {
      status = 'success';
      result = response.data;
    },
    error => {
      status = 'error';
      result = error;
    },
  );

  return {
    read() {
      if (status === 'pending') throw suspender;
      if (status === 'error') throw result;
      if (status === 'success') return result;
    },
  };
};

const getConfigResource: () => ConfigResourceType = () => {
  if (!configResource) {
    configResource = createConfigResource();
  }
  return configResource;
};

export default getConfigResource;
