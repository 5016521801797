import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {
  getAccessToken,
  getRefreshToken,
  removeAllTokens,
  setAccessToken,
} from 'scout-chat/repositories/tokens_repository.ts';

const ScoutAPI = axios.create({
  withCredentials: true,
});

const maxRetryAttempts = 10;
const retryDelay = 500;
const baseDelay = 5000;
const delayMultiplier = 1.5;
const statusCodesToRetry = [429];
export const invalidSessionStatusCodes = [401, 422];

const anonymousEndpoints = ['/auth/login/', '/auth/refresh/'];

ScoutAPI.interceptors.request.use(request => {
  if (request.url === '/auth/login/') {
    return request;
  }

  const access_token = getAccessToken();
  if (access_token) {
    request.headers.Authorization = `Bearer ${access_token}`;
  }

  return request;
});

interface AxiosConfigWithRetry extends AxiosRequestConfig {
  retryCount?: number;
}

ScoutAPI.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (!error.config || !error.response || !statusCodesToRetry.includes(error.response.status)) {
      return Promise.reject(error);
    }

    const configWithRetry: AxiosConfigWithRetry = error.config;

    configWithRetry.retryCount = configWithRetry.retryCount || 0;

    if (configWithRetry.retryCount >= maxRetryAttempts) {
      return Promise.reject(error);
    }

    configWithRetry.retryCount += 1;

    const delay = baseDelay + retryDelay * Math.pow(delayMultiplier, configWithRetry.retryCount - 1);
    await new Promise<void>(resolve => setTimeout(() => resolve(), delay));
    return ScoutAPI(configWithRetry);
  },
);

let isRefreshingAccessToken = false;
let refreshSubscribers: ((newAccessToken: string) => void)[] = [];
const onRefreshSuccess = (newAccessToken: string) => {
  refreshSubscribers.forEach(callback => callback(newAccessToken));
  refreshSubscribers = [];
};
const onRefreshFailure = () => {
  refreshSubscribers = [];
};

ScoutAPI.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (
      axios.isAxiosError(error) &&
      error.response !== undefined &&
      invalidSessionStatusCodes.includes(error.response.status) &&
      error.config?.url !== undefined &&
      !anonymousEndpoints.includes(error.config.url)
    ) {
      try {
        if (!isRefreshingAccessToken) {
          isRefreshingAccessToken = true;
          const newAccessToken = await handleRefreshToken();
          isRefreshingAccessToken = false;
          onRefreshSuccess(newAccessToken);
          error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axios(error.config);
        }

        return new Promise(resolve => {
          refreshSubscribers.push((newAccessToken: string) => {
            if (!error.config) {
              // eslint-disable-next-line no-console
              console.log("Failed request's config is undefined, cannot retry request");
              return;
            }
            error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
            resolve(axios(error.config));
          });
        });
      } catch (refreshError) {
        isRefreshingAccessToken = false;
        onRefreshFailure();
        removeAllTokens();
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  },
);

export default ScoutAPI;

export const handleRefreshToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    throw new Error('No refresh token');
  }

  const response = await axios.post(
    `${ScoutAPI.defaults.baseURL}/auth/refresh/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    },
  );

  const {access_token} = response.data;

  setAccessToken(access_token);

  return access_token;
};
