import {Cross1Icon, HamburgerMenuIcon} from '@radix-ui/react-icons';
import {FunctionComponent} from 'react';

export interface ToggleSidebarButtonProps
  extends Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'>,
    Required<Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>> {
  variant: 'open' | 'close';
}

export const ToggleSidebarButton: FunctionComponent<ToggleSidebarButtonProps> = ({
  variant,
  className = '',
  onClick,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`aspect-square h-12 rounded-md bg-surface-02 flex justify-center items-center hover:opacity-70 transition-opacity ${className}`}
      onClick={onClick}
    >
      {variant === 'open' ? (
        <HamburgerMenuIcon className='size-5 stroke-primary' />
      ) : (
        <Cross1Icon className='size-5 stroke-primary' />
      )}
    </button>
  );
};
