import {useLocalStorage} from 'react-use';
import {LocalStorageKeys} from 'scout-chat/repositories/environment.ts';

export const useDefaultModelId = () => {
  const [defaultModelId, setDefaultModelId] = useLocalStorage(LocalStorageKeys.SELECTED_MODEL_ID, '');
  return {
    defaultModelId,
    setDefaultModelId,
  };
};
