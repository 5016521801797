import Auth0Provider from '#/components/login/auth0/Auth0Provider.tsx';
import OktaProvider from '#/components/login/okta/OktaProvider.tsx';
import {AppSettingsProvider} from '#/hooks/use-app-settings.tsx';
import {ConversationsProvider} from '#/hooks/use-conversations.tsx';
import {LoginProvider} from '#/hooks/use-login.tsx';
import {SidebarProvider} from '#/hooks/use-sidebar.tsx';
import React, {ReactNode} from 'react';
import {AppQueryClientProvider} from 'scout-chat/hooks/contexts/AppQueryClientProvider.tsx';
import {AssistantsProvider, NoAssistantsProvider} from 'scout-chat/hooks/contexts/use-assistants.tsx';
import {AuthProvider} from 'scout-chat/hooks/contexts/use-auth.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {ToastsProvider} from 'scout-chat/hooks/contexts/use-toasts.tsx';

// Providers need to be in reverse order of how they will be rendered in the tree
// The first provider is the innermost
const providers: React.FC<{children: ReactNode}>[] = [];

const AppProviders = (props: {children: ReactNode}) => {
  const {config} = useConfig();
  const {
    features,
    integrations: {authentication},
  } = config;

  if (authentication.auth0) {
    providers.push(Auth0Provider);
  }
  if (authentication.okta) {
    providers.push(OktaProvider);
  }

  providers.push(ConversationsProvider);

  if (features.assistants) {
    providers.push(AssistantsProvider);
  } else {
    providers.push(NoAssistantsProvider);
  }

  providers.push(ToastsProvider);
  providers.push(SidebarProvider);
  providers.push(AppQueryClientProvider);
  providers.push(AppSettingsProvider);
  providers.push(LoginProvider);
  providers.push(AuthProvider);

  return providers.reduce((acc, Provider) => <Provider>{acc}</Provider>, props.children);
};

export default AppProviders;
