import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import ScoutSpinner from 'scout-chat/components/ScoutSpinner.tsx';
import {FunctionPermissionDetailsWrapper} from 'scout-chat/components/function-permissions/FunctionPermissionDetailsView.tsx';
import PythonCodeMarkdown from 'scout-chat/components/tools-used/PythonCodeMarkdown.tsx';
import {ToolUsedItemProps} from 'scout-chat/components/tools-used/ToolUsedItem.tsx';
import {useChatCompletionQuery} from 'scout-chat/hooks/requests/use-chat-completion.tsx';
import {useChatModelsQuery} from 'scout-chat/hooks/requests/use-chat-models-query.tsx';
import {ChatCompletionResponse} from 'scout-chat/requests/chat-completion.ts';
import {MessageRole} from 'scout-chat/types.ts';
import {contentPartsToString} from 'scout-chat/utils/conversation-utils.ts';
import {castJson} from 'scout-chat/utils/json-utils.ts';

export interface ExecuteExternalServicesCodeToolArguments {
  code: string;
  service_name: string;
}

const ExecuteExternalServicesCodeFunctionPermissionDetails: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const toolArguments = useMemo(() => {
    return castJson<ExecuteExternalServicesCodeToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  const models = useChatModelsQuery();
  const {t} = useTranslation();

  const chatCompletionRequest = useMemo(
    () => ({
      messages: toolArguments?.code
        ? [
            {
              role: 'system' as MessageRole,
              content: t('function-permissions.functions.execute_external_services_code.chat-completion-explain-code'),
            },
            {
              role: 'user' as MessageRole,
              content: toolArguments.code,
            },
          ]
        : [],
      model: models[0] ? models[0].id : '',
    }),
    [models, t, toolArguments?.code],
  );

  const chatCompletionExplanation = useChatCompletionQuery(chatCompletionRequest, toolArguments?.code ?? '');
  const chatCompletionMessages = (chatCompletionExplanation as ChatCompletionResponse)?.messages;
  const lastMessage = chatCompletionMessages?.at(-1);

  const isLoaded = useMemo(() => {
    return !!chatCompletionMessages;
  }, [chatCompletionMessages]);

  return (
    <div>
      <div className='pb-6 flex'>
        <div className={`justify-center transition-opacity duration-300 ${isLoaded ? 'opacity-100' : 'opacity-0'}`}>
          {isLoaded &&
            lastMessage?.content &&
            (Array.isArray(lastMessage.content) ? contentPartsToString(lastMessage.content) : lastMessage.content)}
        </div>
        {!isLoaded && (
          <>
            <ScoutSpinner />
            <span className='pl-2'>{t('function-permissions.explaining')}</span>{' '}
          </>
        )}
      </div>
      <FunctionPermissionDetailsWrapper>
        <PythonCodeMarkdown code={toolArguments?.code} />
      </FunctionPermissionDetailsWrapper>
    </div>
  );
};

export default ExecuteExternalServicesCodeFunctionPermissionDetails;
