import {useQuery} from '@tanstack/react-query';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {fetchChatModels} from 'scout-chat/requests/fetch-chat-models.ts';

export const useChatModelsQuery = () => {
  const {addToast} = useToasts();
  const {t} = useTranslation();

  const fetchChatModelsQuery = useCallback(async () => {
    try {
      return await fetchChatModels();
    } catch (error) {
      addToast(t('errors.fetch-chat-models'), 'error');
    }
  }, [addToast, t]);

  const {data} = useQuery({queryKey: ['chat-models'], queryFn: fetchChatModelsQuery});

  return data?.data ?? [];
};
