import {FunctionComponent} from 'react';

import ConversationComponent, {ConversationChildren} from 'scout-chat/components/scout-chat/ConversationComponent.tsx';
import {useScoutChatContext} from 'scout-chat/components/scout-chat/scout-chat-providers/ScoutChatContext.tsx';
import 'scout-chat/locales/i18n';

type ScoutChatProps = {
  maxWidth?: string;
  showNewConversationPresentation?: boolean;
  children?: ConversationChildren;
};

const ScoutChat: FunctionComponent<ScoutChatProps> = ({
  maxWidth,
  showNewConversationPresentation = false,
  children,
}) => {
  const {
    prompt,
    setPrompt,
    onSendMessageSubmit,
    conversationId,
    conversationQuery,
    messages,
    loading,
    filesToShow,
    setFiles,
    error,
    assistant,
    mentionAssistantEnabled,
    mentionedAssistantToShow,
    handleMentionedAssistantSelect,
    handleRemoveMentionedAssistant,
    promptInputRef,
    handleEditSubmit,
  } = useScoutChatContext();

  return (
    <ConversationComponent
      conversationId={conversationId}
      messages={messages}
      conversationQueryIsLoading={conversationQuery?.isLoading}
      isLoading={loading}
      message={prompt}
      onMessageChange={setPrompt}
      files={filesToShow}
      onFileChange={setFiles}
      onSendMessageSubmit={onSendMessageSubmit}
      error={error}
      assistant={assistant}
      mentionAssistantEnabled={mentionAssistantEnabled}
      mentionedAssistant={mentionedAssistantToShow}
      onMentionedAssistantSelect={handleMentionedAssistantSelect}
      onRemoveMentionedAssistant={handleRemoveMentionedAssistant}
      promptInputRef={promptInputRef}
      onEditSubmit={handleEditSubmit}
      maxWidth={maxWidth}
      showNewConversationPresentation={showNewConversationPresentation}
    >
      {children}
    </ConversationComponent>
  );
};

export default ScoutChat;
