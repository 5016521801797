import {FunctionComponent, useCallback, useMemo, useRef, useState} from 'react';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import AssistantAvatar from 'scout-chat/components/AssistantAvatar.tsx';
import useCallOnCloseWhenNotOpen from 'scout-chat/components/mention-assistant/use-call-on-close-when-not-open.tsx';
import useCloseOnEscapeKeyPressOrClickAway from 'scout-chat/components/mention-assistant/use-close-on-escape-key-press-or-click-away.tsx';
import useResetSelectedIndexOnOpen from 'scout-chat/components/mention-assistant/use-reset-selected-index-on-open.tsx';
import useSelectAssistantOnEnterKeyPress from 'scout-chat/components/mention-assistant/use-select-assistant-on-enter-key-press.tsx';
import useSetOpenWhenSearchNotNull from 'scout-chat/components/mention-assistant/use-set-open-when-search-not-null.tsx';
import useSetSelectedIndexOnArrowsKeyPress from 'scout-chat/components/mention-assistant/use-set-selected-index-on-arrows-key-press.tsx';
import useSetSelectedIndexWhenOverBounds from 'scout-chat/components/mention-assistant/use-set-selected-index-when-over-bounds.tsx';
import {useAssistants} from 'scout-chat/hooks/contexts/use-assistants.tsx';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';

export const MENTION_ASSISTANT_REGEX = /@(\w*)$/;

interface MentionAssistantProps extends Pick<React.HTMLAttributes<HTMLDivElement>, 'className' | 'style'> {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  message: string;
  onClose?: () => void;
  onAssistantSelect?: (assistant: AssistantPublicResponse) => void;
}

const MentionAssistantSelect: FunctionComponent<MentionAssistantProps> = ({
  isOpen,
  setIsOpen,
  message,
  className,
  style,
  onClose,
  onAssistantSelect,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const search = useMemo(() => {
    const match = message.match(MENTION_ASSISTANT_REGEX);
    return match ? match[1] : null;
  }, [message]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const {assistants} = useAssistants();

  const filteredAssistants = useMemo(() => {
    if (search === null) return [];
    return assistants.filter(assistant => assistant.name.toLowerCase().includes(search.toLowerCase()));
  }, [assistants, search]);

  const handleSelectAssistant = useCallback(
    (index: number) => {
      const assistant = filteredAssistants[index];
      if (!assistant) return;
      setIsOpen(false);
      onAssistantSelect?.(assistant);
    },
    [filteredAssistants, onAssistantSelect, setIsOpen],
  );

  useSetOpenWhenSearchNotNull(search, setIsOpen);

  useSetSelectedIndexWhenOverBounds(selectedIndex, filteredAssistants, setSelectedIndex);

  useCallOnCloseWhenNotOpen(isOpen, onClose);

  useResetSelectedIndexOnOpen(isOpen, setSelectedIndex);

  useCloseOnEscapeKeyPressOrClickAway(setIsOpen, divRef);

  useSetSelectedIndexOnArrowsKeyPress(isOpen, selectedIndex, filteredAssistants, setSelectedIndex);

  useSelectAssistantOnEnterKeyPress(selectedIndex, handleSelectAssistant);

  if (!isOpen || filteredAssistants.length === 0) {
    return null;
  }

  return (
    <div
      className={`bg-surface-02 shadow-lg border border-stroke-main rounded-2xl overflow-hidden ${className || ''}`}
      style={style}
      ref={divRef}
    >
      <div className='flex flex-col max-h-60 overflow-y-auto whitespace-nowrap'>
        {filteredAssistants.map((assistant, index) => (
          <ScrollIntoViewIfNeeded
            active={index === selectedIndex}
            options={{behavior: 'instant', block: 'nearest', inline: 'nearest'}}
            key={assistant.id}
            className='bg-surface-02 data-[isselected=true]:bg-surface-03 relative group'
            data-isselected={index === selectedIndex}
            onClick={() => handleSelectAssistant(index)}
            onMouseMove={() => setSelectedIndex(index)}
          >
            {index !== 0 && (
              <div className={`border-t border-stroke-main ${index === selectedIndex + 1 ? 'mx-0' : 'mx-4'}`} />
            )}
            <div className='flex items-center p-4 peer'>
              <AssistantAvatar
                containerClassName='size-6 aspect-square shrink-0 mr-2'
                className='rounded-full'
                assistantName={assistant.name}
                src={assistant.avatar_url}
              />

              <p className='font-semibold text-sm mr-1'>{assistant.name}</p>
              <p className='opacity-60 text-xs truncate font-light' title={assistant.description}>
                {assistant.description}
              </p>
            </div>
          </ScrollIntoViewIfNeeded>
        ))}
      </div>
    </div>
  );
};

export default MentionAssistantSelect;
