import {MobileHeader} from '#/components/MobileHeader';
import Page from '#/components/Page';
import LatestTasksSection from '#/components/tasks/LatestTasksSection.tsx';
import MyTasksSection from '#/components/tasks/MyTasksSection.tsx';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import {TaskExcerptResponse} from '#/repositories/assistants-api/requests/fetch-tasks';
import {ReactComponent as GearIcon} from '#/resources/gear-icon.svg';
import {ReactComponent as PlusIcon} from '#/resources/plus-icon.svg';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';

export const TaskCard: FunctionComponent<{task: TaskExcerptResponse}> = ({task}) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const handleEditClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/tasks/${task.id}/edit`);
  };

  return (
    <Link
      draggable={false}
      to={`/tasks/${task.id}/runs`}
      className='relative flex flex-col justify-center overflow-hidden text-balance rounded-xl bg-surface-02 p-4 min-w-64 transition-colors group/task-card hover:bg-surface-03'
    >
      <div className='flex items-start gap-4'>
        <p className='grow font-bold leading-5 truncate' title={task.description}>
          {task.description}
        </p>

        <div className='flex items-center flex-none'>
          <span className='text-secondary font-bold text-2xs uppercase px-2 py-1 bg-surface-03 rounded-2xl'>
            {t('tasks.my-tasks.number-of-runs', {count: task.runs_count})}
          </span>

          <div onClick={handleEditClick} className='group/gear-button pl-2' title={t('tasks.actions.edit')}>
            <GearIcon className='md:opacity-0 group-hover/gear-button:rotate-[30deg] group-hover/task-card:opacity-100 transition stroke-primary' />
          </div>
        </div>
      </div>
      <div className='text-secondary text-xs'>
        {task.last_run_date ? (
          <>
            <span className="mr-1 after:content-[':']">{t('tasks.my-tasks.last-used')}</span>
            <ReactTimeAgo date={new Date(task.last_run_date)} locale={i18n.language} />
          </>
        ) : (
          <span>{t('tasks.my-tasks.never-used')}</span>
        )}
      </div>
    </Link>
  );
};

const TasksPage: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <Page title={t('tasks.page-title')}>
      <MobileHeader>
        <h2 className='grow font-bold text-center md:text-left'>{t('tasks.page-title')} </h2>

        <NavLink
          to='/tasks/create'
          className='aspect-square h-12 rounded-md bg-accent text-accent-inverse flex justify-center items-center hover:opacity-70'
        >
          <PlusIcon className='size-7 stroke-accent-inverse' />
        </NavLink>
      </MobileHeader>

      <div className='size-full flex flex-col items-stretch overflow-y-auto py-4 md:py-0 md:pb-6'>
        <PageContentHeader title={t('tasks.page-title')}>
          <NavLink
            to='/tasks/create'
            className='flex items-center bg-accent text-accent-inverse rounded-xl font-bold px-4 py-2 hover:opacity-70 transition-opacity'
          >
            <PlusIcon className='size-7 stroke-accent-inverse mr-2' />

            {t('create-edit-task.actions.create')}
          </NavLink>
        </PageContentHeader>

        <div className='max-w-page-content grow w-full flex flex-col md:mx-auto gap-y-16'>
          <MyTasksSection />
          <LatestTasksSection />
        </div>
      </div>
    </Page>
  );
};

export default TasksPage;
