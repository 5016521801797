import {prependUpdatedConversation, useConversationsSetQueryData} from '#/hooks/query/conversations.tsx';
import {useConversations} from '#/hooks/use-conversations.tsx';
import {castToConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations.ts';
import {useEffect, useRef} from 'react';
import {ConversationResponse, fetchConversation} from 'scout-chat/requests/fetch-conversation.ts';

export const usePrependUpdatedConversationIfNeeded = (
  currentConversation: ConversationResponse | undefined,
  sendMessageIsPending: boolean,
) => {
  const {conversations} = useConversations();
  const setConversationsQueryData = useConversationsSetQueryData();

  const sendMessageWasPending = useRef<boolean>(false);
  const sendMessageFromPendingToNotPending = sendMessageWasPending.current && !sendMessageIsPending;

  useEffect(() => {
    const runAsync = async () => {
      if (
        !sendMessageFromPendingToNotPending ||
        !currentConversation ||
        currentConversation.id === conversations[0]?.id
      ) {
        sendMessageWasPending.current = sendMessageIsPending;
        return;
      }
      sendMessageWasPending.current = sendMessageIsPending;

      const response = await fetchConversation(currentConversation.id);
      const {data: updatedConversation} = response;

      if (!updatedConversation) return;

      setConversationsQueryData(oldData => {
        return prependUpdatedConversation(oldData, castToConversationSummaryResponse(updatedConversation));
      });
    };
    runAsync();
  }, [
    currentConversation,
    conversations,
    setConversationsQueryData,
    sendMessageFromPendingToNotPending,
    sendMessageIsPending,
  ]);
};
