import {deleteConversation} from '#/repositories/assistants-api/requests/delete-conversation.ts';
import {deleteConversations} from '#/repositories/assistants-api/requests/delete-conversations';
import {
  ConversationSummaryResponse,
  fetchConversations,
} from '#/repositories/assistants-api/requests/fetch-conversations.ts';
import {
  UpdateConversationRequest,
  updateConversation,
} from '#/repositories/assistants-api/requests/update-conversation.ts';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuth} from 'scout-chat/hooks/contexts/use-auth.tsx';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {useErrorMessage} from 'scout-chat/hooks/logic/use-error-message.tsx';
import {useAuthenticatedQuery} from 'scout-chat/hooks/requests/use-authenticated-query.tsx';

export const useConversationsQuery = () => {
  return useAuthenticatedQuery({
    queryKey: ['conversations'],
    queryFn: async () => {
      const response = await fetchConversations();
      return response.data;
    },
  });
};
export const useConversationsSetQueryData = () => {
  const {user} = useAuth();
  const queryClient = useQueryClient();

  return useCallback(
    (modifyData: Parameters<typeof queryClient.setQueryData<ReturnType<typeof useConversationsQuery>['data']>>[1]) => {
      queryClient.setQueryData<ReturnType<typeof useConversationsQuery>['data']>(
        [user?.id, 'conversations'],
        modifyData,
      );
    },
    [queryClient, user?.id],
  );
};

export const useUpdateConversationMutation = () => {
  const {addToast} = useToasts();
  const setConversationsQueryData = useConversationsSetQueryData();
  const {errorMessageForError} = useErrorMessage();
  const queryClient = useQueryClient();
  const {user} = useAuth();

  return useMutation({
    mutationKey: ['update-conversation'],
    mutationFn: async (data: {conversationId: string; editedConversation: UpdateConversationRequest}) => {
      return updateConversation(data.conversationId, data.editedConversation);
    },
    onSuccess: ({data: updatedConversation}) => {
      setConversationsQueryData((oldData = []) => {
        return prependUpdatedConversation(oldData, updatedConversation);
      });
      queryClient.invalidateQueries({queryKey: [user?.id, 'conversation', updatedConversation.id]});
    },
    onError: error => {
      addToast(errorMessageForError(error, 'sidebar.conversations.toasts.update-failure'), 'error');
    },
  });
};

export const useDeleteConversationMutation = () => {
  const {t} = useTranslation();
  const {addToast} = useToasts();
  const setConversationsQueryData = useConversationsSetQueryData();

  return useMutation({
    mutationKey: ['delete-conversation'],
    mutationFn: deleteConversation,
    onSuccess: ({data: deletedConversationId}) => {
      setConversationsQueryData((oldData = []) => {
        return oldData.filter(conversation => conversation.id !== deletedConversationId);
      });
    },
    onError: () => {
      addToast(t('errors.delete-conversation'), 'error');
    },
  });
};

export const useDeleteConversationsMutation = () => {
  const {t} = useTranslation();
  const {addToast} = useToasts();
  const setConversationsQueryData = useConversationsSetQueryData();

  return useMutation({
    mutationKey: ['delete-conversations'],
    mutationFn: deleteConversations,
    onSuccess: ({data: deletedConversationIds}) => {
      setConversationsQueryData((oldData = []) => {
        return oldData.filter(conversation => !deletedConversationIds.includes(conversation.id));
      });
    },
    onError: () => {
      addToast(t('errors.delete-conversations'), 'error');
    },
  });
};

export const prependUpdatedConversation = (
  oldData: ConversationSummaryResponse[] = [],
  updatedConversation: ConversationSummaryResponse,
) => {
  const filteredData = oldData.filter(conversation => conversation.id !== updatedConversation.id);

  return [updatedConversation, ...filteredData];
};
