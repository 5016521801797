import {FunctionComponent, HTMLAttributes, ReactNode, useMemo} from 'react';
import DefaultToolUsedItem from 'scout-chat/components/tools-used/DefaultToolUsedItem.tsx';
import ExecuteCapabilityToolUsedItem from 'scout-chat/components/tools-used/ExecuteCapabilityToolUsedItem.tsx';
import ExecuteCodeToolUsedItem from 'scout-chat/components/tools-used/ExecuteCodeToolUsedItem.tsx';
import ExecuteExternalServicesCodeToolUsedItem from 'scout-chat/components/tools-used/ExecuteExternalServicesCodeToolUsedItem.tsx';
import GenerateAudioTranscriptionToolUsedItem from 'scout-chat/components/tools-used/GenerateAudioTranscriptionToolUsedItem.tsx';
import GenerateImageContentToolUsedItem from 'scout-chat/components/tools-used/GenerateImageContentToolUsedItem.tsx';
import GetExternalServicesInstructionsToolUsedItem from 'scout-chat/components/tools-used/GetExternalServicesInstructionsToolUsedItem.tsx';
import GetFileTextContentToolUsedItem from 'scout-chat/components/tools-used/GetFileTextContentToolUsedItem.tsx';
import GetWebpageContentToolUsedItem from 'scout-chat/components/tools-used/GetWebpageContentToolUsedItem.tsx';
import GoogleSearchToolUsedItem from 'scout-chat/components/tools-used/GoogleSearchToolUsedItem.tsx';
import SearchCapabilitiesToolUsedItem from 'scout-chat/components/tools-used/SearchCapabilitiesToolUsedItem.tsx';
import SearchContentToolUsedItem from 'scout-chat/components/tools-used/SearchContentToolUsedItem.tsx';
import {MetadataToolCall} from 'scout-chat/types.ts';
import {twMerge} from 'tailwind-merge';

export interface ToolUsedItemProps {
  toolCall: MetadataToolCall;
}

interface ToolUsedComponent {
  (props: ToolUsedItemProps): ReactNode;
}

const toolComponents: Record<string, ToolUsedComponent> = {
  execute_external_services_code: ExecuteExternalServicesCodeToolUsedItem,
  execute_capability: ExecuteCapabilityToolUsedItem,
  execute_python_code: ExecuteCodeToolUsedItem,
  generate_image: GenerateImageContentToolUsedItem,
  get_external_service_instructions: GetExternalServicesInstructionsToolUsedItem,
  get_file_text_content: GetFileTextContentToolUsedItem,
  get_webpage_content: GetWebpageContentToolUsedItem,
  google_web_search: GoogleSearchToolUsedItem,
  search_capabilities: SearchCapabilitiesToolUsedItem,
  search_files_content: SearchContentToolUsedItem,
  generate_audio_transcription: GenerateAudioTranscriptionToolUsedItem,
};

const ToolUsedItem: FunctionComponent<{toolCall: MetadataToolCall}> = ({toolCall}) => {
  return useMemo(() => {
    const Component = toolComponents[toolCall.tool_name] || DefaultToolUsedItem;
    return <Component toolCall={toolCall} />;
  }, [toolCall]);
};

export const ToolUsedItemWrapper: FunctionComponent<Pick<HTMLAttributes<HTMLDivElement>, 'children' | 'className'>> = ({
  children,
  className,
}) => {
  return <div className={twMerge('bg-surface-02 px-4 py-2 rounded-lg', className)}>{children}</div>;
};

export default ToolUsedItem;
