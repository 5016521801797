import {cloneElement, FunctionComponent, useContext} from 'react';
import {AppQueryClientProvider} from 'scout-chat/hooks/contexts/AppQueryClientProvider.tsx';
import {AssistantsProvider, NoAssistantsProvider} from 'scout-chat/hooks/contexts/use-assistants.tsx';
import {AuthProvider} from 'scout-chat/hooks/contexts/use-auth.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {ThemeContext, ThemeProvider} from 'scout-chat/hooks/contexts/use-theme.tsx';
import {ToastsProvider} from 'scout-chat/hooks/contexts/use-toasts.tsx';

export const withScoutChatProviders = <T extends JSX.IntrinsicAttributes>(Component: FunctionComponent<T>) => {
  return (props: T) => {
    const providerElements = [];

    const {config} = useConfig();

    providerElements.push(<AppQueryClientProvider />);

    const existingThemeContext = useContext(ThemeContext);
    if (!existingThemeContext) {
      providerElements.push(<ThemeProvider />);
    }

    providerElements.push(<ToastsProvider />);
    providerElements.push(<AuthProvider />);
    if (config.features.assistants) {
      providerElements.push(<AssistantsProvider />);
    } else {
      providerElements.push(<NoAssistantsProvider />);
    }

    return providerElements.reduceRight(
      (children, provider) => {
        return cloneElement(provider, {children});
      },
      <Component {...props} />,
    );
  };
};
