import {TaskResponse} from '#/repositories/assistants-api/requests/fetch-tasks';
import {ModelVisibilityRequest} from '#/repositories/assistants-api/requests/visibility.ts';
import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export type CreatTaskRequest = {
  description: string;
  instructions: string;
  visibility: ModelVisibilityRequest;
};

export const createTask = (request: CreatTaskRequest): Promise<AxiosResponse<TaskResponse>> => {
  return ScoutAPI.post<CreatTaskRequest, AxiosResponse<TaskResponse>>('/tasks/', request);
};
