import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import AssistantAvatar from 'scout-chat/components/AssistantAvatar.tsx';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';
import {ReactComponent as CrossIcon} from 'scout-chat/resources/cross.svg';

const MentionedAssistant: FunctionComponent<{
  mentionedAssistant: AssistantPublicResponse;
  onRemove?: () => void;
}> = ({mentionedAssistant, onRemove}) => {
  const {t} = useTranslation();

  return (
    <div className='relative w-full p-4 bg-surface-03 rounded-t-2xl flex items-center'>
      <AssistantAvatar
        containerClassName='aspect-square size-6 mr-2'
        className='rounded-full'
        assistantName={mentionedAssistant.name}
        src={mentionedAssistant.avatar_url}
      />
      <p className='overflow-hidden break-keep truncate'>
        <span className='opacity-60'>{t('mention-assistant.mentioned-pretext')}</span>{' '}
        <span className='font-semibold opacity-80'>{mentionedAssistant.name}</span>
      </p>
      <button
        onClick={onRemove}
        className='absolute pl-2 py-2 right-2 md:right-4 text-sm font-bold hover:opacity-70 transition-opacity'
      >
        <CrossIcon className='size-5 text-primary opacity-70' />
      </button>
    </div>
  );
};

export default MentionedAssistant;
