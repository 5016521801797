import React, {forwardRef} from 'react';
import TextareaAutosize, {TextareaAutosizeProps} from 'react-textarea-autosize';
import {twMerge} from 'tailwind-merge';

interface TextareaProps extends TextareaAutosizeProps {
  id: string;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  minRows?: number;
  onValueChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({id, value, placeholder, disabled = false, minRows, onValueChange, className, ...props}, ref) => {
    return (
      <TextareaAutosize
        className={twMerge(
          'appearance-none block w-full bg-surface-01 border-2 border-stroke-main rounded-xl py-3 px-4 leading-tight focus:outline-none focus:bg-surface-02',
          className,
        )}
        id={id}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onValueChange}
        ref={ref}
        minRows={minRows}
        {...props}
      />
    );
  },
);
