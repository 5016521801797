import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';

export const useValidateFileSize = (maxFileSizeMb?: number) => {
  const {addToast} = useToasts();
  const {t} = useTranslation();
  const {config} = useConfig();

  return useCallback(
    (file: File) => {
      const maxSizeMb = maxFileSizeMb || config.application.maxFileSizeInMb;
      if (maxSizeMb === null || maxSizeMb === undefined) {
        return true;
      }

      if (file.size > maxSizeMb * 1024 * 1024) {
        addToast(t('errors.file-too-large', {maxFileSizeInMb: maxSizeMb}), 'error');
        return false;
      }
      return true;
    },
    [maxFileSizeMb, config.application.maxFileSizeInMb, addToast, t],
  );
};
