import TaskMermaidPreview from '#/components/tasks/create-edit-task/task-debugger/TaskMermaidPreview.tsx';
import {TaskResponse} from '#/repositories/assistants-api/requests/fetch-tasks.ts';
import {UseQueryResult} from '@tanstack/react-query';
import {FunctionComponent} from 'react';

const TaskPreviewPage: FunctionComponent<{taskQuery: UseQueryResult<TaskResponse, Error>}> = ({taskQuery}) => {
  return (
    <div className='bg-surface-03 h-full overflow-scroll'>
      <TaskMermaidPreview taskQuery={taskQuery} />
    </div>
  );
};

export default TaskPreviewPage;
