import React from 'react';
import {ExtraProps} from 'react-markdown';
import CopyToClipboardButton from 'scout-chat/components/CopyToClipboardButton.tsx';
import {twMerge} from 'tailwind-merge';

interface Props {
  children: React.ReactNode;
}

const extractTextContent = (children: React.ReactNode): string => {
  if (typeof children === 'string' || typeof children === 'number') {
    return children.toString();
  } else if (Array.isArray(children)) {
    return children.map(extractTextContent).join('');
  } else if (React.isValidElement(children)) {
    return extractTextContent((children as React.ReactElement<Props>).props.children);
  }

  return '';
};

const GeneratedCodeComponent = ({className, node, ...props}: JSX.IntrinsicElements['pre'] & ExtraProps) => (
  <pre className={twMerge('relative', className)} {...props}>
    <CopyToClipboardButton content={extractTextContent(props.children)} className='absolute top-2 right-4' />
    {props.children}
  </pre>
);

export default GeneratedCodeComponent;
