import {
  useAddFileToAssistantMutation,
  useAssistantFilesQuery,
  useDeleteAssistantFileMutation,
} from '#/hooks/query/assistants.tsx';
import Label from '#/library/label/Label.tsx';
import {AssistantFileResponse} from '#/repositories/assistants-api/requests/assistant-files.ts';
import {FunctionComponent, memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import Alert from 'scout-chat/components/Alert.tsx';
import FilePicker from 'scout-chat/components/FilePicker.tsx';
import Files from 'scout-chat/components/Files.tsx';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {useValidateFileSize} from 'scout-chat/hooks/logic/use-validate-file-size.tsx';
import {KnowledgeFile} from 'scout-chat/types.ts';

const AssistantFilesSection: FunctionComponent<{assistantId?: string}> = ({assistantId}) => {
  const {addToast} = useToasts();
  const {t} = useTranslation();
  const [shouldPoll, setShouldPoll] = useState(false);

  const assistantFilesQuery = useAssistantFilesQuery({assistantId, shouldPoll});
  const knowledgeFiles: KnowledgeFile[] = useMemo(() => {
    return (
      assistantFilesQuery.data?.map((file: AssistantFileResponse) => ({
        id: file.id,
        name: file.filename,
        description: file.filedescription,
        status: file.status,
        contentType: file.content_type,
      })) || []
    );
  }, [assistantFilesQuery.data]);

  useEffect(() => {
    if (!assistantFilesQuery.data) return;

    setShouldPoll(assistantFilesQuery.data.some(file => file.status === 'PROCESSING' || file.status === 'IN_QUEUE'));
  }, [assistantFilesQuery.data]);

  const addFileToAssistantMutation = useAddFileToAssistantMutation();

  const validateFileSize = useValidateFileSize();

  const handleAddFiles = useCallback(
    (files: File[]) => {
      files.forEach(file => {
        if (!validateFileSize(file)) {
          return;
        }
        addFileToAssistantMutation.mutate({
          assistantId: assistantId || '',
          file,
        });
      });
    },
    [addFileToAssistantMutation, assistantId, validateFileSize],
  );

  const navigate = useNavigate();
  const location = useLocation();
  const onEditFile = useCallback(
    (index: number) => {
      navigate(`/assistants/${assistantId}/edit/${index}`, {state: {backgroundLocation: location}});
    },
    [assistantId, location, navigate],
  );

  const deleteAssistantFileMutation = useDeleteAssistantFileMutation();
  const handleRemoveFile = useCallback(
    (index: number) => {
      knowledgeFiles.splice(index, 1);

      if (!assistantFilesQuery.data) {
        addToast(t('create-edit-assistant.fields.knowledge.errors.no-files'), 'error');
        return;
      }

      deleteAssistantFileMutation.mutate({
        assistantUuid: assistantId || '',
        fileUuid: assistantFilesQuery.data[index].id,
      });
    },
    [assistantFilesQuery.data, deleteAssistantFileMutation, assistantId, addToast, t, knowledgeFiles],
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleAddFiles(acceptedFiles);
    },
    [handleAddFiles],
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: true});

  return (
    <div
      className='relative rounded-xl transition-all outline-none data-[isdragactive=true]:bg-surface-03 data-[isdragactive=true]:outline data-[isdragactive=true]:outline-4 data-[isdragactive=true]:outline-accent'
      data-isdragactive={isDragActive}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Label>{t('create-edit-assistant.fields.knowledge.label')}</Label>
      {!assistantId ? (
        <Alert variant='info'>{t('create-edit-assistant.fields.knowledge.disclaimer')}</Alert>
      ) : (
        <>
          <div>
            <FilePicker
              isLoading={addFileToAssistantMutation.isPending}
              disabled={addFileToAssistantMutation.isPending}
              variant='text'
              textSize='sm'
              onFilesAdd={handleAddFiles}
            />
          </div>

          <Files
            files={knowledgeFiles}
            onEditFile={onEditFile}
            onRemoveFile={handleRemoveFile}
            className='relative max-h-[240px] py-2 my-2 overflow-auto'
            filesClassName='bg-surface-02'
          />
        </>
      )}
    </div>
  );
};

export default memo(AssistantFilesSection);
