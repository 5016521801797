import {RefObject} from 'react';
import {useClickAway, useKeyPressEvent} from 'react-use';

const useCloseOnEscapeKeyPressOrClickAway = (
  setIsOpen: (isOpen: boolean) => void,
  divRef: RefObject<HTMLDivElement>,
) => {
  useKeyPressEvent('Escape', () => {
    setIsOpen(false);
  });

  useClickAway(divRef, () => {
    setIsOpen(false);
  });
};

export default useCloseOnEscapeKeyPressOrClickAway;
