import {useCallback, useState} from 'react';
import {ConversationMessage, OnEditSubmitType} from 'scout-chat/types.ts';

const useEditMessage = (message: ConversationMessage, messageIndex: number, onEditSubmit: OnEditSubmitType) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState('');

  const handleEditClick = useCallback(() => {
    if (typeof message.content !== 'string') {
      return;
    }
    setIsEditing(true);
    setEditedMessage(message.content);
  }, [message.content]);

  const onEditMessageChange = useCallback((newEditedMessage: string) => {
    setEditedMessage(newEditedMessage);
  }, []);

  const [editIsPending, setEditIsPending] = useState(false);
  const handleEditSubmit = useCallback(async () => {
    setEditIsPending(true);
    await onEditSubmit(messageIndex, editedMessage);
    setEditIsPending(false);
    setIsEditing(false);
  }, [editedMessage, messageIndex, onEditSubmit]);

  const handleEditCancel = useCallback(() => {
    setIsEditing(false);
  }, []);

  return {
    isEditing,
    editedMessage,
    handleEditClick,
    onEditMessageChange,
    handleEditSubmit,
    handleEditCancel,
    editIsPending: editIsPending,
  };
};

export default useEditMessage;
