import {AvailableFunction} from '#/repositories/assistants-api/requests/fetch-available-functions';
import {ModelVisibilityResponse} from '#/repositories/assistants-api/requests/visibility';
import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export type ContentRetrievingStrategy = {
  min_score?: number;
  max_result_count?: number;
  keyword_search_count?: number;
  filter_results?: boolean;
};

export type AssistantResponse = {
  id: string;
  name: string;
  description: string;
  instructions: string;
  prompt_starters: string[];
  visibility: ModelVisibilityResponse;
  avatar_url?: string;
  use_system_prompt: boolean;
  allowed_functions?: AvailableFunction[];
  is_owner: boolean;
  allowed_external_services?: string[];
  content_retrieving_strategy: ContentRetrievingStrategy;
};

export const fetchAssistant = (uuid: string): Promise<AxiosResponse<AssistantResponse>> => {
  return ScoutAPI.get<null, AxiosResponse<AssistantResponse>>(`/assistants/${uuid}`);
};
