import {TaskCard} from '#/components/tasks/TasksPage';
import {useTasksQuery} from '#/hooks/query/tasks';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';

const MyTasksSection: FunctionComponent = () => {
  const {t} = useTranslation();
  const {data: tasks} = useTasksQuery();

  const ownTasks = tasks ?? [];

  return (
    <div className='flex flex-col gap-y-4'>
      <h3 className='font-bold px-4'>{t('tasks.my-tasks.title')}</h3>

      <div className='flex flex-col gap-3 px-4'>
        {ownTasks.map(task => (
          <TaskCard key={task.id} task={task} />
        ))}
      </div>
    </div>
  );
};

export default MyTasksSection;
