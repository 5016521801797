import ScoutAPI from 'scout-chat/repositories/scout-api.ts';
import {ConversationMessage, FunctionPermission} from 'scout-chat/types.ts';
import {fetchWithTokenRefresh} from 'scout-chat/utils/request-utils.ts';
import {StreamChatCompletionOnMessageEvent, handleChatStreamResponse} from 'scout-chat/utils/streaming-utils.ts';

export type StreamConversationCompletionRequest = {
  new_messages: ConversationMessage[] | null;
  model: string;
  mentioned_assistant_id?: string;
  function_permissions?: FunctionPermission[];
};

export const streamConversationCompletion = async (
  conversationId: string,
  request: StreamConversationCompletionRequest,
  onMessage: StreamChatCompletionOnMessageEvent,
  files: File[],
  onError: (error: string) => void,
  onStreamEnd: () => void,
  signal: AbortSignal,
) => {
  const formData = new FormData();
  files?.length && files.forEach(file => formData.append(file.name, file));
  formData.append('chat_request', JSON.stringify(request));

  try {
    const response = await fetchWithTokenRefresh(
      `${ScoutAPI.defaults.baseURL}/conversations/${conversationId}/completion/`,
      {
        method: 'POST',
        body: formData,
        signal,
      },
    );
    return handleChatStreamResponse(response, onMessage, onError, onStreamEnd);
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    onError(errorMessage);
  }
};
