import {UseQueryResult} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {useErrorMessage} from 'scout-chat/hooks/logic/use-error-message.tsx';
import {ConversationResponse} from 'scout-chat/requests/fetch-conversation.ts';

export const useRedirectOnInvalidConversation = (conversationQuery?: UseQueryResult<ConversationResponse, Error>) => {
  const navigate = useNavigate();
  const {addToast} = useToasts();
  const {t} = useTranslation();
  const {errorMessageForError} = useErrorMessage();

  useEffect(() => {
    if (!!conversationQuery?.error) {
      navigate('/chat', {replace: true});

      addToast(errorMessageForError(conversationQuery.error), 'error');
      return;
    }
  }, [addToast, conversationQuery?.error, errorMessageForError, navigate, t]);
};
