import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {removeAllTokens} from 'scout-chat/repositories/tokens_repository.ts';

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    removeAllTokens();
    navigate('/login');
  }, [navigate]);

  return <div />;
};

export default LogoutPage;
