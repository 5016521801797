import {MobileHeader} from '#/components/MobileHeader.tsx';
import Page from '#/components/Page.tsx';
import {useApiTokensQuery, useCreateApiTokenMutation, useRevokeApiTokenMutation} from '#/hooks/query/auth.tsx';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import {ApiTokensTokenResponse} from '#/repositories/assistants-api/requests/fetch-api-tokens.ts';
import {LockClosedIcon, Pencil1Icon} from '@radix-ui/react-icons';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button} from 'scout-chat/components/Button.tsx';

const APITokensPage: FunctionComponent = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const apiTokensQuery = useApiTokensQuery();
  const createApiTokenMutation = useCreateApiTokenMutation();

  const location = useLocation();
  const handleGenerateToken = useCallback(async () => {
    const response = await createApiTokenMutation.mutateAsync();
    navigate(`/settings/api-tokens/${response.data.id}/edit`, {state: {backgroundLocation: location}});
  }, [createApiTokenMutation, location, navigate]);

  return (
    <Page title={t('settings.page-header.title')}>
      <MobileHeader>
        <h2 className='grow font-bold text-center md:text-left'>{t('settings.security.api-tokens.title')}</h2>
      </MobileHeader>

      <div className='size-full flex flex-col items-stretch overflow-y-auto'>
        <PageContentHeader title={t('settings.security.api-tokens.title')} />

        <div className='max-w-page-content grow w-full flex flex-col mx-auto gap-y-8 p-4 md:pt-0 '>
          <p>{t('settings.security.api-tokens.description')}</p>

          <Button
            variant='primary'
            size='md'
            textSize='sm'
            isLoading={createApiTokenMutation.isPending}
            onClick={handleGenerateToken}
            className='self-start px-4 py-2 bg-accent rounded-xl font-bold'
          >
            <LockClosedIcon width={18} height={18} />
            {t('settings.security.api-tokens.actions.create')}
          </Button>

          {apiTokensQuery.data?.data?.tokens?.map(token => <ApiTokenItem key={token.id} token={token} />)}
        </div>
      </div>
    </Page>
  );
};

const ApiTokenItem: FunctionComponent<{token: ApiTokensTokenResponse}> = ({token}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const revokeApiTokenMutation = useRevokeApiTokenMutation();
  const handleRevokeClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      revokeApiTokenMutation.mutate(token.id);
    },
    [revokeApiTokenMutation, token.id],
  );

  const handleEditClick = useCallback(() => {
    navigate(`/settings/api-tokens/${token.id}/edit`, {state: {backgroundLocation: location}});
  }, [location, navigate, token.id]);

  const formattedCreatedAt = useMemo(() => {
    const date = new Date(token.created_at);
    return date.toLocaleString();
  }, [token.created_at]);

  return (
    <div className='bg-surface-02 rounded-xl px-6 py-5 flex items-center justify-between'>
      <div className='flex items-center gap-3 w-[70%] '>
        <span>{token.description}</span>
        <span>{formattedCreatedAt}</span>
      </div>
      <div className='flex gap-2'>
        <Button variant='primary' className='rounded-xl' textSize='base' size='sm' onClick={handleEditClick}>
          <Pencil1Icon className='text-accent-inverse' />
        </Button>
        <Button
          variant='cancel'
          className='rounded-xl'
          textSize='base'
          size='sm'
          isLoading={revokeApiTokenMutation.isPending}
          onClick={handleRevokeClick}
        >
          {t('settings.security.api-tokens.actions.revoke')}
        </Button>
      </div>
    </div>
  );
};

export default APITokensPage;
