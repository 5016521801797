import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export interface ApiTokensTokenResponse {
  id: string;
  description: string;
  created_at: string;
  value: string | null;
}

export interface ApiTokensResponse {
  tokens: ApiTokensTokenResponse[];
}

export const fetchApiTokens = async () => {
  try {
    return await ScoutAPI.get<null, AxiosResponse<ApiTokensResponse>>('/auth/api-tokens/');
  } catch (error) {
    throw error;
  }
};
