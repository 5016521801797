import {useCallback} from 'react';
import useDownloadBlob from 'scout-chat/hooks/requests/use-download-blob.tsx';
import {fetchConversation} from 'scout-chat/requests/fetch-conversation.ts';

const useExportRawConversation = (uuid: string) => {
  const downloadBlob = useDownloadBlob();

  return useCallback(async () => {
    const response = await fetchConversation(uuid);

    const jsonString = JSON.stringify(response.data, null, 2);

    const blob = new Blob([jsonString], {type: 'application/json'});
    downloadBlob(blob, `Scout - ${response.data.title}.json`);
  }, [downloadBlob, uuid]);
};

export default useExportRawConversation;
