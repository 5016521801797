import React, {forwardRef} from 'react';
import {ThreeDots} from 'react-loader-spinner';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'primary' | 'secondary' | 'cancel';
  size: 'sm' | 'md' | 'lg';
  textSize: 'sm' | 'base';
  disabled?: boolean;
  isLoading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({children, className = '', variant, size, textSize, disabled = false, isLoading = false, ...props}, ref) => {
    return (
      <button
        {...props}
        ref={ref}
        disabled={disabled}
        className={`flex items-center gap-2 relative bg-accent text-accent-inverse data-[variant='secondary']:bg-surface-03 data-[variant='cancel']:bg-danger data-[variant='secondary']:text-primary font-bold data-[size='sm']:px-4 data-[size='sm']:py-2 text-[13px] data-[size='md']:px-6 data-[size='md']:py-3 rounded-xl overflow-hidden disabled:opacity-50 disabled:cursor-not-allowed hover:opacity-70 transition ${className}`}
        data-size={size}
        data-textsize={textSize}
        data-variant={variant}
      >
        {children}
        {isLoading && (
          <ThreeDots
            visible={true}
            height='24'
            width='38'
            color={variant === 'secondary' ? 'var(--color-accent)' : 'var(--color-accent-inverse)'}
            radius='9'
            ariaLabel='three-dots-loading'
            wrapperClass='absolute top-0 left-0 size-full bg-inherit flex justify-center items-center bg-accent'
          />
        )}
      </button>
    );
  },
);
