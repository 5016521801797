import {AssistantResponse, ContentRetrievingStrategy} from '#/repositories/assistants-api/requests/fetch-assistant.ts';
import {ModelVisibilityRequest} from '#/repositories/assistants-api/requests/visibility.ts';
import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export type CreateAssistantRequest = {
  name: string;
  description: string;
  instructions: string;
  prompt_starters: string[];
  visibility: ModelVisibilityRequest;
  avatar_url?: string;
  use_system_prompt: boolean;
  allowed_functions?: string[];
  allowed_external_services?: string[];
  content_retrieving_strategy: ContentRetrievingStrategy;
};

export const createAssistant = (request: CreateAssistantRequest): Promise<AxiosResponse<AssistantResponse>> => {
  return ScoutAPI.post<CreateAssistantRequest, AxiosResponse<AssistantResponse>>('/assistants/', request);
};
