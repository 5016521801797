import {useEffect} from 'react';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';

const useSetSelectedIndexWhenOverBounds = (
  selectedIndex: number,
  filteredAssistants: AssistantPublicResponse[],
  setSelectedIndex: (value: number) => void,
) => {
  useEffect(() => {
    if (selectedIndex >= filteredAssistants.length) {
      setSelectedIndex(filteredAssistants.length - 1);
    }
  }, [filteredAssistants.length, selectedIndex, setSelectedIndex]);
};

export default useSetSelectedIndexWhenOverBounds;
