import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {getTokenPayload} from 'scout-chat/repositories/tokens_repository.ts';

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  picture_url?: string;
  isAdmin?: boolean;
}

interface AuthContextType {
  user: User | null;
  refreshUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export const AuthProvider: React.FC<Pick<React.HTMLProps<HTMLElement>, 'children'>> = ({children}) => {
  const [user, setUser] = useState<User | null>(null);

  const refreshUser = useCallback(async () => {
    const payload = await getTokenPayload();
    if (!payload) {
      throw new Error('Invalid token');
    }

    const user: User = {
      id: payload.sub,
      firstName: payload.first_name,
      lastName: payload.last_name,
      picture_url: payload.picture_url,
      isAdmin: payload.is_admin,
    };

    setUser(user);
  }, []);

  const existingContext = useContext(AuthContext);

  useEffect(() => {
    if (existingContext) {
      return;
    }
    try {
      refreshUser();
    } catch {}
  }, [existingContext, refreshUser]);

  const value = existingContext ?? {user, refreshUser};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
