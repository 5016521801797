import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocalStorage} from 'react-use';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {LocalStorageKeys} from 'scout-chat/repositories/environment.ts';
import {DEFAULT_LANGUAGE} from 'scout-chat/types.ts';

export const useUserAgreement = () => {
  const {i18n} = useTranslation();
  const [userAgreementsAccepted, setUserAgreementsAccepted] = useLocalStorage(
    LocalStorageKeys.USER_AGREEMENTS_ACCEPTED,
    false,
  );
  const {config} = useConfig();

  const currentLanguage = useMemo(() => {
    if (config.application.userAgreement?.text.hasOwnProperty(i18n.language)) return i18n.language;
    else if (config.application.userAgreement?.text.hasOwnProperty(DEFAULT_LANGUAGE)) return DEFAULT_LANGUAGE;
    else return null;
  }, [i18n.language, config.application.userAgreement]);

  const userAgreementText: string = useMemo(() => {
    if (!currentLanguage) {
      return '';
    }

    let result = config.application.userAgreement?.text[currentLanguage] || '';
    const links = config.application.userAgreement?.links;

    for (const key in links) {
      if (!links.hasOwnProperty(key)) {
        continue;
      }

      const link = links[key];
      if (!link.hasOwnProperty(currentLanguage)) {
        continue;
      }
      const localedLink = link[currentLanguage];

      const placeholder = `{${key}}`;
      const replacement = `<a target="_blank" rel="noreferrer" href="${localedLink.url}">${localedLink.title}</a>`;
      result = result.replace(new RegExp(placeholder, 'g'), replacement);
    }

    return result;
  }, [config.application.userAgreement?.links, config.application.userAgreement?.text, currentLanguage]);

  return {
    userAgreementsAccepted,
    setUserAgreementsAccepted,
    userAgreementText,
  };
};
