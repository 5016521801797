import useOktaLogin from '#/components/login/okta/use-okta-login';
import {useLogin} from '#/hooks/use-login.tsx';
import {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {LocalStorageKeys} from 'scout-chat/repositories/environment.ts';

const OktaLoginPage = () => {
  const {tokens, logout} = useOktaLogin();
  const navigate = useNavigate();
  const didLogin = useRef(false);
  const {login} = useLogin();

  useEffect(() => {
    if (!tokens || didLogin.current) return;
    const handleOktaLogin = async () => {
      didLogin.current = true;
      localStorage.removeItem(LocalStorageKeys.OKTA_NONCE);
      try {
        await login({
          oauth_token: tokens.idToken,
          nonce: tokens.nonce,
          provider: 'okta',
        });
      } catch (error) {
        navigate('/login');
        return;
      }
      // We want to sign out of Okta after we have successfully logged in to invalidate the tokens.
      await logout();
    };
    handleOktaLogin();
  }, [navigate, login, logout, tokens]);

  return <div />;
};

export default OktaLoginPage;
