import {invalidSessionStatusCodes} from 'scout-chat/repositories/scout-api.ts';
import {getAccessToken} from 'scout-chat/repositories/tokens_repository.ts';
import {checkAuth} from 'scout-chat/requests/check-auth.ts';

const updateAccessToken = (options: RequestInit) => {
  const access_token = getAccessToken();

  return {
    ...options.headers,
    Authorization: `Bearer ${access_token}`,
  };
};

export const fetchWithTokenRefresh = async (url: string, options: RequestInit) => {
  options.headers = updateAccessToken(options);

  let response = await fetch(url, {...options});

  if (invalidSessionStatusCodes.includes(response.status)) {
    try {
      await checkAuth();
      options.headers = updateAccessToken(options);
      response = await fetch(url, {...options});
    } catch (refreshError) {
      throw refreshError;
    }
  }

  if (!response.ok) {
    const errorBody = await response.json();
    throw new Error(errorBody.error);
  }

  return response;
};
