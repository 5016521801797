import {useCallback} from 'react';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';

export const useExternalService = () => {
  const {config} = useConfig();

  const findExternalServiceByName = useCallback(
    (serviceName: string) => {
      return config.features.externalServices.find(service => service.name === serviceName);
    },
    [config.features.externalServices],
  );

  return {findExternalServiceByName};
};
