import {useApiTokenQuery, useUpdateApiTokenMutation} from '#/hooks/query/auth.tsx';
import {Textarea} from '#/library/textarea/Textarea.tsx';
import {CheckIcon, CopyIcon, Cross1Icon} from '@radix-ui/react-icons';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from 'scout-chat/components/Button.tsx';
import {ReactComponent as CheckmarkIcon} from 'src/resources/checkmark-icon.svg';

type EditApiTokenParams = {
  tokenId: string;
};
const EditApiTokenPage: FunctionComponent = () => {
  const {t} = useTranslation();
  const {tokenId} = useParams<EditApiTokenParams>();
  const token = useApiTokenQuery(tokenId);
  const [description, setDescription] = useState('');
  useEffect(() => {
    setDescription(token?.data?.description || '');
  }, [token?.data]);

  const [copied, setCopied] = useState(false);
  const handleCopyToken = useCallback(() => {
    navigator.clipboard.writeText(token?.data?.value || '').then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  }, [token?.data?.value]);

  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const updateApiTokenMutation = useUpdateApiTokenMutation(tokenId || '');
  const handleSaveClick = useCallback(async () => {
    await updateApiTokenMutation.mutateAsync({
      description,
    });
    navigate(-1);
  }, [updateApiTokenMutation, description, navigate]);

  return (
    <div className='flex flex-1 bg-black/[0.8] absolute inset-0 justify-center items-center z-20' onClick={handleClose}>
      <div
        className='max-h-full overflow-scroll flex-col space-y-6 w-2/5 p-8 bg-surface-01 md:border border-stroke-main md:rounded-2xl text-primary'
        onClick={e => e.stopPropagation()}
      >
        <div>
          <Textarea
            id='token-description'
            value={description}
            placeholder={t('edit-file.name-placeholder')}
            required
            autoFocus
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        <div>
          {token && <pre className='p-4 border rounded-xl whitespace-pre-wrap break-words'>{token.data?.value}</pre>}
          <Button
            variant='secondary'
            size='md'
            textSize='sm'
            onClick={handleCopyToken}
            className='mt-2'
            disabled={copied}
          >
            {copied ? <CheckIcon width={18} height={18} /> : <CopyIcon width={18} height={18} />}

            {copied ? t('settings.security.api-tokens.token-copied') : t('settings.security.api-tokens.actions.copy')}
          </Button>
        </div>
        <div className='flex flex-row justify-end space-x-4'>
          <Button
            type='button'
            variant='primary'
            size='sm'
            textSize='base'
            isLoading={updateApiTokenMutation.isPending}
            onClick={handleSaveClick}
          >
            <CheckmarkIcon className='stroke-accent-inverse' />
            {t('edit-file.save-button')}
          </Button>
          <Button type='button' variant='secondary' size='sm' textSize='base' onClick={handleClose}>
            <Cross1Icon />
            {t('edit-file.close-button')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditApiTokenPage;
