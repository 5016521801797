import {FunctionComponent, useEffect} from 'react';
import {useScrollToBottom} from 'react-scroll-to-bottom';
import {ConversationMessage} from 'scout-chat/types.ts';

const ScrollToBottomOnNewUserMessage: FunctionComponent<{
  messages: ConversationMessage[];
  isLoading: boolean;
}> = ({messages, isLoading}) => {
  const scrollToBottom = useScrollToBottom();

  useEffect(() => {
    if (!isLoading || !messages.length || messages.at(-1)?.role !== 'user') {
      return;
    }
    scrollToBottom({behavior: 'smooth'});
  }, [isLoading, messages, scrollToBottom]);

  return null;
};

export default ScrollToBottomOnNewUserMessage;
