import * as Accordion from '@radix-ui/react-accordion';
import {ChevronDownIcon} from '@radix-ui/react-icons';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import ToolUsedItem from 'scout-chat/components/tools-used/ToolUsedItem.tsx';
import {MessageMetadata} from 'scout-chat/types.ts';

const ToolsUsedView: FunctionComponent<{metadata?: MessageMetadata | null}> = ({metadata}) => {
  const {t} = useTranslation();

  if (!metadata?.tool_calls) {
    return null;
  }

  return (
    <Accordion.Root className='w-fit max-w-full' type='single' collapsible>
      <Accordion.Item value='tool-used'>
        <Accordion.Header>
          <Accordion.Trigger className='text-secondary opacity-70 hover:opacity-100 group/tools-used flex items-center gap-2 justify-between cursor-default  outline-none'>
            {metadata.tool_calls.length === 1 ? (
              <span>{t('tool-used.title.single', {tool_count: metadata.tool_calls.length})}</span>
            ) : (
              <span>{t('tool-used.title.plural', {tool_count: metadata.tool_calls.length})}</span>
            )}
            <ChevronDownIcon
              className='group-data-[state=open]/tools-used:rotate-180 transition-opacity duration-100 opacity-0 group-hover/tools-used:opacity-100 '
              aria-hidden
            />
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content className='overflow-hidden data-[state=open]:animate-expandDown data-[state=closed]:animate-collapseUp '>
          <div className='overflow-hidden p-4 flex flex-col gap-2 rounded-lg border border-stroke-main'>
            {metadata.tool_calls.map((toolCall, index) => (
              <ToolUsedItem key={index} toolCall={toolCall} />
            ))}
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
};

export default ToolsUsedView;
