import {TaskRunResponse} from '#/repositories/assistants-api/requests/fetch-task-run';
import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export const fetchAllTaskRuns = (
  status?: string,
  skip?: number,
  limit?: number,
): Promise<AxiosResponse<TaskRunResponse[]>> => {
  const params = {
    status,
    skip,
    limit,
  };
  return ScoutAPI.get<null, AxiosResponse<TaskRunResponse[]>>('/tasks/all_runs', {params});
};
