import {MobileHeader} from '#/components/MobileHeader.tsx';
import ProfileToggle from '#/components/ProfileToggle.tsx';
import {useScreenWidth} from '#/hooks/useScreenWidth.tsx';
import {ActionBar} from '#/library/actionbar/ActionBar.tsx';
import BrandIconBox from '#/library/sidebar/BrandIconBox.tsx';
import ScoutIconBox from '#/library/sidebar/ScoutIconBox.tsx';
import {ReactComponent as PlusIcon} from '#/resources/plus-icon.svg';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

export type EmptySidebarProps = Partial<React.HTMLAttributes<HTMLDivElement>>;
export const EmptySidebar: FunctionComponent<EmptySidebarProps> = ({...props}) => {
  const isMd = useScreenWidth('md');

  if (!isMd) {
    return <MobileEmptySidebar {...props} />;
  }

  return <DesktopEmptySidebar {...props} />;
};

export const MobileEmptySidebar: FunctionComponent<EmptySidebarProps> = ({className = '', ...props}) => {
  const {t} = useTranslation();
  return (
    <div {...props} className={`size-full bg-surface-01 overflow-y-auto flex flex-col gap-5 ${className}`}>
      <MobileHeader>
        <span className='text-primary font-bold'>{t('sidebar.header')}</span>
        <NavLink
          to='/chat'
          className='flex items-center h-12 aspect-square gap-2 rounded-md bg-accent text-accent-inverse font-semibold justify-center hover:opacity-70 transition-opacity'
        >
          <PlusIcon className='size-7 stroke-accent-inverse' />
        </NavLink>
      </MobileHeader>

      <ActionBar variant='horizontal' className='justify-between shrink-0 mx-4' />

      <div className='w-full absolute bottom-0 flex flex-col gap-5 left-0 px-4 pt-4 bg-surface-01'>
        <ProfileToggle />
        <ScoutIconBox variant='expanded' />
      </div>
    </div>
  );
};

export const DesktopEmptySidebar: FunctionComponent<EmptySidebarProps> = ({className = '', ...props}) => {
  return (
    <div
      {...props}
      className={`h-full overflow-y-auto flex flex-col gap-y-5 p-4 pb-0 rounded-2xl bg-surface-01 border border-stroke-main ${className}`}
    >
      <BrandIconBox variant='collapsed' />
      <div className='w-full sticky bottom-0 left-0 mt-auto pointer-events-none'>
        <div className='w-full h-5 bg-gradient-to-t from-surface-01 pb-3 pointer-events-none' />
        <ScoutIconBox variant='collapsed' />
      </div>
    </div>
  );
};

export default EmptySidebar;
