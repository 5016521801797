import {FunctionComponent} from 'react';
import {ConfigurationProvider} from 'scout-chat/hooks/contexts/use-config.tsx';

export const withScoutConfigProvider = <T extends JSX.IntrinsicAttributes>(Component: FunctionComponent<T>) => {
  return ({
    baseUrl,
    ...props
  }: T & {
    baseUrl?: string;
  }) => {
    return (
      <ConfigurationProvider baseUrl={baseUrl}>
        <Component {...(props as T)} />
      </ConfigurationProvider>
    );
  };
};
