import {Header} from '#/components/chat-page/new-conversation/UseCasesView';
import {FunctionComponent} from 'react';

const PlainNewConversationView: FunctionComponent<{maxWidth: string | undefined}> = ({maxWidth}) => {
  return (
    <div className='size-full flex flex-col mt-5 md:mt-0'>
      <Header maxWidth={maxWidth} />
    </div>
  );
};

export default PlainNewConversationView;
