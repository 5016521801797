import HelpButton from '#/components/HelpButton';
import ProfileButton from '#/components/ProfileButton';
import {useSidebar} from '#/hooks/use-sidebar';
import {ReactComponent as AssistantsIcon} from '#/resources/assistants-icon.svg';
import {ReactComponent as HomeIcon} from '#/resources/home-icon.svg';
import {ReactComponent as TasksIcon} from '#/resources/tasks-icon.svg';
import * as Tooltip from '@radix-ui/react-tooltip';
import {ComponentProps, FunctionComponent, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import AssistantAvatar from 'scout-chat/components/AssistantAvatar.tsx';
import {useConfig} from 'scout-chat/hooks/contexts/use-config.tsx';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';
import {twMerge} from 'tailwind-merge';

export type ActionBarProps = Partial<React.HTMLAttributes<HTMLDivElement>> & {
  assistants?: AssistantPublicResponse[];
  variant?: 'vertical' | 'horizontal';
};

export const ActionBar: FunctionComponent<ActionBarProps> = ({
  assistants,
  className,
  variant = 'vertical',
  ...props
}: ActionBarProps) => {
  const {t} = useTranslation();
  const {config} = useConfig();
  const {setShouldPreventDismissMobile} = useSidebar();

  return (
    <nav
      {...props}
      className={twMerge(
        'data-[variant=vertical]:h-full overflow-y-auto flex data-[variant=vertical]:flex-col justify-start data-[variant=horizontal]:items-stretch data-[variant=vertical]:items-center relative data-[variant=vertical]:px-auto data-[variant=vertical]:pt-4 data-[variant=horizontal]:p-4 gap-4 rounded-2xl bg-surface-01 border border-stroke-main',
        className,
      )}
      data-variant={variant}
    >
      <ActionBarNavLink
        to='/chat'
        IconComponent={HomeIcon}
        title={t('actionbar.home.title')}
        onClick={() => setShouldPreventDismissMobile(true)}
      />

      {config.features.assistants && (
        <ActionBarNavLink
          to='/assistants'
          IconComponent={AssistantsIcon}
          title={t('actionbar.assistants.title')}
          onClick={() => setShouldPreventDismissMobile(true)}
        />
      )}

      {config.features.tasks && (
        <ActionBarNavLink to='/tasks' IconComponent={TasksIcon} title={t('actionbar.tasks.title')} />
      )}

      {!!assistants?.length && (
        <>
          <div className='w-full px-4'>
            <div className='w-full h-px shrink-0 bg-stroke-main' />
          </div>

          {assistants?.map((assistant, index) => (
            <ActionBarNavLink key={index} to={`/assistants/${assistant.id}/chat`} title={assistant.name}>
              <AssistantAvatar
                src={assistant.avatar_url}
                assistantName={assistant.name}
                containerClassName='size-full p-1'
                className='rounded-full'
              />
            </ActionBarNavLink>
          ))}
        </>
      )}
      {variant === 'vertical' && (
        <div className='mt-auto sticky w-full bottom-0'>
          <div className='w-full h-8 bg-gradient-to-t from-surface-01' />
          <div className='pt-2 pb-4 w-full bg-surface-01 space-y-2.5 flex flex-col items-center'>
            <HelpButton />
            <ProfileButton />
          </div>
        </div>
      )}
    </nav>
  );
};

const ActionBarNavLink: FunctionComponent<
  ComponentProps<typeof NavLink> & {
    IconComponent?: typeof HomeIcon;
    children?: ReactNode;
    title: string;
  }
> = ({IconComponent, children, title, ...rest}) => (
  <Tooltip.Provider delayDuration={200}>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <NavLink
          {...rest}
          end
          className='size-10 overflow-hidden shrink-0 gap-2 flex justify-center items-center rounded-lg bg-surface-02 cursor-pointer stroke-primary hover:opacity-70 transition aria-[current=page]:bg-accent aria-[current=page]:stroke-accent-inverse'
        >
          {IconComponent ? <IconComponent className='size-5 text-primary' /> : children}
        </NavLink>
      </Tooltip.Trigger>

      <Tooltip.Portal>
        <Tooltip.Content
          className='max-w-[200px] data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade bg-surface-03 text-primary select-none rounded-[4px] px-[15px] py-[10px] text-[15px] leading-none will-change-[transform,opacity] z-50'
          sideOffset={5}
          side='left'
        >
          {title}
          <Tooltip.Arrow className='fill-surface-03' />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
);
