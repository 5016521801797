import {Textarea} from '#/library/textarea/Textarea';
import {CodeBlockEditorDescriptor, useCodeBlockEditorContext} from '@mdxeditor/editor';

export const PlainTextCodeEditorDescriptor: CodeBlockEditorDescriptor = {
  match: (language, meta) => true,
  priority: 0,
  Editor: props => {
    const cb = useCodeBlockEditorContext();
    return (
      <div className='w-full' onKeyDown={e => e.nativeEvent.stopImmediatePropagation()}>
        <Textarea
          className='w-full bg-surface-03'
          defaultValue={props.code}
          onChange={e => cb.setCode(e.target.value)}
          id='PlanTextCodeEditor'
          value={props.code}
        />
      </div>
    );
  },
};
