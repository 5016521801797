import {useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {useToasts} from 'scout-chat/hooks/contexts/use-toasts.tsx';
import {createConversation} from 'scout-chat/requests/create-conversation.ts';
import {ConversationResponse} from 'scout-chat/requests/fetch-conversation.ts';

export type OnConversationCreateSuccess = (data: ConversationResponse) => void;

export const useCreateConversationMutation = ({onSuccess}: {onSuccess?: OnConversationCreateSuccess} = {}) => {
  const {t} = useTranslation();
  const {addToast} = useToasts();

  return useMutation({
    mutationKey: ['create-conversation'],
    mutationFn: createConversation,
    onSuccess: ({data}) => {
      onSuccess?.(data);
    },
    onError: error => {
      addToast(t('errors.create-conversation'), 'error');
    },
  });
};
