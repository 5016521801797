import 'katex/dist/katex.min.css';
import {FunctionComponent, memo} from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import GeneratedAnchorComponent from 'scout-chat/components/GeneratedAnchorComponent.tsx';
import GeneratedCodeComponent from 'scout-chat/components/GeneratedCodeComponent.tsx';
import GeneratedImageComponent from 'scout-chat/components/GeneratedImageComponent.tsx';
import {twMerge} from 'tailwind-merge';

import 'highlight.js/styles/github.css';
import 'scout-chat/styles/markdown.css';

const remarkPlugins = [remarkParse, remarkRehype, remarkGfm, rehypeRaw, remarkMath];
const rehypePlugins = [rehypeHighlight, rehypeRaw, rehypeKatex];

type MarkdownProps = {
  className?: string;
  children?: string;
};

const markdownComponents = {img: GeneratedImageComponent, a: GeneratedAnchorComponent, pre: GeneratedCodeComponent};
const Markdown: FunctionComponent<MarkdownProps> = ({className, children}) => {
  return (
    <ReactMarkdown
      className={twMerge('conversation-markdown break-words text-primary', className)}
      rehypePlugins={rehypePlugins}
      remarkPlugins={remarkPlugins}
      components={markdownComponents}
    >
      {children}
    </ReactMarkdown>
  );
};

export default memo(Markdown);
