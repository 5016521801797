import Mermaid from '#/components/Mermaid.tsx';
import {TaskResponse} from '#/repositories/assistants-api/requests/fetch-tasks.ts';
import {UseQueryResult} from '@tanstack/react-query';
import {MermaidConfig} from 'mermaid';
import {FunctionComponent, useMemo} from 'react';
import {useTheme} from 'scout-chat/hooks/contexts/use-theme.tsx';

const escapeMermaidChars = (str: string) => {
  const entities = {
    '&': 'amp;',
    '<': 'lt;',
    '>': 'gt;',
    '[': '#91;',
    ']': '#93;',
    '|': '#124;',
    '{': '#123;',
    '}': '#125;',
    '@': '#64;',
    '(': '#40;',
    ')': '#41;',
    '"': 'quot;',
    "'": '#39;',
    '/': '#47;',
    '\\': '#92;',
  } as Record<string, string>;

  // eslint-disable-next-line no-useless-escape
  return str.replace(/[&<>\[\]|{}@()"'\/\\]/g, char => {
    if (!(char in entities)) {
      return '';
    }
    return entities[char];
  });
};
const TaskMermaidPreview: FunctionComponent<{taskQuery: UseQueryResult<TaskResponse, Error>}> = ({taskQuery}) => {
  const {themePreference} = useTheme();
  const mermaidConfig: MermaidConfig = useMemo(() => {
    return {theme: themePreference === 'dark' ? 'dark' : 'default'};
  }, [themePreference]);

  const taskDiagram = useMemo(() => {
    if (!taskQuery.data) return '';

    let diagram = `
    flowchart TD
      subgraph Capability0[ ]
        Start[Run]
      end\n\n`;

    let absoluteStepIndex = 0;

    taskQuery.data.capabilities.forEach((capability, capabilityIndex) => {
      const capabilityDescription = escapeMermaidChars(capability.description);
      diagram += `  subgraph Capability${capabilityIndex + 1}[${capabilityDescription}]\n`;

      capability.task_plan.execution_plan.forEach((step, stepIndex) => {
        const stepId = `Step${absoluteStepIndex + 1}`;
        const nextStepId = `Step${absoluteStepIndex + 2}`;
        const inputVar = step.input_variables.length
          ? escapeMermaidChars(step.input_variables.map(i => i.name).join(', '))
          : ' ';
        const stepDescription = escapeMermaidChars(step.short_description);

        if (stepIndex === 0 && capabilityIndex === 0) {
          diagram += `      Start --> |${inputVar}| ${stepId}[${stepDescription}]\n`;
        } else {
          diagram += `      ${stepId} --> |${inputVar}| ${nextStepId}[${stepDescription}]\n`;
          absoluteStepIndex++;
        }
      });
      diagram += '  end\n\n';
    });

    return diagram;
  }, [taskQuery.data]);

  return (
    <div className='flex my-10 min-h-[5000px] justify-center'>
      <Mermaid chart={taskDiagram} config={mermaidConfig} />
    </div>
  );
};

export default TaskMermaidPreview;
