import {useOktaAuth} from '@okta/okta-react';
import {useMemo} from 'react';
import {LocalStorageKeys} from 'scout-chat/repositories/environment.ts';

interface OktaTokens {
  idToken: string;
  nonce: string;
}

const useOktaLogin = (): {tokens: OktaTokens | null; logout: () => Promise<void>} => {
  const {oktaAuth, authState} = useOktaAuth();

  const tokens: OktaTokens | null = useMemo(() => {
    if (!authState?.idToken) return null;
    return {idToken: authState.idToken.idToken, nonce: localStorage.getItem(LocalStorageKeys.OKTA_NONCE) || ''};
  }, [authState]);

  const logout = async () => {
    await oktaAuth.signOut();
  };

  return {tokens, logout};
};

export default useOktaLogin;
