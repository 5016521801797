import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';
import {FileStatus} from 'scout-chat/types.ts';

export type LinkResponse = {
  id: string | null;
  url: string;
  status: FileStatus;
};

export const fetchAssistantLinks = (uuid: string) => {
  return ScoutAPI.get<null, AxiosResponse<LinkResponse[]>>(`/assistants/${uuid}/links`);
};
