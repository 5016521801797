import mermaid, {MermaidConfig} from 'mermaid';
import {FunctionComponent, useEffect, useRef} from 'react';

const DEFAULT_CONFIG = {
  startOnLoad: true,
  theme: 'dark',
  look: 'classic',
  logLevel: 5,
  securityLevel: 'loose',
  arrowMarkerAbsolute: false,
  flowchart: {
    htmlLabels: true,
    curve: 'linear',
  },
} as MermaidConfig;

const Mermaid: FunctionComponent<{
  chart: string;
  config?: MermaidConfig;
}> = ({chart, config}) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    mermaid.initialize({...DEFAULT_CONFIG, ...config});
  }, [config]);

  useEffect(() => {
    const run = async () => {
      if (!ref.current) return;

      const result = await mermaid.render('mermaid', chart);
      ref.current.innerHTML = result.svg;
    };
    run();
  }, [chart, config]);

  if (!chart) return null;

  // eslint-disable-next-line tailwindcss/no-custom-classname
  return <div ref={ref} className='mermaid' />;
};

export default Mermaid;
