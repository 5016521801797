import {ThemeSelectorDropdown} from '#/library/ThemeSelectorDropdown/ThemeSelectorDropdown.tsx';
import {ReactComponent as MiregoWoodmarkIcon} from '#/resources/mirego-woodmark.svg';
import {ReactComponent as ScoutNameLogo} from '#/resources/scout-name-logo.svg';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';

const miregoWebsiteLink = 'https://www.mirego.com';

const ScoutIconBox: FunctionComponent<{variant: 'expanded' | 'collapsed'}> = ({variant}) => {
  const {t} = useTranslation();

  return (
    <div
      className='w-full flex bg-surface-01 pt-2 pb-4 pointer-events-auto justify-between data-[collapsed=true]:justify-center items-center'
      data-collapsed={variant === 'collapsed'}
    >
      <a
        href={miregoWebsiteLink}
        target='_blank'
        className='text-primary flex items-end gap-1 group/scout-anchor hover:opacity-70 transition-opacity outline-none select-none data-[collapsed=true]:hidden'
        rel='noreferrer'
        data-collapsed={variant === 'collapsed'}
      >
        <ScoutNameLogo className='fill-primary' />
        <span className='text-2xs leading-xxs overflow-hidden ml-1'>{t('sidebar.subtitle')}</span>
        <MiregoWoodmarkIcon className='w-mirego-woodmark-icon h-mirego-woodmark-icon' fill='var(--color-primary)' />
      </a>

      <ThemeSelectorDropdown variant={variant} />
    </div>
  );
};

export default ScoutIconBox;
