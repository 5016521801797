import {FunctionComponent, useEffect, useRef} from 'react';
import {useScrollToBottom} from 'react-scroll-to-bottom';
import {ConversationMessage} from 'scout-chat/types.ts';

const ScrollToBottomOnConversationChange: FunctionComponent<{
  conversationId: string | undefined;
  conversationMessages: ConversationMessage[];
  conversationQueryIsLoading: boolean;
}> = ({conversationId, conversationMessages, conversationQueryIsLoading}) => {
  const scrollToBottom = useScrollToBottom();
  const prevConversationId = useRef<string | null>(null);
  const prevMessagesLength = useRef<number>(0);

  useEffect(() => {
    if (
      !conversationId ||
      conversationId === prevConversationId.current ||
      conversationMessages.length === prevMessagesLength.current ||
      !conversationMessages.length ||
      conversationQueryIsLoading
    ) {
      return;
    }
    prevConversationId.current = conversationId;
    prevMessagesLength.current = conversationMessages.length;
    scrollToBottom({behavior: 'auto'});
  }, [conversationId, conversationQueryIsLoading, conversationMessages, scrollToBottom]);

  return null;
};

export default ScrollToBottomOnConversationChange;
