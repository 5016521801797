import React, {ReactNode, createContext, useContext} from 'react';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';
import getConfigResource from 'scout-chat/requests/fetch-config-resource.ts';
import {AssistantSharing, WebappConfig} from 'scout-chat/types.ts';

interface ConfigurationContextType {
  config: WebappConfig;
}

const defaultConfigState: ConfigurationContextType = {
  config: {
    application: {
      colors: {
        accent: '#B5A6FF',
        accentDark: '#C4F24A',
        accentInverse: '#292929',
      },
      companyName: '',
      userAgreement: null,
      localizedSupportLinks: null,
      maxFileSizeInMb: 100,
    },
    features: {
      tasks: false,
      assistants: false,
      assistantSharing: AssistantSharing.Disabled,
      imageGeneration: false,
      speech: false,
      remoteFiles: false,
      assistantFiles: false,
      externalServices: [],
      useCases: [],
    },
    integrations: {
      authentication: {
        auth0: undefined,
        google: undefined,
        okta: undefined,
      },
    },
  },
};

const ConfigurationContext = createContext<ConfigurationContextType | undefined>(undefined);

export const useConfig = () => {
  const context = useContext(ConfigurationContext);

  if (context === undefined) {
    throw new Error('useConfig must be used inside ConfigurationProvider');
  }

  return context;
};

export const ConfigurationProvider: React.FC<{baseUrl?: string; children: ReactNode}> = ({baseUrl, children}) => {
  const existingContext = useContext(ConfigurationContext);

  if (!existingContext) {
    ScoutAPI.defaults.baseURL = baseUrl;
  }

  return (
    <ConfigurationContext.Provider
      value={
        existingContext ?? {
          config: getConfigResource().read() || defaultConfigState.config,
        }
      }
    >
      {children}
    </ConfigurationContext.Provider>
  );
};
