import {login, LoginRequest} from '#/repositories/assistants-api/requests/login.ts';
import React, {createContext, ReactNode, useCallback, useContext} from 'react';
import {useAuth} from 'scout-chat/hooks/contexts/use-auth.tsx';

interface LoginContextType {
  login: (request: LoginRequest) => Promise<void>;
}

const LoginContext = createContext<LoginContextType | undefined>(undefined);

export const useLogin = (): LoginContextType => {
  const context = useContext(LoginContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export const LoginProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const {user, refreshUser} = useAuth();

  const handleLogin = useCallback(
    async (request: LoginRequest) => {
      await login(request);
      await refreshUser();
    },
    [refreshUser],
  );

  const value = {
    user,
    login: handleLogin,
  };

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
};
