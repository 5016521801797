import {AxiosResponse} from 'axios';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';
import {ConversationResponse} from 'scout-chat/requests/fetch-conversation.ts';
import {ConversationMessage} from 'scout-chat/types.ts';

export type UpdateConversationMessageRequest = {
  message: ConversationMessage;
  user_message_index: number;
};

export const updateConversationMessage = (id: string, message: UpdateConversationMessageRequest) =>
  ScoutAPI.put<UpdateConversationMessageRequest, AxiosResponse<ConversationResponse>>(
    `/conversations/${id}/message`,
    message,
  );
