import {FunctionComponent} from 'react';

type LabelProps = Pick<React.LabelHTMLAttributes<HTMLLabelElement>, 'children' | 'htmlFor'>;
const Label: FunctionComponent<LabelProps> = ({children, ...props}) => {
  return (
    <label {...props} className='block text-primary font-bold mb-6'>
      {children}
    </label>
  );
};

export default Label;
