import {AxiosResponse} from 'axios';
import {KeyPrefix, ParseKeysByKeyPrefix} from 'i18next';
import ScoutAPI from 'scout-chat/repositories/scout-api.ts';

export type AvailableFunction = ParseKeysByKeyPrefix<
  KeyPrefix<'translation'>,
  'create-edit-assistant.fields.advanced.functions'
>;

export const fetchAvailableFunctions = (): Promise<AxiosResponse<AvailableFunction[]>> => {
  return ScoutAPI.get<null, AxiosResponse<AvailableFunction[]>>('/chat/functions/');
};
