import {TaskPlanExecutionStep} from '#/repositories/assistants-api/requests/fetch-tasks.ts';
import {useMemo} from 'react';

const useOriginalSteps = (executionPlan?: TaskPlanExecutionStep[]) => {
  return useMemo(() => {
    return (
      executionPlan?.map(step => ({
        prompt: step.prompt || '',
        code: step.code_execution?.code || '',
      })) ?? []
    );
  }, [executionPlan]);
};
export default useOriginalSteps;
