import {useMemo, useState} from 'react';
import {AssistantPublicResponse} from 'scout-chat/requests/fetch-public-assistant.ts';

export const usePromptInputDataToShow = (
  createConversationMutationIsError: boolean,
  message: string,
  files: File[],
  mentionedAssistant: AssistantPublicResponse | null,
) => {
  const [sendMessageIsPending, setSendMessageIsPending] = useState(false);

  const isPending = useMemo(
    () => sendMessageIsPending && !createConversationMutationIsError,
    [createConversationMutationIsError, sendMessageIsPending],
  );

  const promptToShow = useMemo(() => {
    return isPending ? '' : message;
  }, [isPending, message]);

  const filesToShow = useMemo(() => (isPending ? [] : files), [files, isPending]);

  const mentionedAssistantToShow = useMemo(
    () => (isPending ? null : mentionedAssistant),
    [isPending, mentionedAssistant],
  );

  return {promptToShow, filesToShow, mentionedAssistantToShow, sendMessageIsPending, setSendMessageIsPending};
};
