import {useAuthenticatedQuery} from 'scout-chat/hooks/requests/use-authenticated-query.tsx';
import {fetchConversation} from 'scout-chat/requests/fetch-conversation.ts';

type UseConversationQueryParams = {
  conversationId?: string;
};
export const useConversationQuery = ({conversationId}: UseConversationQueryParams) =>
  useAuthenticatedQuery({
    queryKey: ['conversation', conversationId],
    queryFn: async () => {
      const response = await fetchConversation(conversationId || '');
      return response.data;
    },
    enabled: !!conversationId,
    refetchOnWindowFocus: false,
  });
