import {useQuery} from '@tanstack/react-query';
import {fetchPublicAssistant} from 'scout-chat/requests/fetch-public-assistant.ts';

export const usePublicAssistantQuery = ({assistantId}: {assistantId?: string}) =>
  useQuery({
    queryKey: ['public-assistant', assistantId],
    queryFn: async () => {
      const response = await fetchPublicAssistant(assistantId || '');
      return response.data;
    },
    enabled: !!assistantId,
  });
