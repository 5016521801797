import {useSidebar} from '#/hooks/use-sidebar';
import {ReactComponent as RightArrowIcon} from '#/resources/right-arrow-icon.svg';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

export const AssistantsTitleNavLink: FunctionComponent<{className?: string}> = ({className}) => {
  const {t} = useTranslation();
  const {mobileToggle} = useSidebar();

  return (
    <NavLink
      to='/assistants'
      className={`flex items-center justify-center p-2 bg-surface-02 rounded-xl text-primary ${className || ''}`}
    >
      <span className='grow text-base font-semibold pl-2'>{t('sidebar.assistants.actions.browse')}</span>

      <div
        className='h-9 aspect-square rounded-lg bg-accent text-accent-inverse flex justify-center items-center hover:opacity-70 transition-opacity'
        onClick={mobileToggle}
      >
        <RightArrowIcon className='text-accent-inverse stroke-accent-inverse' />
      </div>
    </NavLink>
  );
};
