import {TaskPlanExecutionStep} from '#/repositories/assistants-api/requests/fetch-tasks.ts';
import {EditedStep} from '#/repositories/assistants-api/requests/update-task-capability-step.ts';
import {useEffect} from 'react';

const useSetFormDataOnCapabilityChange = (
  executionPlan: TaskPlanExecutionStep[] | undefined,
  setSelectedStepIndex: (value: number) => void,
  setEditedSteps: (value: (EditedStep | null)[]) => void,
) => {
  useEffect(() => {
    if (!executionPlan?.length) {
      return;
    }
    setSelectedStepIndex(0);
    setEditedSteps(Array.from({length: executionPlan.length}, () => null));
  }, [executionPlan, setEditedSteps, setSelectedStepIndex]);
};

export default useSetFormDataOnCapabilityChange;
